/* eslint-disable @typescript-eslint/no-use-before-define */
import { Flex, Typography } from 'antd';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { COUNT_COLORS_PALETTE } from 'config/colors';
import { formatToUnits } from 'services/helpers.service';

type TProps = {
	data: any;
};

export const TotalContentCounts = ({ data }: TProps) => {
	const { t } = useTranslation();

	if (!data) return null;

	return (
		<Flex gap={12} className='h-28'>
			{Object.values(getContentHash(t)).map((fn: any, index) => {
				const { title, count } = fn?.(data) || {};
				const colorIndex = COUNT_COLORS_PALETTE.length - 1 - index;
				return (
					<Flex
						flex={1}
						gap={4}
						vertical
						key={index}
						align='center'
						justify='center'
						style={{
							borderColor: COUNT_COLORS_PALETTE[colorIndex],
							borderWidth: 1,
							borderStyle: 'solid'
						}}
						className='rounded-md p-2 shadow-md'
					>
						<Typography.Text
							className='text-2xl font-bold'
							style={{
								color: COUNT_COLORS_PALETTE[colorIndex]
							}}
						>
							{count || 'N/A'}
						</Typography.Text>
						<Typography.Text
							className='text-xs mt-1 font-medium text-center'
							style={{
								color: COUNT_COLORS_PALETTE[colorIndex]
							}}
						>
							{title}
						</Typography.Text>
					</Flex>
				);
			})}
		</Flex>
	);
};

const getContentHash = (t: TFunction) => {
	return {
		articles: (data: any) => ({
			title: t('dashboard.totalArticles'),
			percentage: data?.percentages?.articles?.toFixed(2),
			count: formatToUnits(data?.counts?.articlesCount)
		}),
		movies: (data: any) => ({
			title: t('dashboard.totalMovies'),
			percentage: data?.percentages?.movies?.toFixed(2),
			count: formatToUnits(data?.counts?.moviesCount)
		}),
		podcast: (data: any) => ({
			title: t('dashboard.totalPodcasts'),
			percentage: data?.percentages?.podcasts?.toFixed(2),
			count: formatToUnits(data?.counts?.podcastsCount)
		}),
		series: (data: any) => ({
			title: t('dashboard.totalSeries'),
			percentage: data.percentages?.series?.toFixed(2),
			count: formatToUnits(data?.counts?.seriesCount)
		}),
		videos: (data: any) => ({
			title: t('dashboard.totalPropagandas'),
			percentage: data?.percentages?.propagandas?.toFixed(2),
			count: formatToUnits(data?.counts?.propagandasCount)
		})
	};
};
