import { useRef } from 'react';

import { Button, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import FilterContainer from './filter-container/filter-container';
import './filter-popover.scss';
import { TGlobalFilterConfig } from './types';

const GlobalFilter = (props: TGlobalFilterConfig) => {
	const { t } = useTranslation();

	const filterButtonRef = useRef<HTMLButtonElement>(null);
	const buttonProps = props?.filterButtonProps || {};

	return (
		<Popover content={<FilterContainer {...props} filterButtonRef={filterButtonRef} />} trigger='click'>
			<Button size='large' type='primary' ref={filterButtonRef} {...buttonProps} className={props?.className || ''}>
				{buttonProps?.title ?? t('filter.filter')}
			</Button>
		</Popover>
	);
};

export default GlobalFilter;
