import { ButtonView, icons, Plugin, toWidget } from 'ckeditor5';

export default class CustomImagePlugin extends Plugin {
	init() {
		const { editor } = this;

		editor.model.schema.register('image', {
			allowWhere: '$block',
			allowAttributes: ['src', 'class', 'id', 'preload', 'width', 'height', 'width', 'height', 'imageStyle'], // Include imageStyle if needed
			isObject: true,
			isBlock: true
		});

		editor.conversion.for('dataDowncast').elementToElement({
			model: 'image',
			view: (modelElement, { writer }) => {
				const imgElement = writer.createEmptyElement('img', {
					src: modelElement.getAttribute('src'),
					width: modelElement.getAttribute('width') || '100%', // Use width attribute
					height: modelElement.getAttribute('height') || 'auto' // Use height attribute
				});
				return imgElement;
			}
		});
		// Downcast conversion for editing
		editor.conversion.for('editingDowncast').elementToElement({
			model: 'image',
			view: (modelElement, { writer }) => {
				const imgElement = writer.createEditableElement('img', {
					src: modelElement.getAttribute('src'),
					width: modelElement.getAttribute('width') || '100%', // Use width attribute
					height: modelElement.getAttribute('height') || 'auto'
				});
				const widgetElement = writer.createContainerElement('span', {
					class: 'image-inline ck-widget ck-widget_with-resizer image_resized ck-widget_selected'
				});

				writer.insert(writer.createPositionAt(widgetElement, 0), imgElement);
				return toWidget(widgetElement, writer, { label: 'image widget' });
			}
		});

		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'img',
				attributes: {
					src: true,
					width: true,
					height: true,
					'data-image-style': true
				}
			},
			model: (viewElement, { writer }) => {
				return writer.createElement('image', {
					src: viewElement.getAttribute('src'),
					width: viewElement.getAttribute('width') || '100%',
					height: viewElement.getAttribute('height') || 'auto'
				});
			}
		});

		editor.ui.componentFactory.add('CustomImagePlugin', (locale) => {
			const view = new ButtonView(locale);
			// Assuming you want to allow images in all block elements

			view.set({
				label: 'Insert Image',
				icon: icons.image,
				tooltip: true
			});

			// The command to be executed when the button is clicked
			view.on('execute', () => {
				// Trigger your child component here
				const opener = editor.config.get('onImageDrawerOpen') as any;
				if (typeof opener === 'function') opener();
			});

			return view;
		});
	}
}
