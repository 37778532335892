import { ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

type TProps = {
	backButton?: { path: To | number; options?: NavigateOptions };
	title?: string | ReactNode;
	subTitle?: string | ReactNode;
	renderLeft?: ReactNode;
	renderRight?: ReactNode;
};

const Header = ({ backButton, title, subTitle, renderLeft, renderRight }: TProps) => {
	const navigate = useNavigate();

	return (
		<Flex className='w-full' justify='space-between' align='center' gap={8}>
			<Flex gap={8}>
				{backButton && (
					<Button
						onClick={() => {
							typeof backButton.path === 'number' ? navigate(backButton.path) : navigate(backButton.path, backButton.options);
						}}
						icon={<ArrowLeftOutlined />}
						type='text'
					/>
				)}

				{renderLeft ? (
					<div>{renderLeft}</div>
				) : (
					<Flex vertical>
						{title && (
							<Typography.Title level={4} style={{ margin: 0 }}>
								{title}
							</Typography.Title>
						)}

						{subTitle && <Typography.Text>{subTitle}</Typography.Text>}
					</Flex>
				)}
			</Flex>

			<div>{renderRight}</div>
		</Flex>
	);
};

export default Header;
