import { Flex } from 'antd';

import { UserEngagementCounts } from './engagement-counts';
import useUserEngagementStats from './useUserEngagementStats';

const UserEngagementStats = () => {
	const { isLoading, data } = useUserEngagementStats();

	return (
		<Flex gap={8} className='min-h-24'>
			{data?.map((item: any, index: number) => (
				<div key={index} className='flex-1'>
					{!isLoading && (
						<UserEngagementCounts
							title={item?.title}
							count={item?.count}
							upDownDetails={item?.upDownDetails}
							icon={{
								Icon: item?.icon,
								color: item?.color,
								background: item?.background,
								borderColor: item?.borderColor
							}}
						/>
					)}
				</div>
			))}
		</Flex>
	);
};

export default UserEngagementStats;
