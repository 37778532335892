import { Flex, Typography } from 'antd';
import { IconType } from 'react-icons';
import { IoMdTrendingDown, IoMdTrendingUp } from 'react-icons/io';

type TProps = {
	title: string;
	count: string;
	upDownDetails: {
		isIncreased: boolean;
		message: string;
	};
	icon: {
		Icon: IconType;
		color: string;
		background: string;
		borderColor: string;
	};
};

export const UserEngagementCounts = (props: TProps) => {
	const { title, count, upDownDetails, icon } = props;

	const IconComp = icon.Icon;

	return (
		<Flex vertical className='relative p-2 bg-white h-full rounded-md shadow-md'>
			<Flex vertical>
				<Typography.Text className='text-xs'>{title}</Typography.Text>
				<Typography.Text className='text-3xl font-bold'>{count}</Typography.Text>
			</Flex>

			{upDownDetails ? (
				<Flex className='flex items-center gap-1 my-2 text-xs text-zinc-500'>
					{upDownDetails?.isIncreased ? <IoMdTrendingUp className='text-green-500' /> : <IoMdTrendingDown className='text-red-500' />}
					<Typography.Text className='text-xs w-48 overflow-x-scroll' ellipsis={{ tooltip: true }}>
						{upDownDetails?.message}
					</Typography.Text>
				</Flex>
			) : (
				<Typography.Text className='text-xs my-2 text-zinc-500'>N/A</Typography.Text>
			)}

			{IconComp && (
				<IconComp
					size={35}
					className={`absolute top-2 right-2 ${icon.color} border border-solid ${icon.borderColor} ${icon.background} rounded-full p-1`}
				/>
			)}
		</Flex>
	);
};
