export interface TFetchImagesData {
	totalPages: number;
	limit: number;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	totalItems: number;
	currentPage: number;
	items: TFetchImagesDataItem[];
}

export interface TFetchImagesDataItem {
	createdAt: string;
	updatedAt: string;
	id: string;
	title: string;
	description: null | string;
	processStatus: string;
	tags: string[];
	imageType: null | string;
	urls: Urls;
}

export interface FetchMediaData {
	createdAt: string;
	updatedAt: string;
	id: string;
	title: string;
	description: string;
	colorPalette: number[][];
	originalName: string;
	processStatus: string;
	mediaSize: string;
	mimeType: string;
	tags: string[];
	imageType: string;
	urls: Urls;
}

interface Urls {
	url: string;
	thumbnail: string;
	preview: string;
}

export enum EImageType {
	THUMBNAIL = 'thumbnail',
	CARD_LONG = 'card-long',
	CARD_WIDE = 'card-wide',
	HEADER = 'header'
}
