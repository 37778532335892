import React from 'react';

import { Flex } from 'antd';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { COLORS_PALETTE } from 'config/colors';

import HeaderContentBlock from '../../header-content-block';

type TProps = {
	data: any;
};

const ContentDistribution: React.FC<TProps> = ({ data }) => {
	const { t } = useTranslation();

	const countsObj = data?.counts || {};
	const dataList = [
		{ value: countsObj?.propagandasCount, name: t('dashboard.propagandas') },
		{ value: countsObj?.articlesCount, name: t('dashboard.articles') },
		{ value: countsObj?.moviesCount, name: t('dashboard.movies') },
		{ value: countsObj?.podcastsCount, name: t('dashboard.podcasts') },
		{ value: countsObj?.seriesCount, name: t('dashboard.series') }
	];

	const option = {
		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)'
		},
		legend: {
			data: dataList?.map((item) => item?.name),
			itemWidth: 20,
			itemHeight: 10
		},
		aria: {
			enabled: true
		},
		series: [
			{
				name: t('dashboard.contentDistribution'),
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: true,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				emphasis: {
					label: {
						show: true,
						fontSize: '15',
						fontWeight: 'bold'
					}
				},
				data: dataList,
				labelLine: {
					show: false,
					length: 5,
					length2: 5
				},
				color: Object.values(COLORS_PALETTE)
			}
		]
	};

	return (
		<Flex vertical gap={24} className='h-full'>
			<Flex justify='center'>
				<HeaderContentBlock title={t('dashboard.contentDistribution')} />
			</Flex>
			<ReactECharts option={option} lazyUpdate style={{ height: '100%', width: '100%' }} />
		</Flex>
	);
};

export default ContentDistribution;
