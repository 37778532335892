import { EImageType } from 'types/image';

const base64ToFile = (base64: string, filename: string) => {
	const arr = base64.split(',');
	const mime = arr[0]?.match(/:(.*?);/)![1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	// eslint-disable-next-line no-plusplus
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const ImageHelpersService = {
	base64ToFile
};

export const HashImageTypeAspectRatio: { [key in `${EImageType}` | 'none']: number } = {
	thumbnail: 1,
	header: 16 / 9,
	'card-wide': 4 / 3,
	'card-long': 3 / 4,
	none: 0
};
