import { useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Drawer, Form, Spin } from 'antd';

// eslint-disable-next-line import/no-cycle
import InputFields from 'components/input-fields';
import UploadDeviceImageField from 'components/input-fields/components/custom/upload-device-image-field';
import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TFormFields } from 'components/input-fields/types/types';
import { FORM_SCROLL_OPTIONS, IMAGE_ASPECT_RATIOS } from 'config/constants';
import { useUtils } from 'contexts/UtilsProvider';
import { ImageHelpersService } from 'services/image-helpers.service';
import { IMAGE_MUTATIONS, IMAGE_QUERIES } from 'services/tanstack-query/image';
import { EImageType, FetchMediaData, TFetchImagesDataItem } from 'types/image';

import 'cropperjs/dist/cropper.css';

interface UploadMediaDrawerProps {
	visible: boolean;
	onClose: (refresh?: boolean) => void;
	onImageUploaded?: () => void;
	onImageDetailsUpdate?: TFetchImagesDataItem;
	defaultImageType?: `${EImageType}`;
}

const SingleImageUploadDrawer = ({
	visible,
	defaultImageType,
	onClose,
	onImageUploaded,
	onImageDetailsUpdate
}: UploadMediaDrawerProps) => {
	const utils = useUtils();

	const [tempImage, setTempImage] = useState<any>();

	const id = onImageDetailsUpdate?.id;

	const [form] = Form.useForm();

	const selectedImageType = Form.useWatch('imageType', form) || defaultImageType;

	if (defaultImageType) form.setFieldValue('imageType', defaultImageType);

	const { isLoading, data, isError, error, isSuccess } = useQuery<any, any, FetchMediaData>({
		...IMAGE_QUERIES.fetchMedia(id || ''),
		enabled: !!id
	});

	if (isError) utils.showError(error);

	if (isSuccess) {
		form.setFieldsValue({
			title: data?.title,
			imageType: data?.imageType,
			tags: data?.tags,
			description: data?.description
		});
	}

	const addImage = useMutation({
		...IMAGE_MUTATIONS.uploadSingleImage(),
		onError: (err) => utils.showError(err),
		onSuccess: () => {
			utils.showSuccess('Image uploaded successfully');
			onClose(true);
			form.resetFields();
			onImageUploaded?.();
		}
	});

	const updateImage = useMutation({
		...IMAGE_MUTATIONS.updateSingleImage(id),
		onError: (err) => utils.showError(err),
		onSuccess: () => {
			utils.showSuccess('Image details updated successfully');
			onClose(true);
			onImageUploaded?.();
		}
	});

	const onHandleClose = () => {
		onClose();
		form.resetFields();
	};

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const formFields = getFormFields({ defaultImageType });

	const saveImage = (formValues: any) => {
		if (id) {
			updateImage.mutate({
				title: formValues.title,
				description: formValues.description,
				tags: formValues.tags,
				imageType: formValues.imageType
			});
			return;
		}

		const formData = new FormData();
		formData.append('title', formValues.title);
		formData.append('description', formValues.description);
		formData.append('imageType', formValues.imageType);
		if (formValues.tags) formData.append('tags', formValues.tags);
		formData.append('image', ImageHelpersService.base64ToFile(tempImage, 'image.jpg'));
		addImage.mutate(formData);
	};

	return (
		<Drawer
			title={id ? 'Update Image Details' : 'Upload Single Image'}
			open={visible}
			onClose={onHandleClose}
			destroyOnClose
		>
			<Spin spinning={isLoading || addImage.isPending || updateImage.isPending}>
				<Form
					form={form}
					layout='vertical'
					onFinish={saveImage}
					disabled={updateImage.isPending || addImage.isPending}
					scrollToFirstError={FORM_SCROLL_OPTIONS}
				>
					<InputFields formFields={formFields} />

					{!id && (
						<Form.Item
							label='Select media'
							name='mediaUpload'
							rules={[
								{
									required: true,
									message: 'Please select image!'
								}
							]}
							extra='File name should not be more than 100 characters long'
						>
							<UploadDeviceImageField
								aspectRatio={selectedImageType || 'thumbnail'}
								onImageSelect={(image) => {
									setTempImage(image);
									form.setFieldValue('mediaUpload', image);
									form.validateFields(['mediaUpload']);
								}}
								onDelete={() => {
									setTempImage(undefined);
									form.setFieldValue('mediaUpload', undefined);
									form.validateFields(['mediaUpload']);
								}}
							/>
						</Form.Item>
					)}

					<Button type='primary' htmlType='submit' size='large' block>
						{!id ? 'Upload Image' : 'Update image'}
					</Button>
				</Form>
			</Spin>
		</Drawer>
	);
};

export default SingleImageUploadDrawer;

type TGetFormFields = {
	defaultImageType?: `${EImageType}`;
};

const getFormFields = ({ defaultImageType }: TGetFormFields): TFormFields => {
	return {
		title: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Title',
				name: 'title',
				rules: [
					{
						required: true,
						message: 'Please input image title!'
					}
				]
			},
			fieldProps: {
				placeholder: 'Enter Title',
				maxLength: 100,
				count: { show: true, max: 100 }
			}
		},
		imageType: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Image Type',
				name: 'imageType',
				rules: [
					{
						required: true,
						message: 'Please select image type!'
					}
				]
			},
			fieldProps: {
				options: IMAGE_ASPECT_RATIOS,
				defaultValue: defaultImageType,
				placeholder: 'Select Image Type'
			}
		},
		tags: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Image Tags',
				name: 'tags'
			},
			api: {
				url: `/media/image/tags`,
				responseDataStructure: 'tags'
			},
			fieldProps: {
				mode: 'tags',
				placeholder: 'Select Image Tags'
			}
		},
		description: {
			fieldType: EInputFieldTypes.TEXTAREA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Description',
				name: 'description',
				rules: [
					{
						required: true,
						message: 'Please input description!'
					}
				]
			},
			fieldProps: {
				rows: 4,
				placeholder: 'Enter Description'
			}
		}
	};
};
