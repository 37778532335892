import { Button, Drawer, Spin } from 'antd';

// eslint-disable-next-line import/no-cycle
import {
	DEFAULT_FILTER_SORT_BY,
	DEFAULT_FILTER_SORT_ORDER,
	GlobalFilter,
	GLOBAL_FILTERS_COMMON_SORT_BY
} from 'components/filter/global-filter';
import Header from 'components/header';
import SearchComp from 'components/ui/search';
import StreamGallery from 'pages/media/stream/components/StreamGallery';
import CreateLiveStreamDrawer from 'pages/media/stream/CreateLiveStream/CreateLiveStream';
import { useStreamListing } from 'pages/media/stream/useStreamListing';
import { useRolePermissions } from 'services/user-role/permissions/useRolePermissions';

import { TVideo } from '../../uploadVideoButton/UploadVideoButton';
import '../image/image-gallery.scss';
import { TSelectedMedia } from '../types';

type TUploadModuleVideoProps = {
	selectMultiple?: boolean;
	onVideoSelected?: (video: TVideo[]) => void;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
	forEditor?: boolean;
};

const SORT_BY_LIST = [
	...GLOBAL_FILTERS_COMMON_SORT_BY,
	{ label: 'Title', value: 'meta' },
	{ label: 'Description', value: 'description' }
];

const StreamGalleryWrapper = ({
	selectMultiple,
	onVideoSelected,
	onSelect,
	forEditor = false
}: TUploadModuleVideoProps) => {
	const { hasPermission } = useRolePermissions();
	const {
		isLoading,
		liveSteamsData,
		actionDetails,
		setActionDetails,
		setFilter,
		handleCreateUpdateDrawerClose,
		onAddTranslation
	} = useStreamListing();

	return (
		<div className='image-gallery'>
			<Header
				title='Live Stream'
				subTitle={hasPermission('media.create') && 'Add or remove live streams'}
				renderRight={
					hasPermission('media.create') && (
						<div className='ml-4'>
							<Button
								onClick={() => {
									setActionDetails({ action: 'create' });
								}}
								type='primary'
								size='large'
							>
								Add Live Stream
							</Button>
						</div>
					)
				}
			/>

			<div className='flex gap-4 mt-4'>
				<SearchComp
					searchProps={{
						placeholder: 'Search Live Steam'
					}}
					onValueChange={(value) => {
						setFilter((pv: any) => ({ ...pv, q: value }));
					}}
				/>

				<GlobalFilter
					fieldNames={['sortOrder', 'sortBy', 'updatedBy', 'createdBy']}
					formFields={{
						predefined: {
							sortBy: {
								fieldProps: {
									options: SORT_BY_LIST
								}
							}
						}
					}}
					onSubmit={({
						sortOrder = DEFAULT_FILTER_SORT_ORDER,
						sortBy = DEFAULT_FILTER_SORT_BY,
						updatedBy,
						createdBy
					}) => {
						setFilter((s) => ({ ...s, sortOrder, sortBy, updatedBy, createdBy }));
					}}
				/>
			</div>

			<div className='mt-3 align-center w-full h-full overflow-scroll'>
				<Spin spinning={isLoading}>
					<StreamGallery dataList={liveSteamsData ?? []} onSelect={onSelect} />
				</Spin>
			</div>

			<Drawer
				title={actionDetails?.action === 'edit' ? 'Edit Live Stream' : 'Add Live Stream'}
				open={actionDetails?.action === 'edit' || actionDetails?.action === 'create'}
				onClose={() => {
					handleCreateUpdateDrawerClose(false);
				}}
				destroyOnClose
			>
				<CreateLiveStreamDrawer
					onClose={handleCreateUpdateDrawerClose}
					targetSteamId={actionDetails?.liveStreamData?.id}
					onAddTranslation={onAddTranslation}
				/>
			</Drawer>
		</div>
	);
};

export default StreamGalleryWrapper;
