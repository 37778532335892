import { CSSProperties } from 'react';

import { ColProps, FormInstance } from 'antd';

export enum EPageMetaSchemaType {
	ARTICLES = 'articles',
	MOVIES = 'movies',
	TV_SERIES = 'tv-series',
}

export type TMetaApiData = {
	metaSchemaType: string | undefined;

	metaTitle: string | undefined;
	metaDescription: string | undefined;
	metaKeyWords: any;
	metaImage: { id: string; [key: string]: any } | undefined;

	metaSocialTitle: string | undefined;
	metaSocialDescription: string | undefined;
	metaSocialKeyWords: string | undefined;
	metaSocialImage: { id: string; [key: string]: any } | undefined;
};

type TGroupedFieldProps = {
	colProps?: ColProps;
	showTitle?: boolean;
	fieldsContainer?: {
		className?: string;
		style?: CSSProperties;
	};
};

export type TMetaInputFieldsProps = {
	form: FormInstance;
	pageSchemaType?: `${EPageMetaSchemaType}`;
	componentProps?: {
		meta?: TGroupedFieldProps;
		socialMeta?: TGroupedFieldProps;
	};
};

export type TGetMetaFormFields = {
	form: FormInstance;
	pageSchemaType?: `${EPageMetaSchemaType}`;
};
