import { MoreOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

export enum EStreamCardMenuOptions {
	VIEW = 1,
	EDIT = 2,
	STATUS = 3,
	VISIBILITY = 4,
	DELETE = 5,
	TRANSLATION = 6
}

type TCreateMenuOptions = {
	onMenuOptionSelected: (option: EStreamCardMenuOptions) => void;
	hasPermissions: {
		edit: boolean;
		delete: boolean;
	};
};

const createMenuOptions = ({ onMenuOptionSelected, hasPermissions }: TCreateMenuOptions): MenuProps['items'] => {
	return [
		{
			label: 'View',
			key: '1',
			onClick() {
				onMenuOptionSelected(EStreamCardMenuOptions.VIEW);
			}
		},

		...((hasPermissions.edit && [
			{
				label: 'Edit',
				key: '2',
				onClick() {
					onMenuOptionSelected(EStreamCardMenuOptions.EDIT);
				}
			}
		]) ||
			[]),
		...((hasPermissions.edit && [
			{
				label: 'Translation',
				key: '6',
				onClick() {
					onMenuOptionSelected(EStreamCardMenuOptions.TRANSLATION);
				}
			}
		]) ||
			[]),

		...((hasPermissions.edit && [
			{
				label: 'Change Status',
				key: '3',
				onClick() {
					onMenuOptionSelected(EStreamCardMenuOptions.STATUS);
				}
			}
		]) ||
			[]),
		...((hasPermissions.edit && [
			{
				label: 'Change Visibility',
				key: '4',
				onClick() {
					onMenuOptionSelected(EStreamCardMenuOptions.VISIBILITY);
				}
			}
		]) ||
			[]),
		...((hasPermissions.delete && [
			{
				label: 'Delete',
				key: '5',
				onClick() {
					onMenuOptionSelected(EStreamCardMenuOptions.DELETE);
				},
				danger: true
			}
		]) ||
			[])
	];
};

interface IStreamMenuOptionsProps {
	onMenuOptionSelected: (option: EStreamCardMenuOptions) => void;
	hasPermissions: {
		edit: boolean;
		delete: boolean;
	};
}

const StreamMenuOptions = ({ onMenuOptionSelected, hasPermissions }: IStreamMenuOptionsProps) => (
	<Dropdown
		menu={{
			items: createMenuOptions({ onMenuOptionSelected, hasPermissions })
		}}
		trigger={['click']}
	>
		<MoreOutlined
			style={{
				fontSize: '20px',
				color: 'var(--color-text-grey)'
			}}
		/>
	</Dropdown>
);

export default StreamMenuOptions;
