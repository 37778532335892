import { TUserRolePermissionsName, UserRolePermissions } from './userRolePermissions';
import { StorageService } from '../../storage.service';

export const useRolePermissions = () => {
	const userRole = StorageService.get('user-role');

	const hasPermission = (permission: TUserRolePermissionsName): boolean => {
		// for accepting "as Unknown as TUserRolePermissionsName" case
		/**
		 * e.g) 
		 * 			{hasPermission(UserRolePermissions['users.delete'] as unknown as TUserRolePermissionsName) && (
							<Button onClick={() => onDelete(record.id)} type='dashed' icon={<DeleteOutlined />} />
						)}
		 */
		return UserRolePermissions[permission]?.includes(userRole);
	};

	return { hasPermission };
};
