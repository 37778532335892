import { lazy, Suspense } from 'react';

import { Drawer, Dropdown, Flex, Pagination, Spin, Typography } from 'antd';

import CardGallery from 'components/card-gallery';
import { TSaveAudioType } from 'components/drawers/SaveAudioDrawer/types';
import SearchComp from 'components/ui/search';
import { useRolePermissions } from 'services/user-role/permissions/useRolePermissions';

import { DROPDOWN_MENU_ITEMS } from './config';
import FilterButton from './FilterButton';
import { AUDIO_PER_PAGE, useAudioGallery } from './useAudioGallery';
import { TAudio } from '../../uploadAudioButton/UploadVideoButton';
import { TSelectedMedia } from '../types';

import '../../data-gallery/styles.scss';

// eslint-disable-next-line import/no-cycle
const SaveAudioDrawer = lazy(() => import('../../drawers/SaveAudioDrawer/SaveAudioDrawer'));

type Props = {
	selectMultiple?: boolean;
	onAudioSelected?: (audio: TAudio[]) => void;
	mediaType?: string;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
};

const AudioGallery = ({ selectMultiple, onAudioSelected, onSelect, mediaType }: Props) => {
	const {
		isLoading,
		dataList,
		pagination,
		sortOrderRef,
		onAudioModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		totalAudiosRef,

		selectedItemsId,
		handleItemSelect,
		saveAudioTypeRef,
		handleSaveAudio,
		openSaveAudio
	} = useAudioGallery({ selectMultiple, onAudioSelected, onSelect });

	const { hasPermission } = useRolePermissions();

	return (
		<Spin spinning={isLoading}>
			<Flex vertical gap={16}>
				{/* header */}
				<Flex justify='space-between'>
					<Flex vertical>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Audio
						</Typography.Title>
						<Typography.Paragraph style={{ margin: 0 }}>Add or remove supported audio</Typography.Paragraph>
					</Flex>

					<Flex gap={16}>
						<FilterButton sortOrder={sortOrderRef.current} selectedItem={onFilterOptionSelected} />

						{hasPermission('media.create') && (
							<Dropdown.Button
								type='primary'
								size='large'
								menu={{
									items: Object.values(DROPDOWN_MENU_ITEMS.save),
									onClick: (event: any) => {
										handleSaveAudio(event?.key as TSaveAudioType);
									}
								}}
							>
								Add Audio
							</Dropdown.Button>
						)}
					</Flex>
				</Flex>

				<SearchComp
					searchProps={{
						placeholder: 'Search Audio'
					}}
					onValueChange={onSearch}
				/>

				{/* card list */}
				<CardGallery cardType='audio' data={dataList} selectable selectedItems={selectedItemsId} onSelect={handleItemSelect} />

				<Flex justify='center'>
					<Pagination
						defaultCurrent={pagination}
						total={totalAudiosRef.current}
						pageSize={AUDIO_PER_PAGE}
						onChange={handlePageSelection}
						showSizeChanger={false}
					/>
				</Flex>
				<Drawer title='Add Audio' open={openSaveAudio} onClose={() => onAudioModalClose()} destroyOnClose>
					<Suspense>
						<SaveAudioDrawer saveAudioType={saveAudioTypeRef.current} audioDetails={undefined} onClose={onAudioModalClose} />
					</Suspense>
				</Drawer>
			</Flex>
		</Spin>
	);
};

export default AudioGallery;
