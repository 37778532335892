import { Button, Form, FormInstance, Spin } from 'antd';

import InputFields from 'components/input-fields';
import { getSlugFormField } from 'components/input-fields/form-fields';
import { EInputFieldTypes, EMediaTypes } from 'components/input-fields/types/enums';
import { TFormFields } from 'components/input-fields/types/types';
import { COUNTRY_DETAILS, FORM_SCROLL_OPTIONS } from 'config/constants';
import { API_URLS } from 'services/apiUrls';
import { filterOption, generateSlug } from 'services/helpers.service';

import { useCreateLiveStream } from './useCreateLiveStream';

interface Props {
	targetSteamId?: string;
	onClose: (refresh?: boolean) => void;
	onAddTranslation: (id: string) => void;
}

const CreateLiveStreamDrawer = ({ targetSteamId, onClose, onAddTranslation }: Props) => {
	const { form, isSaving, isLoading, onSubmit } = useCreateLiveStream({ targetSteamId, onClose });

	const formFields = getFormFields({
		form,
		fields: {
			countryId: { options: [COUNTRY_DETAILS.IN as any] }
		}
	});

	return (
		<Spin spinning={isLoading}>
			<Form form={form} layout='vertical' onFinish={onSubmit} scrollToFirstError={FORM_SCROLL_OPTIONS} disabled={isSaving}>
				<InputFields formFields={formFields} />

				<Button loading={isSaving} type='primary' htmlType='submit' block size='large' className='mt-4'>
					{targetSteamId ? 'Update' : 'Add'} Live Stream
				</Button>
				<Button
					type='default'
					block
					size='large'
					className='mt-4'
					disabled={!targetSteamId}
					onClick={() => targetSteamId && onAddTranslation(targetSteamId)}
				>
					Add Translation
				</Button>
			</Form>
		</Spin>
	);
};

export default CreateLiveStreamDrawer;

type TGetFormFields = {
	form: FormInstance;
	fields: {
		countryId?: {
			options: { label: string; value: string }[];
		};
		[key: string]: any;
	};
};

const getFormFields = ({ form, fields }: TGetFormFields): TFormFields => {
	const countryId = form.getFieldValue('countryId');

	return {
		title: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Title',
				name: 'title',
				rules: [{ required: true, message: 'Please input title!' }]
			},
			fieldProps: {
				placeholder: 'Enter Title',
				onChange: () => {
					const slug = generateSlug(form, ['title']);
					form.setFieldValue('slug', slug);
					form.validateFields(['slug']);
				}
			}
		},
		slug: getSlugFormField(),
		categories: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Category',
				name: 'categories',
				rules: [{ required: true, message: 'Please select category!' }]
			},
			api: {
				url: API_URLS.ALL_CATEGORIES,
				responseDataStructure: 'data',
				labelKey: 'title',
				valueKey: 'id'
			},
			fieldProps: {
				placeholder: 'Select Category',
				filterOption,
				mode: 'multiple'
			}
		},
		authorId: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Author',
				name: 'authorId',
				rules: [{ required: true, message: 'Please select author!' }]
			},
			api: {
				url: API_URLS.AUTHOR_ALL,
				responseDataStructure: 'data',
				getOptionLabel: (data: any) => `${data?.firstName} ${data?.lastName}`,
				labelKey: 'firstName',
				valueKey: 'id'
			},
			fieldProps: {
				placeholder: 'Select Author',
				filterOption
			}
		},
		countryId: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Country',
				name: 'countryId',
				rules: [{ required: true, message: 'Please select country!' }]
			},
			fieldProps: {
				disabled: true,
				placeholder: 'Select Country',
				options: fields?.countryId?.options || []
			}
		},
		stateId: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'State',
				name: 'stateId',
				rules: [{ required: true, message: 'Please select state!' }]
			},
			api: {
				url: `${API_URLS.MASTER_STATES}/${countryId}`,
				responseDataStructure: 'data',
				labelKey: 'name',
				valueKey: 'id'
			},
			fieldProps: {
				placeholder: 'Select State',
				filterOption
			}
		},
		districts: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'District',
				name: 'districts'
			},
			api: {
				url: API_URLS.MASTER_DISTRICTS,
				responseDataStructure: 'data',
				labelKey: 'name',
				valueKey: 'id'
			},
			fieldProps: {
				mode: 'multiple',
				filterOption,
				placeholder: 'Select District'
			}
		},
		description: {
			fieldType: EInputFieldTypes.TEXTAREA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Description',
				name: 'description',
				rules: [{ required: true, message: 'Please input description!' }]
			},
			fieldProps: {
				placeholder: 'Enter Description',
				rows: 4
			}
		},
		thumbnail: {
			fieldType: EInputFieldTypes.SELECT_MEDIA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Thumbnail Image',
				name: 'thumbnail',
				rules: [
					{
						required: true,
						message: 'Please select thumbnail image!'
					}
				]
			},
			fieldProps: {
				formInstance: form,
				button: {
					text: 'Select Thumbnail Image'
				},
				drawer: {
					title: 'Select Image'
				},
				mediaGallery: {
					mediaType: EMediaTypes.IMAGE,
					selectMultiple: false,
					imageType: 'card-wide'
				}
			}
		},
		collections: {
			fieldType: EInputFieldTypes.API_DRIVEN_SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Collections',
				name: 'collections'
			},
			api: {
				url: API_URLS.COLLECTION,
				responseDataStructure: 'items',
				labelKey: 'title',
				valueKey: 'id'
			},
			fieldProps: {
				mode: 'multiple',
				filterOption,
				placeholder: 'Select Collections'
			}
		}
	};
};
