export type TGetTableColumns = {
	onEdit: (audioId: string) => void;
	onDelete: (audioId: string) => void;
	hasEditPermission: boolean;
	hasDeletePermission: boolean;
};

export enum ESaveAudioType {
	single = 'single',
	bulk = 'bulk'
}

export type TSaveAudioType = ESaveAudioType.single | ESaveAudioType.bulk;

export type TAudioParams = {
	q?: string;
	page: number;
	limit: number;
	tags?: string[];
	sortBy?: string;
	sort: string;
};

export interface IHeader {
	title: string;
	description?: string;
	params: TAudioParams;
	onClickSaveAudio: (action: TSaveAudioType) => void;
}

export interface IFilters {
	fetchAudiosParamsRef: React.MutableRefObject<TAudioParams>;
	onSearch: (value: string) => void;
	onFiltersChange?: () => void;
}
