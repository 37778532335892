/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { createContext, useMemo } from 'react';

import { message } from 'antd';

import { ApiHandlersService } from 'services/api-handlers.service';

const UtilContext = createContext({
	showSuccess: (message: any) => {},
	showError: (message: any) => {},
	showWarning: (message: any) => {},
	showInfo: (message: any) => {},
	show: (type: 'success' | 'info' | 'error' | 'warning', content: string | React.ReactNode, className?: string, style?: any) => {}
});

const UtilsProvider = ({ children }: any) => {
	const [messageApi, contextHolder] = message.useMessage();

	const showSuccess = (message: string) => {
		messageApi.success(message);
	};

	const showError = (message: string, duration?: number, onClose?: VoidFunction) => {
		messageApi.error(ApiHandlersService.generateErrorMessage(message), duration, onClose);
	};

	const showWarning = (message: string) => {
		messageApi.warning(message);
	};

	const showInfo = (message: string) => {
		messageApi.info(message);
	};

	const show = (type: 'success' | 'info' | 'error' | 'warning', content: string | React.ReactNode, className = '', style: any = {}) => {
		messageApi.open({
			type,
			content,
			className,
			style
		});
	};

	const values = useMemo(
		() => ({
			showSuccess,
			showError,
			showWarning,
			showInfo,
			show
		}),
		[]
	);

	return (
		<UtilContext.Provider value={values}>
			{contextHolder}
			{children}
		</UtilContext.Provider>
	);
};

const useUtils = () => {
	const context = React.useContext(UtilContext);
	if (context === undefined) {
		throw new Error('useUtils must be used within a UtilsProvider');
	}
	return context;
};

const useUtilsNewer = () => {
	const context = React.useContext(UtilContext);

	if (context === undefined) throw new Error('useUtils must be used within a UtilsProvider');

	const showError = (error: unknown) => {
		const message = ApiHandlersService.generateErrorMessage(error);
		context.showError(message);
	};

	const showSuccess = (error: unknown) => {
		const message = ApiHandlersService.generateErrorMessage(error);
		context.showSuccess(message);
	};

	return { context, showError, showSuccess };
};

export { UtilsProvider, useUtils, useUtilsNewer };
