import { Form, Radio } from 'antd';

import { TRadioGroupFieldProps } from '../types/field-props';

const RadioGroupField: React.FC<TRadioGroupFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Radio.Group {...fieldProps} />
		</Form.Item>
	);
};

export default RadioGroupField;
