import { COUNTRY_DETAILS } from 'config/constants';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { UseQueryType } from 'services/tanstack-query/type';

export const MASTER_QUERIES = {
	fetchStates: (): UseQueryType => ({
		queryKey: [MASTER_QUERIES.fetchStates.name, API_URLS.MASTER_STATES, COUNTRY_DETAILS.IN.value],
		queryFn: async () => {
			const { data } = await HttpService.get(`${API_URLS.MASTER_STATES}/${COUNTRY_DETAILS.IN.value}`);
			return data?.data;
		}
	}),

	fetchDistricts: ({ stateId }: { stateId: string }): UseQueryType => ({
		queryKey: [MASTER_QUERIES.fetchDistricts.name, API_URLS.MASTER_DISTRICTS, stateId],
		queryFn: async () => {
			const { data } = await HttpService.get(`${API_URLS.MASTER_DISTRICTS}/${stateId}`);
			return data?.data;
		}
	}),

	fetchAllDistricts: (): UseQueryType => ({
		queryKey: [MASTER_QUERIES.fetchDistricts.name, API_URLS.MASTER_DISTRICTS],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.MASTER_DISTRICTS);
			return data?.data;
		}
	}),

	fetchLanguages: (): UseQueryType => ({
		queryKey: [MASTER_QUERIES.fetchLanguages.name, API_URLS.MASTER_LANGUAGES],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.MASTER_LANGUAGES);
			return data?.data;
		}
	})
};

export const MASTER_MUTATIONS = {};
