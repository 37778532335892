import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { COUNT_COLORS_PALETTE } from 'config/colors';
import { convertToTitleCase } from 'helpers';
import { formatToUnits } from 'services/helpers.service';

import HeaderContentBlock from '../../header-content-block';

type TProps = {
	data: any[];
};

export const TopViewedCategories = ({ data }: TProps) => {
	const { t } = useTranslation();

	return (
		<div className='mt-4'>
			<HeaderContentBlock title={t('dashboard.top10ViewedCategories')} />

			<Flex gap={8} className='overflow-auto pb-2'>
				{data?.map((item, index) => {
					return (
						<Flex
							key={index}
							flex={1}
							vertical
							className='h-24 min-w-44 rounded-md shadow-md hover:bg-opacity-20'
							style={{
								border: 'solid 1px',
								borderColor: COUNT_COLORS_PALETTE[index],
								backgroundColor: COUNT_COLORS_PALETTE[index]
							}}
						>
							<Typography.Text className='h-14 p-2 flex-[2] text-3xl text-white'>{formatToUnits(item?.categoryCount)}</Typography.Text>
							<Typography.Text className='w-full h-10 p-2 flex-1 text-sm font-semibold text-white'>
								{convertToTitleCase(item?.categoryTitle)}
							</Typography.Text>
						</Flex>
					);
				})}
			</Flex>
		</div>
	);
};
