import { ButtonProps, ColProps } from 'antd';

import {
	TApiDrivenSelectFieldProps,
	TCustomTagFieldProps,
	TDateRangePickerProps,
	TNumberFieldProps,
	TRadioGroupFieldProps,
	TSelectFieldProps
} from 'components/input-fields/types/field-props';
import { TFormFieldProps } from 'components/input-fields/types/types';

// Predefined form fields name
export enum EFilterNames {
	RECORD_LIMIT = 'recordsLimit',
	SORT_BY = 'sortBy',
	SORT_ORDER = 'sortOrder',
	CONTENT_STATUS = 'contentStatus',
	DATE_RANGE = 'dateRange',
	STATE = 'state',
	DISTRICT = 'district',
	CATEGORY = 'category',
	GENRE = 'genre',
	PARTY = 'party',
	TAG = 'tag',
	DRM_PROTECTED = 'drmProtected',
	IMAGE_TYPE = 'imageType',
	UPDATED_BY = 'updatedBy',
	CREATED_BY = 'createdBy',
	PAID_USER_BY = 'paidUserBy'
}

type TOptionalNameInFormItemProps<T> = Omit<T, 'formItemProps'> & {
	formItemProps?: Omit<TSelectFieldProps['formItemProps'], 'name'> & {
		name?: string;
	};
};

type TFilterApiDriveSelectFieldProps = Omit<TOptionalNameInFormItemProps<TApiDrivenSelectFieldProps>, 'api'> & {
	api?: { excludeValues?: string[]; params?: { [key: string]: any } };
};

type TFilterSelectFieldProps = TOptionalNameInFormItemProps<TSelectFieldProps>;

type TTagType = Omit<TOptionalNameInFormItemProps<TCustomTagFieldProps>, 'fieldType'>;

type PredefinedFormFieldTypes = {
	[EFilterNames.RECORD_LIMIT]: TOptionalNameInFormItemProps<TNumberFieldProps>;
	[EFilterNames.SORT_BY]: TFilterSelectFieldProps;
	[EFilterNames.SORT_ORDER]: TOptionalNameInFormItemProps<TRadioGroupFieldProps>;
	[EFilterNames.CONTENT_STATUS]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.DATE_RANGE]: TOptionalNameInFormItemProps<TDateRangePickerProps>;
	[EFilterNames.STATE]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.DISTRICT]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.CATEGORY]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.GENRE]: TFilterSelectFieldProps;
	[EFilterNames.PARTY]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.TAG]: TTagType;
	[EFilterNames.DRM_PROTECTED]: TOptionalNameInFormItemProps<TRadioGroupFieldProps>;
	[EFilterNames.IMAGE_TYPE]: TFilterSelectFieldProps;
	[EFilterNames.UPDATED_BY]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.CREATED_BY]: TFilterApiDriveSelectFieldProps;
	[EFilterNames.PAID_USER_BY]: TFilterApiDriveSelectFieldProps;
};

export type TGlobalFilterResult = {
	recordsLimit: number | undefined;
	sortBy: string | undefined;
	sortOrder: string | undefined;
	contentStatus: string | undefined;
	dateRange: [0, 1] | undefined;
	state: string | undefined;
	district: string | undefined;
	category: string | undefined;
	author: string[] | undefined;
	collections: string[] | undefined;
	genre: string | undefined;
	party: string | undefined;
	tag: string[] | undefined;
	drmProtected: boolean | undefined;
	imageType: string | undefined;
	updatedBy?: string;
	createdBy?: string;
	paidUserBy?: any;
	[key: string]: any;
};

// fieldNames is the list of predefined form fields
export type TGlobalFilterConfig = {
	className?: string;
	fieldNames: `${EFilterNames}`[] | string[];
	formFields?: {
		predefined?: {
			[K in keyof PredefinedFormFieldTypes]?: Omit<PredefinedFormFieldTypes[K], 'fieldType'> & {
				colProps?: ColProps;
			};
		};
		custom?: {
			[key: string]: Omit<TFormFieldProps['fieldProps'], 'formInstance'>;
		};
	};
	filterButtonProps?: ButtonProps;
	onClose?: () => void;
	onSubmit: (filters: TGlobalFilterResult) => void;
};
