import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { UseQueryType } from 'services/tanstack-query/type';

export const EDUCATION_GRADE_QUERIES = {
	fetchGrades: ({ apiParams }: { apiParams: any }): UseQueryType => ({
		queryKey: [
			EDUCATION_GRADE_QUERIES.fetchGrades.name,
			API_URLS.EDUCATION_GRADE,
			apiParams,
			'educationFetchGradesKey'
		], // DO NOT CHANGE THE educationFetchGradesKey, used in the resetQueries
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.EDUCATION_GRADE, apiParams);
			return data?.data;
		}
	}),
	fetchGradeById: ({ id, language }: { id: string; language?: string }): UseQueryType => ({
		queryKey: [EDUCATION_GRADE_QUERIES.fetchGrades.name, API_URLS.EDUCATION_GRADE, id, language],
		queryFn: async () => {
			const { data } = await HttpService.get(
				`${API_URLS.EDUCATION_GRADE}/${id}`,
				{},
				{ headers: { 'accept-language': language } }
			);
			return data?.data;
		}
	})
};

export const EDUCATION_GRADE_MUTATIONS = {
	addGrade: async ({ apiParams }: { apiParams: any }) => {
		const { data } = await HttpService.post(API_URLS.EDUCATION_GRADE, apiParams);
		return data;
	},

	updateGrade: async ({ id, apiParams }: { id: string; apiParams: any }) => {
		const { data } = await HttpService.patch(`${API_URLS.EDUCATION_GRADE}/${id}`, apiParams);
		return data;
	},
	updateGradeLang: async ({ id, apiParams, language }: { id: string; apiParams: any; language?: string }) => {
		const { data } = await HttpService.patch(
			`${API_URLS.EDUCATION_GRADE}/${id}/language`,
			apiParams,
			{},
			{ headers: { 'accept-language': language } }
		);
		return data;
	},
	deleteGrade: async ({ id }: { id: string }) => {
		const { data } = await HttpService.delete(`${API_URLS.EDUCATION_GRADE}/${id}`);
		return data;
	}
};
