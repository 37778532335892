import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { AxiosResponse } from 'axios';

import { useUtils } from './UtilsProvider';
import { API_URLS } from '../services/apiUrls';
import { HttpService } from '../services/http.service';

// Define the category type
export type CategoryType = {
	id?: string;
	title: string;
	description: string;
	image: any;
	parentId: string;
	slug: string;
	internalEnum: string;
	titleTranslation?: object;
	descriptionTranslation?: object;
};

// Define the context value type
interface CategoryContextType {
	// isDataLoading: boolean;
	category: any;
	allCategory: any;
	getAllCategory: () => Promise<any>;
	addCategory: (categoryData: CategoryType) => Promise<any>;
	getAllSubCategory: (id: string) => Promise<any>;
	updateCategoryById: (id: string, categoryData: CategoryType, language?: string) => Promise<AxiosResponse>;
	deleteCategoryById: (id: string) => Promise<any>;
	getCategoryBySlug: (id: string, language?: string) => Promise<any>;
	getAllCategoryWithPagination: (params?: any) => Promise<any>;
	deleteSubCategory: (id: string) => Promise<any>;
}

// Create the context
const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

// Define the provider props
interface CategoryProviderProps {
	children: ReactNode;
}

// todo refac
// Create the CategoryProvider component
const CategoryProvider = ({ children }: CategoryProviderProps) => {
	const [category, setCategory] = useState([]); // Paginated
	const [allCategory, setAllCategory] = useState([]); // Not paginated

	const { showError } = useUtils();

	const getAllCategory = useCallback(async () => {
		const res: any = await HttpService.get(API_URLS.ALL_CATEGORIES).catch((err) => {
			showError(err);
			throw err;
		});

		setAllCategory(res.data.data);
		return res?.data?.data;
	}, []);

	const addCategory = useCallback(async (categoryData: CategoryType) => {
		const response = await HttpService.post(API_URLS.CATEGORY, categoryData as any).catch((err) => {
			showError(err);
			throw err;
		});

		return response;
	}, []);

	const getAllSubCategory = useCallback(async (id: string) => {
		setCategory([]);
		const data: any = await HttpService.get(`${API_URLS.CATEGORY}/${id}/sub-categories`).catch((err) => {
			showError(err);
			throw err;
		});
		return data;
	}, []);

	const updateCategoryById = useCallback(async (id: string, categoryData: CategoryType, language?: string) => {
		if (language) {
			const data: any = await HttpService.patch(
				`${API_URLS.CATEGORY}/${id}/language`,
				categoryData,
				{},
				{ headers: { 'accept-language': language } }
			).catch((err) => {
				showError(err);
				throw err;
			});
			return data;
		}
		const data: any = await HttpService.put(`${API_URLS.CATEGORY}/${id}`, categoryData).catch((err) => {
			showError(err);
			throw err;
		});
		return data;
	}, []);

	const deleteCategoryById = useCallback(async (id: string) => {
		const data: any = await HttpService.delete(`${API_URLS.CATEGORY}/${id}`).catch((err) => {
			showError(err);
			throw err;
		});
		return data;
	}, []);

	const getCategoryBySlug = useCallback(async (id: string, language?: string) => {
		const data: any = await HttpService.get(`${API_URLS.CATEGORY}/${id}`, {}, { headers: { 'accept-language': language } }).catch((err) => {
			showError(err);
			throw err;
		});
		return data;
	}, []);

	const getAllCategoryWithPagination = useCallback(async (params?: any) => {
		const res: any = await HttpService.get(API_URLS.CATEGORY, params).catch((err) => {
			showError(err);
			throw err;
		});
		setCategory(res.data.data);
		return res.data.data;
	}, []);

	const deleteSubCategory = useCallback(async (id: string) => {
		const data: any = await HttpService.delete(`${API_URLS.CATEGORY}/sub-category/${id}`).catch((err) => {
			showError(err);
			throw err;
		});
		return data;
	}, []);

	// Combine all values into a single object
	const values: CategoryContextType = useMemo(
		() => ({
			category,
			allCategory,
			getAllCategory,
			addCategory,
			getAllSubCategory,
			updateCategoryById,
			deleteCategoryById,
			getCategoryBySlug,
			getAllCategoryWithPagination,
			deleteSubCategory
		}),
		[
			addCategory,
			allCategory,
			category,
			deleteCategoryById,
			deleteSubCategory,
			getAllCategory,
			getAllCategoryWithPagination,
			getAllSubCategory,
			getCategoryBySlug,
			updateCategoryById
		]
	);

	return <CategoryContext.Provider value={values}>{children}</CategoryContext.Provider>;
};

// Create the useCategory hook
const useCategory = () => {
	const context = useContext(CategoryContext);
	if (!context) {
		throw new Error('useCategory must be used within a CategoryProvider');
	}
	return context;
};

export { CategoryContext, CategoryProvider, useCategory };
