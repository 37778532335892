import { Empty, Flex, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { COLORS_PALETTE } from 'config/colors';
import { formatToUnits } from 'services/helpers.service';

import useSubscribersJoinedVsLeft from './useSubscirbersJoinedVsLeft';
import { ESubscribersPeriodTypes } from '../../../type';
import HeaderContentBlock from '../../header-content-block';

const SubscribersJoinedVsLeft = () => {
	const { t } = useTranslation();

	const { DEFAULT_SELECTED_PERIOD, isLoading, subscribersData, handlePeriodChange } = useSubscribersJoinedVsLeft();

	const chartOptions = {
		tooltip: {
			trigger: 'axis'
		},
		legend: {
			data: [t('dashboard.joined'), t('dashboard.left')],
			bottom: 0, // Positions the legend at the bottom with 20px spacing from the chart
			itemWidth: 30, // Width of the legend icon, making it narrow
			itemHeight: 10 // Height of the legend icon, making it look like a line
		},
		xAxis: {
			type: 'category',
			boundaryGap: true,
			data: subscribersData?.map((data: any) => data?.name),
			axisLabel: {
				rotate: 45
			}
		},
		yAxis: {
			type: 'value'
		},
		series: [
			{
				name: t('dashboard.joined'),
				type: 'line',
				data: subscribersData?.map((data: any) => formatToUnits(data?.joined)),
				smooth: true,
				itemStyle: {
					color: COLORS_PALETTE.color100
				}
			},
			{
				name: t('dashboard.left'),
				type: 'line',
				data: subscribersData?.map((data: any) => formatToUnits(data?.left)),
				smooth: true,
				itemStyle: {
					color: COLORS_PALETTE.color800
				}
			}
		]
	};

	return (
		<div className='h-full'>
			<HeaderContentBlock
				title={t('dashboard.subscribersJoinedVsLeft')}
				selectProps={{
					defaultValue: DEFAULT_SELECTED_PERIOD,
					onChange: handlePeriodChange,
					options: [
						{ label: t('common.weekly'), value: ESubscribersPeriodTypes.WEEKLY },
						{ label: t('common.monthly'), value: ESubscribersPeriodTypes.MONTHLY },
						{ label: t('common.yearly'), value: ESubscribersPeriodTypes.YEARLY }
					]
				}}
			/>

			<Flex justify='center' align='center' className='h-full w-full'>
				{isLoading && <Spin />}
			</Flex>

			{!isLoading && subscribersData?.length > 0 ? (
				<ReactECharts option={chartOptions} style={{ height: '90%' }} />
			) : (
				<Flex justify='center' align='center' className='h-[86%] w-full'>
					<Empty />
				</Flex>
			)}
		</div>
	);
};

export default SubscribersJoinedVsLeft;
