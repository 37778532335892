/* eslint-disable no-unsafe-optional-chaining */
import { useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Form } from 'antd';

import { COUNTRY_DETAILS } from 'config/constants';
import { useUtils } from 'contexts/UtilsProvider';
import { LIVE_STREAM_MUTATIONS, LIVE_STREAM_QUERIES } from 'services/tanstack-query/live-stream';
import { FetchLiveSteamByIdData } from 'types/live-stream';

type Props = {
	targetSteamId?: string;
	onClose: (refresh?: boolean, id?: string) => void;
};

export const useCreateLiveStream = ({ targetSteamId, onClose }: Props) => {
	const { showError, showSuccess } = useUtils();

	const [form] = Form.useForm();
	form.setFieldValue('countryId', COUNTRY_DETAILS.IN.value);

	const fetchLiveSteamById = useQuery<any, any, FetchLiveSteamByIdData>({
		...LIVE_STREAM_QUERIES.fetchLiveSteamById(targetSteamId ?? ''),
		enabled: !!targetSteamId
	});

	useEffect(() => {
		if (fetchLiveSteamById.data) {
			const { data } = fetchLiveSteamById;

			form.setFieldsValue({
				title: data?.title,
				slug: data?.slug,
				categories: data?.categories?.map((category) => category?.id),
				authorId: data?.author?.id,
				stateId: data?.state?.id && +data?.state?.id,
				districts: data?.districtes?.map((district) => district?.id && +district?.id),
				description: data?.description,
				thumbnail: data?.image,
				collections: data?.collections?.map((collection) => collection?.id)
			});
		}
	}, [fetchLiveSteamById.data]);

	const registerLivesStream = useMutation({
		...LIVE_STREAM_MUTATIONS.createLiveStream(),
		onError: (error) => {
			showError(error);
		},
		onSuccess: ({ data: { data } }) => {
			showSuccess('Live stream added successfully');
			onClose(true, data?.id);
		}
	});

	const updateLivesStream = useMutation({
		...LIVE_STREAM_MUTATIONS.updateLiveStream(targetSteamId ?? ''),
		onError: (error) => {
			showError(error);
		},
		onSuccess: () => {
			showSuccess('Live stream updated successfully');
			onClose(true);
		}
	});

	const saveStream = (formValues: any) => {
		const data = {
			title: formValues.title,
			slug: formValues.slug,
			categories: formValues.categories,
			authorId: formValues.authorId,
			countryId: formValues.countryId,
			stateId: formValues.stateId,
			districts: formValues.districts,
			description: formValues.description,
			image: formValues.thumbnail?.id,
			collections: formValues?.collections
		};
		if (targetSteamId) {
			updateLivesStream.mutateAsync(data).catch(showError);
		} else {
			registerLivesStream.mutateAsync(data).catch(showError);
		}
	};

	return {
		form,
		isSaving: registerLivesStream.isPending || updateLivesStream.isPending,
		isLoading: fetchLiveSteamById.isLoading,
		onSubmit: saveStream
	};
};
