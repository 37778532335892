import { Form, Input } from 'antd';

import { TTextFieldProps } from '../types/field-props';

const TextField: React.FC<TTextFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Input size='large' {...fieldProps} />
		</Form.Item>
	);
};

export default TextField;
