import { Form, Input } from 'antd';

import { TTextAreaFieldProps } from '../types/field-props';

const TextareaField: React.FC<TTextAreaFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Input.TextArea size='large' {...fieldProps} />
		</Form.Item>
	);
};

export default TextareaField;
