import React from 'react';

import { VideoCameraOutlined } from '@ant-design/icons';
import { Avatar, Flex, Typography } from 'antd';
import './stream-gallery.scss';

import { useRolePermissions } from 'services/user-role/permissions/useRolePermissions';
import { FetchLiveSteamsData } from 'types/live-stream';

import StreamMenuOptions, { EStreamCardMenuOptions } from './StreamMenuOptions';

interface CardProps {
	cardData: FetchLiveSteamsData;
	onMenuOptionSelected?: (option: EStreamCardMenuOptions, liveStreamData: FetchLiveSteamsData) => void;
	onLiveStreamClicked?: (streamUID: string) => void;
	onSelect?: (stream: FetchLiveSteamsData) => void;
}

const StreamCard: React.FC<CardProps> = ({ cardData, onMenuOptionSelected, onLiveStreamClicked, onSelect }) => {
	const { hasPermission } = useRolePermissions();

	const handleMenuOptionSelected = (option: EStreamCardMenuOptions) => {
		onMenuOptionSelected?.(option, cardData);
	};

	return (
		<div>
			<div
				className={`card ${!cardData?.isActive && 'opacity-70'}`}
				onClick={() => {
					onLiveStreamClicked?.(cardData?.id);
					onSelect?.(cardData);
				}}
			>
				{cardData?.image ? (
					<img
						src={cardData?.image?.urls?.url}
						alt={cardData?.title || 'stream'}
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'fill'
						}}
					/>
				) : (
					<div className='flex flex-1 justify-center '>
						<Avatar
							className='self-center w-20 h-20 bg-gray-200'
							icon={<VideoCameraOutlined className='text-4xl text-gray-500' />}
						/>
					</div>
				)}

				<div className='flex justify-between absolute top-2 left-3 bg-white rounded-md p-1 items-center gap-1'>
					{cardData?.status === 'live' && <span className='rounded-full bg-red-700 w-2 h-2' />}
					<p className='capitalize text-xs font-semibold'>{cardData?.status}</p>
				</div>
			</div>

			<div className='card-footer'>
				<div className='card-footer-left'>
					<Flex vertical>
						<Typography.Text className='card-footer-title' ellipsis={{ tooltip: true }}>
							{cardData?.title}
						</Typography.Text>
						<Typography.Text className='card-footer-description' ellipsis={{ tooltip: true }}>
							{cardData?.description}
						</Typography.Text>
					</Flex>
				</div>

				<div>
					{hasPermission('media.showActions') && handleMenuOptionSelected && (
						<StreamMenuOptions
							onMenuOptionSelected={handleMenuOptionSelected}
							hasPermissions={{
								edit: hasPermission('media.edit'),
								delete: hasPermission('media.delete')
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default StreamCard;
