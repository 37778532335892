import ApiDrivenSelect from '../api-driven-select';
import { ETagTypes, EInputFieldTypes } from 'components/input-fields/types/enums';
import { API_URLS } from 'services/apiUrls';
import { TCustomTagFieldProps } from 'components/input-fields/types/field-props';

const TAG_API_URLS_HASH: { [key in `${ETagTypes}`]: string } = {
	[ETagTypes.IMAGE]: API_URLS.MEDIA_IMAGE_TAGS,
	[ETagTypes.AUDIO]: API_URLS.MEDIA_AUDIO_TAGS,
	[ETagTypes.VIDEO]: API_URLS.MEDIA_VIDEO_TAGS,
	[ETagTypes.ARTICLE]: API_URLS.ARTICLE_TAGS,
	[ETagTypes.PODCAST]: API_URLS.PODCAST_TAGS,
	[ETagTypes.MOVIE]: API_URLS.MOVIES_TAGS,
	[ETagTypes.SERIES]: API_URLS.SERIES_TAGS,
	[ETagTypes.POLL]: API_URLS.POLL_TAGS,
};

const TagField: React.FC<TCustomTagFieldProps> = ({ tagType, formItemProps, fieldProps }) => {
	const { name, ...restFormItemProps } = formItemProps;

	return (
		<ApiDrivenSelect
			fieldType={EInputFieldTypes.API_DRIVEN_SELECT}
			api={{
				url: TAG_API_URLS_HASH[tagType],
				responseDataStructure: 'tags',
			}}
			formItemProps={{
				name: name || 'tags',
				label: 'Tags',
				...restFormItemProps,
			}}
			fieldProps={{
				mode: 'multiple',
				placeholder: 'Select Tags',
				allowClear: true,

				...fieldProps,
			}}
		/>
	);
};

export default TagField;
