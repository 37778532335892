import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { STATE_DETAILS } from 'config/constants';
import { DASHBOARD_QUERIES } from 'services/tanstack-query/dashboard-apis';
import { MASTER_QUERIES } from 'services/tanstack-query/master-apis';

const DEFAULT_SELECTED_STATE_VALUE = String(STATE_DETAILS.KERALA.value);

const useDistrictWiseSubscribers = () => {
	const [stateId, setStateId] = useState(DEFAULT_SELECTED_STATE_VALUE);

	const queryStates = useQuery({ ...MASTER_QUERIES.fetchStates() });
	const querySubscribersByState = useQuery({ ...DASHBOARD_QUERIES.fetchSubscribersByState({ stateId }), refetchInterval: 10000 });

	const handleStateChange = (value: string) => {
		setStateId(value);
	};

	const districtsSubscribers = useMemo(() => {
		return (querySubscribersByState?.data || [])?.filter((item: any) => item?.status === 'PAID');
	}, [querySubscribersByState?.data]);

	return {
		DEFAULT_SELECTED_STATE_VALUE,
		isLoading: querySubscribersByState.isLoading,
		districtsSubscribers,
		queryStates,
		querySubscribersByState,
		handleStateChange
	};
};

export default useDistrictWiseSubscribers;
