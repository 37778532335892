export enum EUserRoles {
	SUPER_ADMIN = 1,
	ADMIN = 2,
	PUBLISHER = 3,
	REVIEWER = 4, // (Moderator)
	CONTENT_CREATOR = 5,
	VIEWER = 6,
	CLIENT = 7
}

export const hashRoleIdToRoleTitle: Record<EUserRoles, string> = {
	[EUserRoles.SUPER_ADMIN]: 'Super admin',
	[EUserRoles.ADMIN]: 'Admin',
	[EUserRoles.PUBLISHER]: 'Publisher',
	[EUserRoles.REVIEWER]: 'Reviewer',
	[EUserRoles.CONTENT_CREATOR]: 'Content Creator',
	[EUserRoles.VIEWER]: 'Viewer',
	[EUserRoles.CLIENT]: 'Client'
};
