/* eslint-disable @typescript-eslint/no-use-before-define */
import { NavigateOptions, To } from 'react-router-dom';

import { EGlobalSearchContentTypes, IGlobalSearchResponseListItem } from 'types/global-search';

import { useSearchContext } from '../provider/search-hook';

const useSearchData = () => {
	const { querySearchData, searchApiParams, setSearchApiParams, setOpenSearchData } = useSearchContext();

	return {
		querySearchData,
		searchApiParams,
		setSearchApiParams,
		setOpenSearchData,
		getHashContentTypeToItemData,
		getHashContentTypeToNavigatePath
	};
};

export default useSearchData;

type THashContentTypeToItemData = {
	[key in EGlobalSearchContentTypes]: {
		title: string;
		description: string | undefined;
		tagColor: string;
		imageUrl: string;
	};
};
const getHashContentTypeToItemData = (data: IGlobalSearchResponseListItem): THashContentTypeToItemData => {
	return {
		[EGlobalSearchContentTypes.ARTICLE]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'blue',
			imageUrl: (data as any)?.cardPreview?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.CATEGORY]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'green',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.DISTRICT]: {
			title: data?.title,
			description: undefined, // No description for district
			tagColor: 'orange',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.MOVIE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'volcano',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.SERIES]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'purple',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.PODCAST]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'gold',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.STREAM]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'cyan',
			imageUrl: (data as any)?.image?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.POLL]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'geekblue',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.RAFFLE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'magenta',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.DYNAMIC_PAGE]: {
			title: data?.title,
			description: data?.summary,
			tagColor: 'brown',
			imageUrl: ''
		},
		[EGlobalSearchContentTypes.PARTY]: {
			title: data?.title,
			description: undefined, // Summary is html text (Rich text)
			tagColor: 'red',
			imageUrl: (data as any)?.thumbnail?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.LEADER]: {
			title: `${data?.firstName} ${data?.lastName}`,
			description: undefined, // No description for leader
			tagColor: 'pink',
			imageUrl: (data as any)?.profileImage?.urls?.thumbnail
		},
		[EGlobalSearchContentTypes.EDUCATION_COURSE]: {
			title: data?.title,
			description: data?.description,
			tagColor: 'gold',
			imageUrl: (data as any)?.profileImage?.urls?.thumbnail
		}
	};
};

type THashContentTypeToNavigatePath = { [key in EGlobalSearchContentTypes]: { to: To; options?: NavigateOptions } };
const getHashContentTypeToNavigatePath = (data: IGlobalSearchResponseListItem): THashContentTypeToNavigatePath => {
	return {
		[EGlobalSearchContentTypes.ARTICLE]: { to: `/edit-article/${data?.id}` },
		[EGlobalSearchContentTypes.CATEGORY]: { to: '/categories', options: { state: { searchCategorySlug: data?.slug } } }, // Drawer
		[EGlobalSearchContentTypes.DISTRICT]: { to: `/districts`, options: { state: { searchDistrictId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.MOVIE]: { to: `/edit-movie/${data?.id}` },
		[EGlobalSearchContentTypes.SERIES]: { to: `/edit-series/${data?.id}` },
		[EGlobalSearchContentTypes.PODCAST]: { to: '/podcasts', options: { state: { searchPodcastId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.STREAM]: { to: '/live-streams', options: { state: { searchLiveStreamId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.POLL]: { to: `/polls/edit-poll/${data?.id}` },
		[EGlobalSearchContentTypes.RAFFLE]: { to: '/raffles', options: { state: { searchRaffleId: data?.id } } }, // Drawer
		[EGlobalSearchContentTypes.DYNAMIC_PAGE]: { to: `/edit-dynamic-page/${data?.id}` },
		[EGlobalSearchContentTypes.PARTY]: { to: `/edit-party/${data?.id}` },
		[EGlobalSearchContentTypes.LEADER]: { to: `/edit-leader/${data?.id}` },
		[EGlobalSearchContentTypes.EDUCATION_COURSE]: {
			to: `/education-courses/`,
			options: { state: { searchCourseId: data?.id } }
		} // Drawer
	};
};
