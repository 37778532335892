import { Form, Select } from 'antd';

import { TSelectFieldProps } from '../types/field-props';

// todo define props type
const SelectField: React.FC<TSelectFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Select size='large' allowClear showSearch {...fieldProps} />
		</Form.Item>
	);
};

export default SelectField;
