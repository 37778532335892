import axios, { AxiosRequestConfig } from 'axios';

import { StorageService } from './storage.service';
import { environment } from '../config/environment';

const getInstance = () => {
	const instance = axios.create({
		baseURL: environment.baseUrl,
		headers: {
			'if-platform': 'admin',
			language: 'ab'
		}
	});

	instance.interceptors.request.use((config) => {
		const token = StorageService.get('access-token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		const selectedOrganizationId = StorageService.get('selected-organization-id');
		if (selectedOrganizationId) {
			config.headers['Selected-Organization-Id'] = selectedOrganizationId;
		}

		return config;
	});

	if (window && window.location) {
		instance.interceptors.response.use(
			(response: any) => {
				return response;
			},
			(error: any) => {
				if (error.response?.status === 401 && !window.location.pathname.includes('/auth/login')) {
					StorageService.remove('access-token');
					window.location.href = '/auth/login';
				}

				const errorMessage = error.response?.data?.message || error.message || 'Something went wrong';

				return Promise.reject(errorMessage);
			}
		);
	}

	return instance;
};

const get = (url: string, params: any = {}, config: AxiosRequestConfig = {}) => {
	return getInstance().get(url, { params, ...config });
};

const post = (url: string, data: any = {}, params: any = {}, config: AxiosRequestConfig = {}) => {
	return getInstance().post(url, data, { params, ...config });
};

const put = (url: string, data: any = {}, params: any = {}, config: AxiosRequestConfig = {}) => {
	return getInstance().put(url, data, { params, ...config });
};

const patch = (url: string, data: any = {}, params: any = {}, config: AxiosRequestConfig = {}) => {
	return getInstance().patch(url, data, { params, ...config });
};

const remove = (url: string, params: any = {}, data?: any, config: AxiosRequestConfig = {}) => {
	return getInstance().delete(url, { params, data, ...config });
};

export const HttpService = {
	get,
	post,
	put,
	patch,
	delete: remove
};
