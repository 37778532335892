import { Select, SelectProps } from 'antd';

type TProps = {
	title: string;
	selectProps?: SelectProps;
};

const HeaderContentBlock = (props: TProps) => {
	const { title, selectProps } = props;
	return (
		// do not change it Flex changes my design
		<div className='flex justify-between items-center mb-4'>
			<h3>{title}</h3>

			{selectProps && <Select className='w-[130px]' {...selectProps} />}
		</div>
	);
};

export default HeaderContentBlock;
