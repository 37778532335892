import { Avatar, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
export type EditorInfoProps = {
	userName?: string;
	startEditTime?: string;
	profileURL?: string;
	contentType?: string;
	withHeader?: boolean;
	className?: string;
};

/**
 * this used for showing editing person to user who can not edit.
 */
export const EditingUserInfo = ({ userName, startEditTime, profileURL, contentType, withHeader = true, className }: EditorInfoProps) => {
	if (!startEditTime) return null;

	const body = (
		<div className={`flex gap-2 ${className ?? ''}`}>
			<Typography.Text className='content-center'>
				This {contentType} is being edited {startEditTime ? dayjs(startEditTime).fromNow() : ''} by
			</Typography.Text>
			{profileURL ? (
				<Tooltip placement='top' title={userName}>
					<Avatar src={profileURL} size='large' />
				</Tooltip>
			) : (
				<Typography.Text className='content-center font-bold'>{userName}</Typography.Text>
			)}
		</div>
	);

	if (withHeader) return body;
	return <div className={`w-full flex justify-end ${className ?? ''}`}>{body}</div>;
};
