import { TFilterTranslation } from 'localization/types/filter';

const filter: TFilterTranslation = {
	createdAt: 'Created At',
	updatedAt: 'Updated At',
	filter: 'Filter',
	close: 'Close',
	records: 'Records',
	sortOrder: 'Sort Order',
	asc: 'ASC',
	desc: 'DESC',
	sortBy: 'Sort By',
	reset: 'Reset',
	gender: 'Gender',
	showResults: 'Show Results',
	enterRecordsLimit: 'Enter record limit',
	selectSortBy: 'Select Sort By',
	dateRange: 'Date Range',
	startDate: 'Start Date',
	endDate: 'End Date',
	state: 'State',
	selectState: 'Select State',
	district: 'District',
	selectDistricts: 'Select Districts',
	category: 'Category',
	selectCategory: 'Select Category',
	party: 'Party',
	selectParty: 'Select Party',
	genre: 'Genre',
	selectGenre: 'Select Genre',
	status: 'Status',
	selectStatus: 'Select Status',
	drmProtected: 'DRM Protected',
	imageType: 'Image Type',
	selectImageType: 'Select Image Type'
};

export default filter;
