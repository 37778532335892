import { useContext } from 'react';

import { SearchContext, SearchContextType } from './search-provider';

export const useSearchContext = (): SearchContextType => {
	const context = useContext(SearchContext);
	if (!context) {
		throw new Error('useSearchContext must be used within a SearchProvider');
	}
	return context;
};
