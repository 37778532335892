import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Tabs, TabsProps } from 'antd';

import { ETabsKey } from './types';
import useSearchContentTabs from './useSearchContentTabs';
import { capitalizeEachWord } from '../../../../services/helpers.service';
import { useSearchContext } from '../provider/search-hook';
import GlobalSearchData from '../search-data/search-data';

const SearchContentTabs = () => {
	const { activeTabKey, handleTabChange } = useSearchContentTabs();
	const { setOpenSearchData } = useSearchContext();

	// Generate the tabs for each key in ETabsKey
	const contentTypesTab: TabsProps['items'] = Object.values(ETabsKey).map((key) => ({
		key,
		label: capitalizeEachWord(key?.replace('-', ' ')), // Capitalize each word
		children: <GlobalSearchData />
	}));

	return (
		<div className='relative rounded-md'>
			<Button
				icon={<CloseCircleFilled />}
				type='primary'
				className='absolute -right-4 -top-4'
				onClick={() => setOpenSearchData(false)}
			/>
			<Tabs className='p-6' size='small' items={contentTypesTab} activeKey={activeTabKey} onChange={handleTabChange} />
		</div>
	);
};

export default SearchContentTabs;
