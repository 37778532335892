import { useEffect, useRef, useState } from 'react';

import { Modal } from 'antd';
import Cropper, { ReactCropperElement } from 'react-cropper';

import { useUtils } from 'contexts/UtilsProvider';

import 'cropperjs/dist/cropper.css';

type TProps = {
	aspectRatio?: number;
	visible: boolean;
	onClose: () => void;
	image: any;
	onOk: (data: any) => void;
};

const ImageCropperModal = ({ aspectRatio, visible, onClose, image, onOk }: TProps) => {
	const utils = useUtils();
	const [imageElement, setImageElement] = useState<any>();
	const cropperRef = useRef<ReactCropperElement>(null);

	const getCropData = () => {
		const tempCropData = cropperRef?.current?.cropper?.getCroppedCanvas()?.toDataURL() || '';

		if (typeof tempCropData !== 'undefined' && tempCropData !== 'null' && tempCropData !== '') {
			onOk?.(tempCropData);
		} else {
			utils.showError('Please crop the image');
		}
	};

	useEffect(() => {
		if (image) {
			// Transform Web URL From Image File List
			const reader = new FileReader();
			reader.onload = () => {
				setImageElement(reader.result);
			};
			reader.readAsDataURL(image[0]);
		}
	}, [image]);

	return (
		<div>
			<Modal className='justify-center' open={visible} onCancel={onClose} onOk={getCropData} destroyOnClose>
				<div>
					<h3>Crop Image</h3>
				</div>
				<div>
					<Cropper
						aspectRatio={aspectRatio}
						ref={cropperRef}
						src={imageElement}
						style={{ height: 350, width: '100%' }}
						initialAspectRatio={1}
						guides={false}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default ImageCropperModal;
