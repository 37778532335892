import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { CreateLiveStreamArgs, FetchLiveSteamsData, UpdateLiveStatusArgs, UpdateVisibilityStatusArgs } from 'types/live-stream';

export type UseQueryType = {
	queryKey: ReadonlyArray<unknown>;
	queryFn: (arg0?: any, args1?: any) => Promise<any>;
};

export type FetchLiveSteamsArgs = {
	q?: string;
	sortBy: string;
	sortOrder: string;
};

export const LIVE_STREAM_QUERIES = {
	fetchLiveSteamById: (streamId: string, language?: string) => ({
		queryKey: [API_URLS.STREAM, streamId, language],
		queryFn: () => {
			return HttpService.get(`${API_URLS.STREAM}/${streamId}`, {}, { headers: { 'accept-language': language } }).then(
				({ data }) => data?.data
			);
		}
	}),
	fetchLiveSteams: (args: FetchLiveSteamsArgs) => ({
		queryKey: [API_URLS.STREAM, args],
		queryFn: () =>
			HttpService.get(API_URLS.STREAM, args).then(({ data: { data } }: { data: { data: FetchLiveSteamsData[] } }) => {
				data.forEach((item: any) => {
					if (data && data?.length > 0) {
						item.src = item?.image?.urls?.url;
					}
				});
				return data;
			})
	})
};

export const LIVE_STREAM_MUTATIONS = {
	removeLiveStream: () => ({
		mutationFn: (streamId: string) => HttpService.delete(`${API_URLS.STREAM}/${streamId}`)
	}),
	createLiveStream: () => ({
		mutationFn: (args: CreateLiveStreamArgs) => HttpService.post(API_URLS.STREAM, args)
	}),
	updateLiveStream: (streamId: string) => ({
		mutationFn: (args: CreateLiveStreamArgs) => HttpService.put(`${API_URLS.STREAM}/${streamId}`, args)
	}),
	patchLiveStream: (streamId: string, language?: string) => ({
		mutationFn: (args: CreateLiveStreamArgs) => {

			return HttpService.patch(`${API_URLS.STREAM}/${streamId}/language`, args, {}, { headers: { 'accept-language': language } });
		}
	}),
	updateLiveStatus: (streamUID: string | undefined) => ({
		mutationFn: (args: UpdateLiveStatusArgs) => HttpService.put(`${API_URLS.STREAM_LIVE_STATUS}/${streamUID}`, args)
	}),
	updateVisibilityStatus: (streamId: string | undefined) => ({
		mutationFn: (args: UpdateVisibilityStatusArgs) => HttpService.put(`${API_URLS.STREAM}/${streamId}/isActive`, args)
	})
};
