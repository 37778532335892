import { ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { EditingUserInfo, EditorInfoProps } from 'components/EditingUserInfo';

type TProps = {
	children: any;
	path?: any;
	editorInfo?: EditorInfoProps;
	child?: ReactNode;
};

const GoBack = ({ children, path, editorInfo, child }: TProps) => {
	const navigate = useNavigate();
	return (
		<div className='flex justify-between mb-4'>
			<div className='flex items-center'>
				<Button className='mr-[10px]' onClick={() => navigate(path || -1)} icon={<ArrowLeftOutlined />} type='text' />
				<h3>{children}</h3>
			</div>
			{child}
			<EditingUserInfo {...editorInfo} />
		</div>
	);
};

export default GoBack;
