import { useState } from 'react';

import { Form, Input } from 'antd';

import ImageCropperModal from 'components/modals/ImageCropperModal';
import { REGEX } from 'config/constants';

import { TImageFileFieldProps } from '../types/field-props';

const ImageFileField: React.FC<TImageFileFieldProps> = ({ formItemProps, fieldProps, image }) => {
	const [imageFile, setImageFile] = useState<any>();
	const [isCropperModalVisible, setIsCropperModalVisible] = useState(false);

	const closeCropperModal = () => {
		setIsCropperModalVisible(false);
	};

	const onChange = (e: any) => {
		const file = e.target.files[0];
		if (!REGEX.IMAGE.test(file.name)) return;

		setImageFile(e.target.files);
		setIsCropperModalVisible(true);
	};

	return (
		<>
			<Form.Item {...formItemProps}>
				<Input
					type='file'
					onChange={onChange}
					size='large'
					accept='image/png, image/jpeg, image/jpg, image/tiff, image/webp, image/bmp'
					{...fieldProps}
				/>
			</Form.Item>

			<ImageCropperModal
				visible={isCropperModalVisible}
				image={imageFile}
				onClose={closeCropperModal}
				onOk={(data: any) => {
					closeCropperModal();
					image.onImageSelect(data);
				}}
			/>
		</>
	);
};

export default ImageFileField;
