/* eslint-disable @typescript-eslint/no-use-before-define */
import { Suspense, useEffect, useRef } from 'react';

import { Flex, Layout, Spin } from 'antd';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import HeaderNavbar from 'components/header-navbar';
import Sidebar from 'components/navigations/sidebar/Sidebar';
// eslint-disable-next-line import/no-cycle
import { LayoutRouts } from 'config/routing';

import './index.scss';

const { Content } = Layout;

const DefaultLayout = () => {
	return (
		<Layout className='overflow-hidden h-lvh'>
			<Sidebar />

			<Layout>
				<HeaderNavbar />
				<ContentWithScrollTop />
			</Layout>
		</Layout>
	);
};

export default DefaultLayout;

const ContentWithScrollTop = () => {
	const location = useLocation();

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		containerRef.current?.scrollTo({
			top: 0,
			left: 0
		});
	}, [location.pathname]);

	return (
		<Content className='p-4 overflow-y-auto' ref={containerRef}>
			<SwitchTransition>
				<CSSTransition key={location.pathname} classNames='fade-global-only' timeout={300} unmountOnExit>
					<Suspense
						fallback={
							<Flex className='w-full h-lvh' justify='center' align='center'>
								<Spin />
							</Flex>
						}
					>
						<Routes location={location}>
							{LayoutRouts.map((route, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<Route key={`default-layout-route-${index}`} path={route.path} element={route.component} />
							))}
						</Routes>
					</Suspense>
				</CSSTransition>
			</SwitchTransition>
		</Content>
	);
};
