/* eslint-disable react/no-unused-prop-types */
import { RefObject } from 'react';

import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import useFilterContainer from './use-filter-container';
// eslint-disable-next-line import/no-cycle
import GlobalFilterForm from '../form/filter-form';
import { TGlobalFilterConfig } from '../types';

type TProps = TGlobalFilterConfig & {
	filterButtonRef: RefObject<HTMLButtonElement>;
};

const FilterContainer = (props: TProps) => {
	const { t } = useTranslation();
	const { form, handleCloseFilter, handleResetFilter, handleFiltersResult } = useFilterContainer(props);

	return (
		<Flex vertical className='w-[21rem] pb-4'>
			<Flex justify='space-between' align='center' className='mb-3 px-4 py-2 border-solid border-0 border-b-[1px] border-slate-200'>
				<HeaderButton title={t('filter.close')} onClick={handleCloseFilter} />

				<Typography.Text className='text-md font-bold'>{t('filter.filter')}</Typography.Text>

				<HeaderButton title={t('filter.reset')} onClick={handleResetFilter} />
			</Flex>

			<GlobalFilterForm form={form} filterConfig={props} />

			<Button className='mt-6 mx-4' onClick={handleFiltersResult} type='primary'>
				{t('filter.showResults')}
			</Button>
		</Flex>
	);
};

export default FilterContainer;

type TButtonCompProps = {
	title: string;
	onClick: () => void;
};
const HeaderButton = ({ title, onClick }: TButtonCompProps) => {
	return (
		<Typography.Text className='font-semibold text-sm text-[var(--primary)] cursor-pointer hover:text-[var(--secondary)]' onClick={onClick}>
			{title}
		</Typography.Text>
	);
};
