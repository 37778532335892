import { Checkbox, Form } from 'antd';

import { TCheckboxProps } from '../types/field-props';

const CheckboxField: React.FC<TCheckboxProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Checkbox {...fieldProps} />
		</Form.Item>
	);
};

export default CheckboxField;
