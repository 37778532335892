import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { UseQueryType } from 'services/tanstack-query/type';

export const EDUCATION_SUBJECT_QUERIES = {
	fetchSubjects: ({ apiParams }: { apiParams: any }): UseQueryType => ({
		queryKey: [
			EDUCATION_SUBJECT_QUERIES.fetchSubjects.name,
			API_URLS.EDUCATION_SUBJECT,
			apiParams,
			'educationFetchSubjectsKey'
		], // DO NOT CHANGE THE educationFetchSubjectsKey, used in the resetQueries
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.EDUCATION_SUBJECT, apiParams);
			return data?.data;
		}
	}),
	fetchGradeById: ({ id, language }: { id: string; language?: string }): UseQueryType => ({
		queryKey: [EDUCATION_SUBJECT_QUERIES.fetchGradeById.name, API_URLS.EDUCATION_SUBJECT, id, language],
		queryFn: async () => {
			const apiUrl = `${API_URLS.EDUCATION_SUBJECT}/${id}`;
			const { data } = await HttpService.get(apiUrl, {}, { headers: { 'accept-language': language } });
			return data?.data;
		}
	})
};

export const EDUCATION_SUBJECT_MUTATIONS = {
	addSubject: async ({ apiParams }: { apiParams: any }) => {
		const { data } = await HttpService.post(API_URLS.EDUCATION_SUBJECT, apiParams);
		return data;
	},

	updateSubject: async ({ id, apiParams }: { id: string; apiParams: any }) => {
		const apiUrl = `${API_URLS.EDUCATION_SUBJECT}/${id}`;
		const { data } = await HttpService.patch(apiUrl, apiParams);
		return data;
	},
	updateSubjectLang: async ({ id, apiParams, language }: { id: string; apiParams: any; language?: string }) => {
		const apiUrl = `${API_URLS.EDUCATION_SUBJECT}/${id}/language`;
		const { data } = await HttpService.patch(apiUrl, apiParams, {}, { headers: { 'accept-language': language } });
		return data;
	},
	deleteSubject: async ({ id }: { id: string }) => {
		const apiUrl = `${API_URLS.EDUCATION_SUBJECT}/${id}`;
		const { data } = await HttpService.delete(apiUrl);
		return data;
	}
};
