/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';

import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import { DEFAULT_FILTER_SORT_BY, DEFAULT_FILTER_SORT_ORDER } from 'components/filter/global-filter';
import { useUtils } from 'contexts/UtilsProvider';
import { useCustomNavigation } from 'hooks/useCustomNavigation';
import { EStreamCardMenuOptions } from 'pages/media/stream/components/StreamMenuOptions';
import { ApiHandlersService } from 'services/api-handlers.service';
import { deleteRecord } from 'services/helpers.service';
import { FetchLiveSteamsArgs, LIVE_STREAM_MUTATIONS, LIVE_STREAM_QUERIES } from 'services/tanstack-query/live-stream';
import { FetchLiveSteamsData } from 'types/live-stream';

const initialFilter: FetchLiveSteamsArgs = {
	q: '',
	sortBy: DEFAULT_FILTER_SORT_BY,
	sortOrder: DEFAULT_FILTER_SORT_ORDER
};

type TActionDetails = {
	action: 'view' | 'create' | 'edit' | 'updateLiveStatus' | 'updateVisibility' | 'translation';
	liveStreamData?: FetchLiveSteamsData;
};

export const useStreamListing = () => {
	const [actionDetails, setActionDetails] = useState<TActionDetails>();
	const [filter, setFilter] = useState<FetchLiveSteamsArgs>(initialFilter);

	const { showError, showSuccess } = useUtils();

	const searchLiveStreamId = useLocation()?.state?.searchLiveStreamId; // searchLiveStreamId is used to open a live stream directly from the search
	const customNavigation = useCustomNavigation();

	const fetchLiveSteams: UseQueryResult<FetchLiveSteamsData[]> = useQuery({
		...LIVE_STREAM_QUERIES.fetchLiveSteams(filter),
		refetchInterval: 10000
	});

	const removeLiveStream = useMutation({
		...LIVE_STREAM_MUTATIONS.removeLiveStream(),
		onError: (error) => showError(error),
		onSuccess: () => {
			showSuccess('Live stream deleted successfully');
			fetchLiveSteams.refetch();
		}
	});

	function handleMenuOptionSelected(option: EStreamCardMenuOptions, liveStreamData: FetchLiveSteamsData) {
		const actionHash: { [key in EStreamCardMenuOptions]: () => void } = {
			[EStreamCardMenuOptions.DELETE]: () => handleDeleteModalAction(liveStreamData?.id),
			[EStreamCardMenuOptions.EDIT]: () => setActionDetails({ action: 'edit', liveStreamData }),
			[EStreamCardMenuOptions.VIEW]: () => setActionDetails({ action: 'view', liveStreamData }),
			[EStreamCardMenuOptions.STATUS]: () => setActionDetails({ action: 'updateLiveStatus', liveStreamData }),
			[EStreamCardMenuOptions.VISIBILITY]: () => setActionDetails({ action: 'updateVisibility', liveStreamData }),
			[EStreamCardMenuOptions.TRANSLATION]: () => setActionDetails({ action: 'translation', liveStreamData })
		};

		actionHash[option]();
	}

	const handleDeleteModalAction = (streamId: string) => {
		deleteRecord({
			modalProps: {
				title: 'Are you sure you want to delete this stream?',
				onOk: async () => {
					await removeLiveStream.mutateAsync(streamId).catch((error) => {
						const message = ApiHandlersService.generateErrorMessage(error);
						showError(message);
					});
				}
			}
		});
	};

	const onLiveStreamClicked = (streamUID: string) => {
		setActionDetails({ action: 'view', liveStreamData: { id: streamUID } as FetchLiveSteamsData });
	};

	const handleLiveStatusUpdateDrawerClose = (refresh: boolean) => {
		setActionDetails(undefined);
		if (refresh) fetchLiveSteams.refetch();
	};

	const handleUpdateVisibilityDrawerClose = (refresh: boolean) => {
		setActionDetails(undefined);
		if (refresh) fetchLiveSteams.refetch();
	};

	const handleCreateUpdateDrawerClose = (refresh?: boolean, streamId?: string) => {
		setActionDetails(undefined);

		if (refresh) fetchLiveSteams.refetch();

		if (streamId) {
			const ti = setTimeout(() => {
				setActionDetails({ action: 'edit', liveStreamData: { id: streamId } as FetchLiveSteamsData });
			}, 300);

			return () => {
				clearTimeout(ti);
			};
		}

		customNavigation.clearStateFromNavigation(!!searchLiveStreamId); // Clear searchLiveStreamId from the navigation state
	};

	const onAddTranslation = (id: string) => {
		setActionDetails({ action: 'translation', liveStreamData: { id } as FetchLiveSteamsData });
	};

	// For global search
	useEffect(() => {
		if (searchLiveStreamId) {
			setActionDetails({ action: 'edit', liveStreamData: { id: searchLiveStreamId } as FetchLiveSteamsData });
		}
	}, [searchLiveStreamId]);

	return {
		onAddTranslation,
		isLoading: fetchLiveSteams.isLoading,
		liveSteamsData: fetchLiveSteams.data,
		actionDetails,
		setActionDetails,
		setFilter,
		handleMenuOptionSelected,
		onLiveStreamClicked,
		handleCreateUpdateDrawerClose,
		handleLiveStatusUpdateDrawerClose,
		handleUpdateVisibilityDrawerClose
	};
};
