import { useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Flex, Table } from 'antd';
import dayjs from 'dayjs';

import { GlobalFilter, GLOBAL_FILTERS_COMMON_SORT_BY, TGlobalFilterResult } from 'components/filter/global-filter';
import GoBack from 'components/GoBack';
import { DEFAULT_ITEMS_LIMIT_PER_PAGE } from 'config/constants';
import { useUtils } from 'contexts/UtilsProvider';
import { API_URLS } from 'services/apiUrls';
import { exportToCsv } from 'services/helpers.service';
import { HttpService } from 'services/http.service';
import { ESortOrder } from 'types/enums';

const SORT_BY_LIST = [...GLOBAL_FILTERS_COMMON_SORT_BY];

enum PaymentStatus {
	'all',
	'success',
	'failure',
	'pending'
}

const INITIAL_API_PARAMS = {
	page: 1,
	q: '',
	limit: DEFAULT_ITEMS_LIMIT_PER_PAGE,
	status: undefined,
	sortBy: 'updatedAt',
	sort: ESortOrder.DESC,
	// paymentStatus: PaymentStatus.all,
	userId: '',
	userSubscriptionId: ''
};
const TransactionHistory: React.FC = () => {
	const [apiParams, setApiParams] = useState(INITIAL_API_PARAMS);
	const { showError, showSuccess } = useUtils();

	const queryTransactionHistory = useQuery({
		queryKey: [API_URLS.TRANSACTION_HISTORY, apiParams],
		queryFn: () => HttpService.get(`${API_URLS.TRANSACTION_HISTORY}`, apiParams).then(({ data }) => data?.data)
	});

	const columns: any = [
		{
			title: 'Transaction ID',
			dataIndex: 'transactionId',
			key: 'transactionId'
		},
		{
			title: 'Amount',
			dataIndex: 'paidAmount',
			key: 'paidAmount'
		},
		{
			title: 'Payment Method',
			render: (record: any) => record?.paymentResponseJson?.payment_method_type.toUpperCase() || 'N/A'
		},
		{
			title: 'Status',
			dataIndex: 'paymentStatus',
			key: 'paymentStatus'
		},
		{
			title: 'Trans. Date',
			dataIndex: 'transactionDate',
			key: 'transactionDate',
			render: (date: string) => dayjs(date).format('DD MMM YYYY, hh:mm A')
		}
	];

	const downloadTransactionData = useMutation({
		mutationFn: () =>
			HttpService.get(`${API_URLS.TRANSACTION_HISTORY}`, { ...apiParams, page: 1, limit: 99999999 }).then(
				({ data }) => data?.data
			),
		onSuccess: (data) => {
			exportToCsv(
				data?.items?.map((e: any) => ({
					transactionId: e?.transactionId,
					paidAmount: e?.paidAmount,
					paymentMethod: e?.paymentResponseJson?.payment_method_type.toUpperCase() || 'N/A',
					paymentStatus: e?.paymentStatus,
					transactionDate: e?.transactionDate
				})),
				`Payment Transaction History (ALL) ${dayjs(new Date()).format('DD-MM-YYYY')}`,
				['Transaction ID', 'Amount', 'Payment Method', 'Status', 'Trans. Date']
			);
			showSuccess('Successfully Export Payment Transaction History (ALL)');
			return [];
		}
	});

	const handleExport = async () => {
		await downloadTransactionData.mutateAsync().catch(showError);
	};

	const handleFilterSubmit = (filters: TGlobalFilterResult) => {
		setApiParams((previousValue: any) => ({
			...previousValue,
			userId: filters?.paidUserBy?.key,
			page: 1,
			limit: filters.recordsLimit,
			sortBy: filters.sortBy,
			sort: filters.sortOrder
		}));
	};
	return (
		<div>
			<GoBack path={-1}>Transaction History</GoBack>

			<Flex gap={16} justify='end' className='my-4'>
				<GlobalFilter
					fieldNames={['paidUserBy', 'recordsLimit', 'sortOrder', 'sortBy']}
					formFields={{
						predefined: {
							sortBy: {
								fieldProps: {
									options: SORT_BY_LIST
								}
							}
						}
					}}
					onSubmit={handleFilterSubmit}
				/>
				<Button
					type='primary'
					className='h-auto'
					onClick={handleExport}
					disabled={queryTransactionHistory?.data?.items?.length < 1}
				>
					Export to CSV
				</Button>
			</Flex>
			<Table
				loading={queryTransactionHistory.isLoading}
				dataSource={queryTransactionHistory?.data?.items || []}
				columns={columns}
				pagination={{
					current: apiParams.page,
					pageSize: apiParams.limit,
					total: queryTransactionHistory?.data?.totalItems,
					showSizeChanger: false,
					onChange: (page) => setApiParams((pv) => ({ ...pv, page }))
				}}
			/>
		</div>
	);
};
export default TransactionHistory;
