export const ONGOING_DATA_COLOR = '#46a2b0';
export const COMPLETED_DATA_COLOR = '#15537d';
export const UPCOMING_DATA_COLOR = '#042254';

export const GRAY_COLORS_PALETTE = {
	gray100: '#D3D3D3',
	gray200: '#DCDCDC',
	gray300: '#C0C0C0',
	gray400: '#A9A9A9',
	gray500: '#808080',
	gray600: '#696969'
};

export const COLORS_PALETTE: any = {
	color100: '#042254',
	color400: '#10c4be',
	color600: '#a1d916',
	color800: '#ffac12',
	color900: '#ff8c12'
};

export const COUNT_COLORS_PALETTE = [
	'#ff6c12',
	'#ff8c12',
	'#ffac12',
	'#a1d916',
	'#65a30d',
	'#13ba82',
	'#10c4be',
	'#46a2b0',
	'#0e7490',
	'#042254'
];
