/* eslint-disable jsx-a11y/media-has-caption */
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	AccessibilityHelp,
	Alignment,
	Autoformat,
	BalloonToolbar,
	BlockQuote,
	Bold,
	ClassicEditor,
	Editor,
	EditorConfig,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	Image,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	SourceEditing,
	SpecialCharacters,
	Strikethrough,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo,
	WordCount
} from 'ckeditor5';
import Hls from 'hls.js';

import { MediaDrawer } from 'components/input-fields/components/media';
import { EMediaTypes } from 'components/input-fields/types/enums';
import CustomImagePlugin from 'components/Inputs/RichTextEditor/customPlugin/imagePlugin/CustomImagePlugin';
import CustomVideoPlugin from 'components/Inputs/RichTextEditor/customPlugin/videoPlugin/CustomVideoPlugin';

import 'ckeditor5/ckeditor5.css';
import { TFetchImagesDataItem } from '../../../types/image';
import { FetchVideosDataItem } from '../../../types/video';
import './index.scss';

type Props = {
	value?: string;
	config?: {
		placeholder?: any;
		toolbar?: any;
		language?: any;
		image?: any;
		table?: any;
	};
	lengthLimit?: number;
	onChange?: (data: string) => void;
	disabled?: boolean;
	useMedia?: boolean;
};

const RichTextEditor = ({ config, disabled, onChange, value, useMedia = true, lengthLimit }: Props) => {
	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);

	const wordCountContainerId = useId();

	useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);

	const [mediaType, setMediaType] = useState<EMediaTypes>(EMediaTypes.VIDEO);
	const [openVideoDrawer, setOpenVideoDrawer] = useState(false);
	const [editorInstance, setEditorInstance] = useState<Editor>();
	const [selectedVideo, setSelectedVideo] = useState<FetchVideosDataItem>();
	const [selectedImage, setSelectedImage] = useState<TFetchImagesDataItem>();

	const VideoHlsHelper = () => {
		const videos = document.querySelectorAll('.i-need-to-be-fixed');
		if (videos.length > 0) {
			videos.forEach((video) => {
				if (Hls.isSupported()) {
					const hls = new Hls();
					hls.loadSource(video.getAttribute('src') || '');
					hls.attachMedia(video as HTMLVideoElement);
					video.className = 'i-am-processed-video';
					// hls.on(Hls.Events.MANIFEST_PARSED, () => {
					// 	(video as HTMLVideoElement).play().catch((err) => console.error(err));
					// });
				} else {
					console.error('Hls.js is not supported in this browser.');
				}
			});
		}
	};

	const onDone = () => {
		if (editorInstance) {
			setOpenVideoDrawer(false);
			if (selectedVideo) {
				editorInstance.model.change((writer: any) => {
					const videoElement = writer.createElement('video', {
						src: selectedVideo?.urls?.video?.hls
					});
					editorInstance.model.insertContent(videoElement, editorInstance.model.document.selection);
					setSelectedVideo(undefined);
					VideoHlsHelper();
				});
			}
			if (selectedImage) {
				editorInstance.model.change((writer: any) => {
					const videoElement = writer.createElement('image', {
						src: selectedImage?.urls?.url
					});
					editorInstance.model.insertContent(videoElement, editorInstance.model.document.selection);
					setSelectedImage(undefined);
				});
			}
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			VideoHlsHelper();
		}, 3000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const wdPlugins = useMemo(() => {
		if (!lengthLimit) return [];
		return [WordCount];
	}, []);

	const mediaPlugins = useMemo(() => {
		if (!useMedia) return [];
		return [
			ImageResize, // Make sure ImageResize is here
			ImageToolbar,
			Image,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageStyle,
			ImageTextAlternative,
			CustomVideoPlugin,
			CustomImagePlugin
		];
	}, []);

	const overFlowValidator = useCallback((characters: number) => {
		const wordCountElement = document.getElementById(wordCountContainerId);
		if (wordCountElement) {
			wordCountElement.innerHTML = `Characters: ${characters} / ${lengthLimit}`;
			const isOverFlow = characters > (lengthLimit || 0);
			wordCountElement.style.color = isOverFlow ? 'red' : 'black';
			if (!isOverFlow) return;
			const data = editorInstance?.getData()?.slice(0, lengthLimit);
			if (data) editorInstance?.setData(data);
		}
	}, []);

	// Listen for real-time changes in the editor
	editorInstance?.model.document.on('change:data', () => {
		if (lengthLimit) overFlowValidator(editorInstance.plugins.get('WordCount').characters);
	});

	return (
		<div>
			<div className='main-container'>
				<div
					className='editor-container editor-container_classic-editor editor-container_include-style editor-container_include-block-toolbar '
					ref={editorContainerRef}
				>
					<div className='editor-container__editor'>
						<div ref={editorRef}>
							{isLayoutReady && (
								<CKEditor
									editor={ClassicEditor as any}
									config={
										{
											extraPlugins: [...wdPlugins, ...mediaPlugins],
											wordCount: { container: lengthLimit ? document.getElementById(wordCountContainerId) : undefined },
											...editorConfig,
											onVideoDrawerOpen: () => {
												setMediaType(EMediaTypes.VIDEO);
												setOpenVideoDrawer(true);
											},
											onImageDrawerOpen: () => {
												setMediaType(EMediaTypes.IMAGE);
												setOpenVideoDrawer(true);
											},
											...config
										} as EditorConfig
									}
									onChange={(event, editor) => {
										const data = editor.getData();
										if (data) {
											onChange?.(data);
										}
									}}
									disabled={disabled}
									data={value || ''}
									onReady={(editor) => {
										setEditorInstance(editor);
									}}
								/>
							)}
						</div>
					</div>
					<div id={wordCountContainerId} className='mt-[8px]' />
				</div>
			</div>
			{useMedia && (
				<MediaDrawer
					show={openVideoDrawer}
					drawer={{
						title: mediaType === EMediaTypes.VIDEO ? 'Select Video' : 'Select Image',
						onClose: () => {
							setOpenVideoDrawer(false);
						}
					}}
					mediaGallery={{ mediaType, selectMultiple: false, forEditor: true }}
					onClose={() => setOpenVideoDrawer(false)}
					onSelect={(selectedMedia) => {
						if (selectedMedia[0]) {
							if (mediaType === EMediaTypes.VIDEO) setSelectedVideo(selectedMedia[0]);
							if (mediaType === EMediaTypes.IMAGE) setSelectedImage(selectedMedia[0]);
						}
					}}
					onDone={onDone}
				/>
			)}
		</div>
	);
};

export default RichTextEditor;

const editorConfig: EditorConfig = {
	toolbar: {
		items: [
			'undo',
			'redo',
			'|',
			'sourceEditing',
			'findAndReplace',
			'selectAll',
			'|',
			'heading',
			'style',
			'|',
			'fontSize',
			'fontFamily',
			'fontColor',
			'fontBackgroundColor',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'specialCharacters',
			'link',
			'mediaEmbed',
			'insertTable',
			'highlight',
			'blockQuote',
			'htmlEmbed',
			'|',
			'alignment',
			'|',
			'bulletedList',
			'numberedList',
			'todoList',
			'outdent',
			'indent',
			'|',
			'accessibilityHelp',
			'|',
			'CustomVideoPlugin',
			'CustomImagePlugin'
		],
		shouldNotGroupWhenFull: true
	},
	image: {
		resizeOptions: [
			{
				name: 'resizeImage:original',
				value: null,
				label: 'Original'
			},
			{
				name: 'resizeImage:custom',
				label: 'Custom',
				value: 'custom'
			},
			{
				name: 'resizeImage:40',
				value: '40',
				label: '40%'
			},
			{
				name: 'resizeImage:60',
				value: '60',
				label: '60%'
			}
		],
		toolbar: [
			'resizeImage',
			'|',
			'toggleImageCaption',
			'imageTextAlternative',
			'|',
			'imageStyle:inline',
			'imageStyle:wrapText',
			'imageStyle:breakText'
		]
	},

	plugins: [
		AccessibilityHelp,
		Alignment,
		Autoformat,
		BalloonToolbar,
		BlockQuote,
		Bold,
		Essentials,
		FindAndReplace,
		FontBackgroundColor,
		FontColor,
		FontFamily,
		FontSize,
		FullPage,
		GeneralHtmlSupport,
		Heading,
		Highlight,
		HtmlComment,
		HtmlEmbed,
		Indent,
		IndentBlock,
		Italic,
		Link,
		List,
		ListProperties,
		Markdown,
		MediaEmbed,
		Paragraph,
		PasteFromOffice,
		SelectAll,
		SourceEditing,
		SpecialCharacters,
		Strikethrough,
		Style,
		Table,
		TableCaption,
		TableCellProperties,
		TableColumnResize,
		TableProperties,
		TableToolbar,
		TextTransformation,
		TodoList,
		Underline,
		Undo,
		// Widget,
		ImageToolbar,
		MediaEmbed
	],
	fontFamily: {
		supportAllValues: true
	},
	fontSize: {
		options: [10, 12, 14, 'default', 18, 20, 22],
		supportAllValues: true
	},
	heading: {
		options: [
			{
				model: 'paragraph',
				title: 'Paragraph',
				class: 'ck-heading_paragraph'
			},
			{
				model: 'heading1',
				view: 'h1',
				title: 'Heading 1',
				class: 'ck-heading_heading1'
			},
			{
				model: 'heading2',
				view: 'h2',
				title: 'Heading 2',
				class: 'ck-heading_heading2'
			},
			{
				model: 'heading3',
				view: 'h3',
				title: 'Heading 3',
				class: 'ck-heading_heading3'
			},
			{
				model: 'heading4',
				view: 'h4',
				title: 'Heading 4',
				class: 'ck-heading_heading4'
			},
			{
				model: 'heading5',
				view: 'h5',
				title: 'Heading 5',
				class: 'ck-heading_heading5'
			},
			{
				model: 'heading6',
				view: 'h6',
				title: 'Heading 6',
				class: 'ck-heading_heading6'
			}
		]
	},
	htmlSupport: {
		allow: [
			{
				name: /^.*$/,
				styles: true,
				attributes: true,
				classes: true
			}
		]
	},

	link: {
		addTargetToExternalLinks: true,
		defaultProtocol: 'https://',
		decorators: {
			toggleDownloadable: {
				mode: 'manual',
				label: 'Downloadable',
				attributes: {
					download: 'file'
				}
			}
		}
	},
	list: {
		properties: {
			styles: true,
			startIndex: true,
			reversed: true
		}
	},
	menuBar: {
		isVisible: false
	},
	placeholder: 'Type or paste your content here!',
	style: {
		definitions: [
			{
				name: 'Article category',
				element: 'h3',
				classes: ['category']
			},
			{
				name: 'Title',
				element: 'h2',
				classes: ['document-title']
			},
			{
				name: 'Subtitle',
				element: 'h3',
				classes: ['document-subtitle']
			},
			{
				name: 'Info box',
				element: 'p',
				classes: ['info-box']
			},
			{
				name: 'Side quote',
				element: 'blockquote',
				classes: ['side-quote']
			},
			{
				name: 'Marker',
				element: 'span',
				classes: ['marker']
			},
			{
				name: 'Spoiler',
				element: 'span',
				classes: ['spoiler']
			},
			{
				name: 'Code (dark)',
				element: 'pre',
				classes: ['fancy-code', 'fancy-code-dark']
			},
			{
				name: 'Code (bright)',
				element: 'pre',
				classes: ['fancy-code', 'fancy-code-bright']
			}
		]
	},
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
	}
};
