import { TCommonTranslation } from 'localization/types/common';

const common: TCommonTranslation = {
	weekly: 'Weekly',
	monthly: 'Monthly',
	yearly: 'Yearly',
	superAdmin: 'Super Admin',
	admin: 'Admin',
	publisher: 'Publisher',
	reviewer: 'Reviewer',
	contentCreator: 'Content Creator',
	viewer: 'Viewer',
	client: 'Client',
	add: 'Add',
	reset: 'Reset',
	update: 'Update'
};

export default common;
