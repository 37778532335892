import { Form, TimePicker } from 'antd';

import { TTimeRangePickerProps } from 'components/input-fields/types/field-props';

const TimeRangePickerField: React.FC<TTimeRangePickerProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<TimePicker.RangePicker size='large' {...fieldProps} style={{ ...(fieldProps?.style || {}), width: '100%' }} />
		</Form.Item>
	);
};

export default TimeRangePickerField;
