import React, { createContext, useState } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { TABLE_ROWS_LIMIT } from 'config/constants';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { IPaginatedGlobalSearchResponse, TGlobalSearchApiParams } from 'types/global-search';

export type SearchContextType = {
	openSearchData: boolean;
	querySearchData: UseQueryResult<IPaginatedGlobalSearchResponse>;
	searchApiParams: TGlobalSearchApiParams;
	setOpenSearchData: React.Dispatch<React.SetStateAction<boolean>>;
	setSearchApiParams: React.Dispatch<React.SetStateAction<TGlobalSearchApiParams>>;
};

export const SearchContext = createContext<SearchContextType | undefined>(undefined);

const defaultApiParams: TGlobalSearchApiParams = {
	q: '',
	indexes: [],
	isActive: undefined, // undefined to get all active and inactive records
	limit: TABLE_ROWS_LIMIT,
	page: 1
};

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [openSearchData, setOpenSearchData] = useState<boolean>(false);
	const [searchApiParams, setSearchApiParams] = useState<TGlobalSearchApiParams>(defaultApiParams);

	const querySearchData: UseQueryResult<IPaginatedGlobalSearchResponse> = useQuery({
		queryKey: [searchApiParams.q, searchApiParams.indexes, searchApiParams],
		queryFn: () => HttpService.get(API_URLS.SEARCH, searchApiParams).then(({ data: { data } }) => data),
		meta: { hideGlobalError: true },
		enabled: searchApiParams.q.length > 0
	});

	const value = React.useMemo(
		() => ({ openSearchData, setOpenSearchData, querySearchData, searchApiParams, setSearchApiParams }),
		[openSearchData, querySearchData, searchApiParams, setOpenSearchData, setSearchApiParams]
	);

	return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
