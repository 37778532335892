import { useState } from 'react';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Drawer, Flex, Form, Image, Typography } from 'antd';

// eslint-disable-next-line import/no-cycle
import MediaGallery from 'components/gallery/MediaGallery';
import { EImageType } from 'types/image';

import { TSelectMediaFieldProps } from '../types/types';

const MediaField: React.FC<TSelectMediaFieldProps> = ({
	formItemProps,
	fieldProps: { formInstance, button, drawer, mediaGallery, selectedMedia }
}) => {
	const [showDrawer, setShowDrawer] = useState(false);
	const [mediaDetails, setMediaDetails] = useState<any[]>([]);

	let values = Form.useWatch(formItemProps?.name, formInstance) as any[];
	if (values) {
		values = mediaGallery.selectMultiple ? values : [values];
	}

	const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setShowDrawer(true);

		button.props?.onClick?.(e);
	};

	const handleOnClose = () => {
		setShowDrawer(false);

		drawer.onClose?.();
	};

	const handleOnSelect = (movie: any[]) => {
		setMediaDetails(movie);

		mediaGallery.onSelect?.(movie);
	};

	const setFormValue = (selectedMedias: any) => {
		const newSelectedMedias =
			selectedMedias.length > 0 ? (mediaGallery.selectMultiple ? selectedMedias : selectedMedias?.[0]) : undefined;

		formInstance.setFieldValue(formItemProps?.name, newSelectedMedias || undefined);
		formInstance.validateFields([formItemProps?.name]);
	};

	const handleOnDone = () => {
		setFormValue(mediaDetails);

		setShowDrawer(false);

		drawer.onDone?.(mediaDetails);
	};

	const handleOnDelete = (mediaId: string) => {
		let newMediaDetails = mediaDetails;
		if (newMediaDetails.length === 0 && values.length !== 0) {
			newMediaDetails = values;
		}

		const updatedMediaDetails = newMediaDetails.filter((media) => {
			return media.id !== mediaId;
		});

		setMediaDetails(updatedMediaDetails);

		setFormValue(updatedMediaDetails);

		selectedMedia?.delete?.onDelete?.(mediaId);
	};

	return (
		<>
			<Form.Item {...formItemProps}>
				<Flex gap={8} vertical>
					<div>
						<Button size='large' icon={<UploadOutlined />} {...button.props} onClick={handleOnClick}>
							{button.text || `Select ${mediaGallery.mediaType}`}
						</Button>
					</div>

					{[undefined, true].includes(selectedMedia?.visible) && (
						<SelectedMedias
							medias={values}
							mediaType={mediaGallery.mediaType}
							delete={{
								...selectedMedia?.delete,
								visible: selectedMedia?.delete?.visible,
								onDelete: handleOnDelete
							}}
						/>
					)}
				</Flex>
			</Form.Item>

			<MediaDrawer
				imageType={mediaGallery?.imageType}
				show={showDrawer}
				drawer={drawer}
				mediaGallery={mediaGallery}
				onClose={handleOnClose}
				onSelect={handleOnSelect}
				onDone={handleOnDone}
			/>
		</>
	);
};

export default MediaField;

type TMediaDrawerProps = {
	show: boolean;
	drawer: TSelectMediaFieldProps['fieldProps']['drawer'];
	mediaGallery: TSelectMediaFieldProps['fieldProps']['mediaGallery'];
	imageType?: `${EImageType}`;
	onClose: () => void;
	onSelect: TSelectMediaFieldProps['fieldProps']['mediaGallery']['onSelect'];
	onDone: () => void;
};

export const MediaDrawer = ({
	show,
	drawer,
	mediaGallery,
	imageType,
	onClose,
	onSelect,
	onDone
}: TMediaDrawerProps) => {
	if (!show) return null;

	return (
		<Drawer
			open={show}
			size='large'
			title={drawer.title}
			onClose={onClose}
			destroyOnClose
			extra={
				<Button size='large' type='primary' onClick={onDone}>
					Done
				</Button>
			}
			styles={{
				wrapper: { width: 900 }
			}}
		>
			<MediaGallery
				forEditor={mediaGallery?.forEditor}
				imageType={imageType}
				mediaType={mediaGallery.mediaType as any}
				selectMultiple={mediaGallery.selectMultiple}
				onSelect={onSelect}
			/>
		</Drawer>
	);
};

type TSelectedMediasProps = {
	medias: any[];
	mediaType: TSelectMediaFieldProps['fieldProps']['mediaGallery']['mediaType'];
	delete?: Omit<ButtonProps, 'onClick'> & {
		visible?: boolean;
		onDelete: (mediaId: string) => void;
	};
};

const SelectedMedias = ({ medias, mediaType, delete: deleteProp }: TSelectedMediasProps) => {
	const { visible: deleteButtonVisible, onDelete, ...restDeleteProp } = deleteProp || {};

	if (!medias || medias.length === 0) return null;

	return (
		<Flex wrap='nowrap' gap={16} style={{ overflowX: 'auto' }}>
			{medias.map((media) => (
				<Flex
					key={media.id}
					gap={4}
					justify='center'
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 4,
						borderRadius: 8
						// boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
					}}
				>
					{mediaType === 'audio' && (
						<Typography.Paragraph style={{ margin: 0, width: 70 }} ellipsis={{ rows: 1, tooltip: media?.title }}>
							{media?.title}
						</Typography.Paragraph>
					)}

					{mediaType !== 'audio' && (
						<Image
							width={70}
							height={70}
							src={media?.urls?.image?.thumbnail || media?.urls?.url || media?.url || media?.image?.urls?.thumbnail}
							className='rounded-md'
						/>
					)}

					{[undefined, true].includes(deleteButtonVisible) && (
						<Button
							className='self-center'
							size='small'
							danger
							icon={<DeleteOutlined />}
							style={{ width: 70 }}
							{...restDeleteProp}
							onClick={() => onDelete?.(media.id)}
						/>
					)}
				</Flex>
			))}
		</Flex>
	);
};
