import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { ApiHandlersService } from 'services/api-handlers.service';

const queryClient = new QueryClient({
	defaultOptions: { queries: { retry: false } },
	queryCache: new QueryCache({
		onError: (error, query) => {
			console.error(error);
			if (query?.options?.meta?.hideGlobalError) return;

			message.error(ApiHandlersService.generateErrorMessage(error));
		}
	}),
	mutationCache: new MutationCache({
		onError: (error, v, c, m) => {
			console.error(error);
			if (m?.meta?.hideGlobalError) return;
			message.error(ApiHandlersService.generateErrorMessage(error), 2.5);
		}
	})
});

export default queryClient;
