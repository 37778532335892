import { useEffect, useRef, useState } from 'react';

import { useUtils } from 'contexts/UtilsProvider';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { ApiHandlersService } from 'services/api-handlers.service';

import { TSortOrder } from './FilterButton';
import { TSelectedMedia } from '../types';

export const IMAGES_PER_PAGE = 30;

export type TImage = {
	id: string;
	url: string;
	title: string;
};

type TProps = {
	selectMultiple?: boolean;
	imageType: string | undefined;
	onImageSelected?: (images: TImage[]) => void;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
};

const DEFAULT_SORT_BY = 'createdAt';
const DEFAULT_SORT_ORDER: TSortOrder = 'DESC';
const DEFAULT_FILTER_TAGS = '';

export const useImageGallery = ({ selectMultiple, imageType, onImageSelected, onSelect }: TProps) => {
	const utils = useUtils();

	const [isLoading, setIsLoading] = useState(true);
	const [dataList, setDataList] = useState([] as any);
	const [pagination, setPagination] = useState(1);

	const [showSingImageUpload, setShowSingleImageUpload] = useState(false);
	const [showBulkImageUpload, setShowBulkImageUpload] = useState(false);

	const [searchString, setSearchString] = useState('');

	const sortByRef = useRef<string>(DEFAULT_SORT_BY);
	const sortOrderRef = useRef<TSortOrder>(DEFAULT_SORT_ORDER);
	const totalImagesRef = useRef(0);

	const onSingleImageModalClose = () => {
		setShowSingleImageUpload(false);
		setShowBulkImageUpload(false);
	};

	const onBulkImageModalClose = () => {
		setShowSingleImageUpload(false);
		setShowBulkImageUpload(false);
	};

	const items = [
		{
			label: 'Single Image Upload',
			key: '1',
			onClick: () => setShowSingleImageUpload(true)
		},
		{
			label: 'Bulk Image Upload',
			key: '2',
			onClick: () => setShowBulkImageUpload(true)
		}
	];

	const menuProps = {
		items
	};

	const handlePageSelection = (page: number) => {
		setPagination(page);
	};

	const fetchImages = async (pagination: number, searchString: string, filterTags: string, sortByOrder: string, sortOrder: string) => {
		setIsLoading(true);

		try {
			const apiParams = {
				q: searchString,
				tags: filterTags,
				page: pagination,
				limit: IMAGES_PER_PAGE,
				sortBy: sortByOrder,
				sort: sortOrder,
				imageType
			};

			const { data } = await HttpService.get(API_URLS.MEDIA_IMAGE, apiParams);

			if (data && data.data && data.data.items) {
				data.data?.items.forEach((item: any) => {
					item.src = item?.urls?.url;
					item.tags = [];
					item.caption = item.title;
				});
			}

			setDataList(data?.data?.items);
			totalImagesRef.current = data?.data?.totalItems;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchImages(pagination, searchString, DEFAULT_FILTER_TAGS, DEFAULT_SORT_BY, DEFAULT_SORT_ORDER);
	}, [pagination, searchString]);

	const onSearch = (searchString: string) => {
		setSearchString(searchString);
	};

	// Called when single or bulk images are uploaded successfully
	const onImageUploaded = () => {
		fetchImages(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, DEFAULT_SORT_ORDER);
	};

	const onFilterOptionSelected = (sortBy: string) => {
		sortByRef.current = sortBy;
		if (sortBy === sortByRef.current) {
			sortOrderRef.current = sortOrderRef.current === 'ASC' ? 'DESC' : 'ASC';
		} else {
			sortOrderRef.current = 'DESC';
		}

		fetchImages(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, sortOrderRef.current);
	};

	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const selectedItemsId = selectedItems.map((item) => item.id);

	const handleItemSelect = (id: string) => {
		const item = dataList.find((item: any) => item.id === id);

		const newSelectedItems = selectedItemsId.includes(id)
			? selectedItems.filter((selectedItem) => selectedItem.id !== id)
			: selectMultiple
				? [...selectedItems, item]
				: [item];

		setSelectedItems?.(newSelectedItems);

		onImageSelected?.(newSelectedItems);
		onSelect?.(newSelectedItems);
	};

	return {
		isLoading,
		dataList,
		showSingImageUpload,
		showBulkImageUpload,
		pagination,
		menuProps,
		sortOrderRef,
		totalImagesRef,
		onImageUploaded,
		onSingleImageModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		onBulkImageModalClose,

		selectedItemsId,
		handleItemSelect
	};
};
