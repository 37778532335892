import { useRef } from 'react';

import { Avatar, ColProps, Flex, FormItemProps, Typography } from 'antd';

import { API_URLS } from 'services/apiUrls';

import ApiDrivenSelect from '../components/api-driven-select';
import { TApiDrivenSelectFieldProps } from '../types/field-props';

type TProps = {
	colProps?: ColProps;
	formItemProps?: FormItemProps & {
		additionalRules?: FormItemProps['rules'];
	};
	fieldProps?: TApiDrivenSelectFieldProps['fieldProps'];
};

type TReturn = TApiDrivenSelectFieldProps & {
	colProps?: ColProps;
};

export const getPartyFormField = (props?: TProps): TReturn => {
	const { additionalRules, ...restFormItemProps } = props?.formItemProps || {};

	return {
		fieldType: 'api-driven-select',
		colProps: { span: 24, ...props?.colProps },
		formItemProps: {
			name: 'party',
			label: 'Party',
			rules: [...(additionalRules || [])],
			...restFormItemProps
		},
		api: {
			url: API_URLS.PARTY_ALL,
			initialLoad: true,
			responseDataStructure: 'data',
			valueKey: 'id',
			labelKey: 'title'
		},
		fieldProps: {
			optionRender: (options) => <PartyDropdown options={options} />,
			placeholder: 'Select Party',
			...props?.fieldProps,
			allowClear: true
		}
	};
};

export const PartyFormField = (props?: TProps) => {
	return <ApiDrivenSelect {...getPartyFormField(props)} />;
};

export default PartyFormField;

export const getRandomColor = () => {
	const colors = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

	const randomIndex = Math.floor(Math.random() * colors.length);

	return colors[randomIndex];
};

export const PartyDropdown = ({ options }: any) => {
	const backgroundColorRef = useRef<any>(getRandomColor());

	return (
		<Flex gap={8} align='center'>
			{options.data.partyLogo?.urls?.url ? (
				<Avatar src={options.data.partyLogo?.urls?.url} />
			) : (
				<Avatar
					style={{
						backgroundColor: backgroundColorRef.current
					}}
				>
					{options.data?.title?.[0]}
				</Avatar>
			)}

			<Flex vertical>
				<Typography.Paragraph className='capitalize' style={{ margin: 0 }}>
					{options?.data?.title}
				</Typography.Paragraph>
			</Flex>
		</Flex>
	);
};
