import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { UseQueryType } from 'services/tanstack-query/type';

export const DASHBOARD_QUERIES = {
	fetchSubscribersByState: ({ stateId }: { stateId: string }): UseQueryType => ({
		queryKey: [DASHBOARD_QUERIES.fetchSubscribersByState.name, API_URLS.DASHBOARD_SUBSCRIBERS_GROUPED_BY_STATES, stateId],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.DASHBOARD_SUBSCRIBERS_GROUPED_BY_STATES, { stateId });
			return data?.data;
		}
	}),
	fetchSubscribersJoinedVsLeft: ({ period }: { period: string }): UseQueryType => ({
		queryKey: [DASHBOARD_QUERIES.fetchSubscribersJoinedVsLeft.name, API_URLS.DASHBOARD_SUBSCRIBERS_GROUPED_BY_STATES, period],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.DASHBOARD_SUBSCRIBERS_GROUPED_BY_STATES, { period });
			return data?.data;
		}
	}),
	fetchPublicContentSummary: (): UseQueryType => ({
		queryKey: [DASHBOARD_QUERIES.fetchPublicContentSummary.name, API_URLS.DASHBOARD_PUBLIC_CONTENT],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.DASHBOARD_PUBLIC_CONTENT);
			return data?.data;
		}
	}),
	fetchUserEngagementStats: ({ apiParams }: { apiParams: { period: string } }): UseQueryType => ({
		queryKey: [DASHBOARD_QUERIES.fetchUserEngagementStats.name, API_URLS.DASHBOARD_USER_ENGAGEMENT, apiParams],
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.DASHBOARD_USER_ENGAGEMENT, apiParams);
			return data;
		}
	})
};
