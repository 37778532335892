import { useQuery } from '@tanstack/react-query';
import { Col, Flex, Row, Spin } from 'antd';

import { DASHBOARD_QUERIES } from 'services/tanstack-query/dashboard-apis';

import ContentDistribution from './public-contents/content-distribution';
import { TopViewedCategories } from './public-contents/top-viewed-categories';
import { TotalContentCounts } from './public-contents/total-viewed-contents';
import UserEngagementStats from './user-engagement-stats/user-engagement-stats';

const UserEngagementPublicContent: React.FC = () => {
	const { isLoading, data: publicContent } = useQuery({ ...DASHBOARD_QUERIES.fetchPublicContentSummary(), refetchInterval: 10000 });

	return (
		<div className='min-h-[410px]'>
			<Row gutter={8}>
				<Col span={14}>
					<Spin spinning={isLoading}>
						<Flex vertical gap={8} className='h-[410px]'>
							<UserEngagementStats />
							<Flex vertical gap={8} className='h-full bg-white p-3 rounded-md shadow-md'>
								<TotalContentCounts data={publicContent?.totalPublicContentCount} />
								<TopViewedCategories data={publicContent?.topCategory || []} />
							</Flex>
						</Flex>
					</Spin>
				</Col>

				<Col span={10}>
					<Spin spinning={isLoading}>
						<div className='h-[410px] bg-white p-3 rounded-md shadow-md'>
							{!isLoading && <ContentDistribution data={publicContent?.totalPublicContentCount} />}
						</div>
					</Spin>
				</Col>
			</Row>
		</div>
	);
};

export default UserEngagementPublicContent;
