import { useMemo } from 'react';

import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Flex, MenuProps, Typography } from 'antd';
import { FaUser, FaUserLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/AuthProvider';
import { deleteRecord } from 'services/helpers.service';
import { StorageService } from 'services/storage.service';
import { EUserRoles } from 'services/user-role/permissions/types';
import { hashRoleIdToRoleTitle } from 'services/user-role/permissions/utils';

const NavbarUserDetails = () => {
	const navigate = useNavigate();
	const auth = useAuth();

	const userRole = StorageService.get('user-role');
	const { user } = auth;

	const settingItems: MenuProps['items'] = useMemo(
		() => [
			{
				key: 'changePassword',
				label: 'Change Password',
				icon: <FaUserLock />,
				onClick: () => navigate('/change-password')
			},
			{
				key: 'userProfile',
				label: 'Profile',
				icon: <FaUser />,
				onClick: () => navigate('/user-profile')
			},
			{
				key: 'logout',
				label: 'Logout',
				icon: <LogoutOutlined />,
				onClick: () => {
					deleteRecord({
						modalProps: {
							title: 'Are you sure you want to logout?',
							onOk: async () => {
								await auth.logout();
								navigate('/auth/login');
							}
						}
					});
				},
				danger: true
			}
		],
		[]
	);

	return (
		<Dropdown menu={{ items: settingItems }} arrow>
			<Flex gap={12} align='center'>
				<Avatar src={user?.profileImage} shape='circle' size='large' />
				<div className='flex flex-col '>
					<Typography.Title
						level={5}
						style={{
							color: 'white',
							marginBottom: 0,
							marginTop: 0
						}}
					>
						{user?.name}
					</Typography.Title>
					<Typography.Text
						type='secondary'
						style={{
							color: 'gray',
							marginTop: 0
						}}
					>
						{hashRoleIdToRoleTitle[userRole as EUserRoles]}
					</Typography.Text>
				</div>
			</Flex>
		</Dropdown>
	);
};

export default NavbarUserDetails;
