import { IPaginatedApiMetaResponse } from './types';

// Used to search content of a specific type (index)
export enum EGlobalSearchIndexes {
	ARTICLE = 'article',
	CATEGORY = 'category',
	DISTRICT = 'district',
	MOVIE = 'movie',
	SERIES = 'series',
	PODCAST = 'podcast',
	STREAM = 'stream',
	POLL = 'poll',
	RAFFLE = 'raffle',
	PARTY = 'party',
	LEADER = 'leader',
	DYNAMIC_PAGE = 'dynamic_page',
	EDUCATION_COURSE = 'course'
}

// Used in search response
export enum EGlobalSearchContentTypes {
	ARTICLE = 'ARTICLE',
	CATEGORY = 'CATEGORY',
	DISTRICT = 'DISTRICT',
	MOVIE = 'MOVIE',
	SERIES = 'SERIES',
	PODCAST = 'PODCAST',
	STREAM = 'STREAM',
	POLL = 'POLL',
	RAFFLE = 'RAFFLE',
	PARTY = 'PARTY',
	LEADER = 'LEADER',
	DYNAMIC_PAGE = 'DYNAMIC_PAGE',
	EDUCATION_COURSE = 'COURSE'
}

export type TGlobalSearchApiParams = {
	q: string;
	indexes: EGlobalSearchIndexes[] | undefined;
	isActive: boolean | undefined;
	limit: number;
	page: number;
};

// It has common fields for all content types, which are used to show in the search result
export interface IGlobalSearchResponseListItem {
	contentType: EGlobalSearchContentTypes;
	id: string;
	slug: string;
	title: string;
	description?: string;
	summary?: string;
	firstName?: string;
	lastName?: string;
}

export interface IPaginatedGlobalSearchResponse extends IPaginatedApiMetaResponse {
	items: IGlobalSearchResponseListItem[];
}
