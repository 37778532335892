import { Form, Switch } from 'antd';

import { TSwitchProps } from '../types/field-props';

const SwitchField: React.FC<TSwitchProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Switch size='default' {...fieldProps} />
		</Form.Item>
	);
};

export default SwitchField;
