export enum ETabsKey {
	ALL = 'all',
	ARTICLE = 'article',
	CATEGORY = 'category',
	DISTRICT = 'district',
	MOVIE = 'movie',
	SERIES = 'series',
	PODCAST = 'podcast',
	STREAM = 'stream',
	POLL = 'poll',
	RAFFLE = 'raffle',
	PARTY = 'party',
	LEADER = 'leader',
	DYNAMIC_PAGE = 'dynamic-page',
	EDUCATION_COURSE = 'course'
}
