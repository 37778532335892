import { lazy, Suspense } from 'react';

import { Button, Drawer, Flex, Pagination, Spin, Typography } from 'antd';

import CardGallery from 'components/card-gallery';
import SearchComp from 'components/ui/search';

import FilterButton from './FilterButton';
import { useVideoGallery, VIDEOS_PER_PAGE } from './useVideoGallery';
import { TVideo } from '../../uploadVideoButton/UploadVideoButton';
import { TSelectedMedia } from '../types';

import '../image/image-gallery.scss';

// eslint-disable-next-line import/no-cycle
const SingleVideoUploadDrawer = lazy(() => import('components/drawers/SingleVideoUploadDrawer'));

type TUploadModuleVideoProps = {
	selectMultiple?: boolean;
	onVideoSelected?: (video: TVideo[]) => void;
	mediaType?: string;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
	forEditor?: boolean;
};

const VideoGallery = ({ selectMultiple, onVideoSelected, mediaType, onSelect, forEditor = false }: TUploadModuleVideoProps) => {
	const {
		isLoading,
		dataList,
		showSingVideoUpload,
		pagination,
		sortOrderRef,
		onVideoUploaded,
		onSingleVideoModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		setShowSingleVideoUpload,
		totalVideosRef,
		selectedItemsId,
		handleItemSelect
	} = useVideoGallery({ selectMultiple, onVideoSelected, onSelect, forEditor });

	return (
		<Spin spinning={isLoading}>
			<Flex vertical gap={16}>
				{/* header */}
				<Flex justify='space-between'>
					<Flex vertical>
						<Typography.Title level={4} style={{ margin: 0 }}>
							Video Gallery
						</Typography.Title>
					</Flex>

					<Flex gap={16}>
						<FilterButton sortOrder={sortOrderRef.current} selectedItem={onFilterOptionSelected} />

						<Button onClick={() => setShowSingleVideoUpload(true)} type='primary' size='large'>
							Add Video
						</Button>
					</Flex>
				</Flex>

				<SearchComp
					searchProps={{
						placeholder: 'Search Video'
					}}
					onValueChange={onSearch}
				/>

				{/* card list */}
				<CardGallery cardType='video' data={dataList} selectable selectedItems={selectedItemsId} onSelect={handleItemSelect} />

				<Flex justify='center'>
					<Pagination
						defaultCurrent={pagination}
						total={totalVideosRef.current}
						pageSize={VIDEOS_PER_PAGE}
						onChange={handlePageSelection}
						showSizeChanger={false}
					/>
				</Flex>
				<Drawer title='Upload Single Video' open={showSingVideoUpload} onClose={onSingleVideoModalClose} destroyOnClose>
					<Suspense>
						<SingleVideoUploadDrawer onClose={onSingleVideoModalClose} onVideoUploaded={() => onVideoUploaded()} />
					</Suspense>
				</Drawer>
			</Flex>
		</Spin>
	);
};

export default VideoGallery;
