import { TFormFields } from './types/types';

type TGroupedFormFields = {
	formFields: TFormFields;
	groupedFields: { [groupKey: string]: string[] };
};

export const getGroupedFormFields = ({ formFields, groupedFields }: TGroupedFormFields) => {
	const groupedFormFields: { [groupKey: string]: TFormFields } = {};

	Object.keys(groupedFields).forEach((groupKey) => {
		groupedFormFields[groupKey] = {};

		groupedFields[groupKey].forEach((fieldName) => {
			if (fieldName in formFields) {
				groupedFormFields[groupKey][fieldName] = formFields[fieldName];
			}
		});
	});

	return groupedFormFields;
};
