import common from './common';
import dashboard from './dashboard';
import filter from './filter';
import modal from './modal';
import user from './user';

export const mlTranslations = {
	dashboard,
	user,
	filter,
	modal,
	common
};
