import GlobalSearch from './global-search';
import { SearchProvider } from './provider/search-provider';

const SearchIndexPage = () => {
	return (
		<SearchProvider>
			<GlobalSearch />
		</SearchProvider>
	);
};

export default SearchIndexPage;
