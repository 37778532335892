/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { TApiDrivenSelectFieldProps } from 'components/input-fields/types/field-props';
import { debounce } from 'components/ui/search';
import { DEFAULT_ITEMS_LIMIT_PER_PAGE } from 'config/constants';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { IPaginatedApiMetaResponse } from 'types/types';

import { ApiDrivenSelectUtils } from './utils';

// Put all the APIs that should be filtered locally, not with the API
const LOCAL_FILTER_APIS: string[] = [
	API_URLS.MASTER_COUNTRIES,
	API_URLS.MASTER_DISTRICTS,
	API_URLS.MASTER_STATES,
	API_URLS.ARTICLE_TAGS,
	API_URLS.AUTHOR_ALL,
	API_URLS.PARTY_ALL,
	API_URLS.LEADER_ALL
];

type TProps = {
	api: TApiDrivenSelectFieldProps['api'];
};

type TApiResponse = IPaginatedApiMetaResponse;

const useApiDrivenSelect = ({ api }: TProps) => {
	const isLocalFilter = useMemo(
		() => LOCAL_FILTER_APIS.some((localFilterApi) => api.url.includes(localFilterApi)),
		[api.url]
	);

	const optionsRef = useRef<any[]>([]); // options for the select, used for filtering

	const [data, setData] = useState<any[]>([]); // actual data from the API
	const [filteredOptions, setFilteredOptions] = useState<any[]>([]); // filtered options for the select

	const [filters, setFilters] = useState<any>({
		...(api?.params || {}),
		q: '',
		page: api.params?.page ?? 1,
		limit: api.params?.limit ?? DEFAULT_ITEMS_LIMIT_PER_PAGE
	});

	const isFetchingNextPageDataRef = useRef(false);

	const debouncedSetSearchValue = debounce({ func: setFilters, delay: 300 });

	const queryFetchOption: UseQueryResult<TApiResponse> = useQuery({
		queryKey: ['api-drive-select', api.url, filters],
		queryFn: async () => (await HttpService.get(api.url, filters))?.data?.data,
		retry: true
	});

	const handleFetchNextPage = async () => {
		if (!queryFetchOption.isFetching && queryFetchOption.data?.hasNextPage) {
			isFetchingNextPageDataRef.current = true;

			setFilters((prev: any) => ({ ...prev, page: (filters?.page || 0) + 1 }));
		}
	};

	useEffect(() => {
		if (queryFetchOption.data) {
			const newData = ApiDrivenSelectUtils.getData(api.responseDataStructure, queryFetchOption.data);
			const finalData = isFetchingNextPageDataRef.current ? [...data, ...newData] : newData;

			isFetchingNextPageDataRef.current = false;

			optionsRef.current = ApiDrivenSelectUtils.getOptions({ data: finalData, api });
			setFilteredOptions(optionsRef.current);
			setData(finalData);
		}
	}, [queryFetchOption.data]);

	useEffect(() => {
		isFetchingNextPageDataRef.current = false;
	}, [queryFetchOption.isError]);

	const handleClearSearch = () => {
		if (isLocalFilter) {
			setFilteredOptions(optionsRef.current); // Setting back to original options
			return;
		}

		debouncedSetSearchValue((prev: any) => ({ ...prev, q: '', page: 1 }));
	};

	const handleOnSearch = (value: string) => {
		if (isLocalFilter) {
			const filtered = ApiDrivenSelectUtils.filterOptions(optionsRef.current, value);
			setFilteredOptions(filtered);
			return;
		}

		debouncedSetSearchValue((prev: any) => ({ ...prev, q: value, page: 1 }));
	};

	return {
		isFetchingNextPageDataRef,
		optionsRef,
		filteredOptions,
		data,
		queryFetchOption,
		setFilters,
		handleOnSearch,
		handleClearSearch,
		handleFetchNextPage
	};
};

export default useApiDrivenSelect;
