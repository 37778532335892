import { Form, Input } from 'antd';

import { TPasswordFieldProps } from '../types/field-props';

const PasswordField: React.FC<TPasswordFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<Input.Password size='large' {...fieldProps} />
		</Form.Item>
	);
};

export default PasswordField;
