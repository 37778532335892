import { ColProps, FormItemProps } from 'antd';

import { EAge } from 'types/enums';

import SelectField from '../components/select';
import { TSelectFieldProps } from '../types/field-props';

type TProps = {
	colProps?: ColProps;
	formItemProps?: FormItemProps & {
		additionalRules?: FormItemProps['rules'];
	};
	fieldProps?: TSelectFieldProps['fieldProps'];
};

type TReturn = TSelectFieldProps & {
	colProps?: ColProps;
};

export const getAgeFormField = (props?: TProps): TReturn => {
	const { additionalRules, ...restFormItemProps } = props?.formItemProps || {};

	return {
		fieldType: 'select',
		colProps: { span: 24, ...props?.colProps },
		formItemProps: {
			name: 'age',
			label: 'Age Rating',
			rules: [{ required: true, message: 'Please select Age Rating!' }, ...(additionalRules || [])],
			...restFormItemProps
		},
		fieldProps: {
			placeholder: 'Select Age Rating',
			options: Object.entries(EAge).map(([key, value]) => ({
				label: key, // `${key} - ${value}+`,
				value: +value
			})),
			...props?.fieldProps,
			allowClear: true
		}
	};
};

const AgeFormField = (props?: TProps) => {
	return <SelectField fieldProps={{ allowClear: true }} {...getAgeFormField(props)} />;
};

export default AgeFormField;
