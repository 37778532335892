import React from 'react';

import { Col, Row } from 'antd';

import DistrictWiseSubscribers from './components/subscribers/district-wise-subscribers/district-wise-subscribers';
import YtdSubscribersJoinedLeft from './components/subscribers/subscribers-joined-vs-left/subscribers-joined-vs-left';
import UserEngagementPublicContent from './components/user-engagement-public-contents';

const DashboardPage: React.FC = () => {
	return (
		<Row className='mb-5'>
			<Col span={24}>
				<UserEngagementPublicContent />
				<Row gutter={8} className='pt-2 h-[400px]'>
					<Col span={12}>
						<div className='bg-white p-3 h-full rounded-md shadow-md'>
							<DistrictWiseSubscribers />
						</div>
					</Col>
					<Col span={12}>
						<div className='bg-white p-3 h-full rounded-md shadow-md'>
							<YtdSubscribersJoinedLeft />
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DashboardPage;
