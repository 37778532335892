/* eslint-disable @typescript-eslint/no-use-before-define */

import { Flex, List, Pagination, Result, Spin, Tag, Typography, Image } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_ITEMS_LIMIT_PER_PAGE, IMAGE_FALLBACK_URL } from 'config/constants';
import { capitalizer } from 'services/helpers.service';
import { EGlobalSearchContentTypes, IGlobalSearchResponseListItem } from 'types/global-search';

import useSearchData from './useSearchData';

const GlobalSearchData = () => {
	const { querySearchData, searchApiParams, setSearchApiParams } = useSearchData();

	return (
		<Flex vertical className='w-[1000px] h-[550px]'>
			<Spin spinning={querySearchData?.isFetching}>
				<div className='overflow-y-scroll h-[500px] '>
					{!querySearchData?.data && querySearchData?.isError && <ErrorComp error={querySearchData?.error} />}
					{querySearchData?.data && !querySearchData?.isError && (
						<List
							itemLayout='vertical'
							dataSource={querySearchData?.data?.items || []}
							renderItem={(item) => <RenderItem item={item} />}
						/>
					)}
				</div>
			</Spin>

			<Flex justify='end' align='end' className='h-[50px]'>
				{(querySearchData?.data?.items?.length || 0) > 0 && (
					<Pagination
						pageSize={searchApiParams.limit || DEFAULT_ITEMS_LIMIT_PER_PAGE}
						total={querySearchData.data?.totalItems || 0}
						current={searchApiParams.page}
						showSizeChanger={false}
						onChange={(page) => setSearchApiParams((pv) => ({ ...pv, page }))}
					/>
				)}
			</Flex>
		</Flex>
	);
};

export default GlobalSearchData;

const RenderItem = ({ item }: { item: IGlobalSearchResponseListItem }) => {
	const navigate = useNavigate();

	const { setOpenSearchData, getHashContentTypeToItemData, getHashContentTypeToNavigatePath } = useSearchData();

	const mappedData = getHashContentTypeToItemData(item)[item?.contentType as EGlobalSearchContentTypes] || {};

	const handleClick = () => {
		const navigationDetails = getHashContentTypeToNavigatePath(item)[item?.contentType as EGlobalSearchContentTypes];
		navigate(navigationDetails.to, navigationDetails.options);

		setOpenSearchData(false);
	};

	return (
		<List.Item>
			<Flex gap={16}>
				<Image src={mappedData?.imageUrl || IMAGE_FALLBACK_URL} width={60} height={60} className='rounded-md' />
				<Flex justify='space-between' className='cursor-pointer w-full' onClick={handleClick}>
					<Flex gap={3} vertical className='overflow-hidden w-[750px]'>
						<Typography.Title
							className='text-[14px] font-medium m-0'
							ellipsis={{
								tooltip: {
									overlayInnerStyle: { maxHeight: 200, overflow: 'scroll' }
								}
							}}
						>
							{mappedData?.title}
						</Typography.Title>
						{mappedData?.description && (
							<Typography.Paragraph
								className='text-gray-500 text-[13px] m-0 '
								ellipsis={{
									tooltip: {
										overlayInnerStyle: { maxHeight: 200, overflow: 'scroll' }
									}
								}}
							>
								{mappedData?.description}
							</Typography.Paragraph>
						)}
					</Flex>
					<div>
						<Tag color={mappedData?.tagColor} className='text-[9px] py-0 px-1 w-20 text-center'>
							{capitalizer(item?.contentType)}
						</Tag>
					</div>
				</Flex>
			</Flex>
		</List.Item>
	);
};

const ErrorComp = ({ error }: { error: Error }) => {
	return (
		<Result
			status='error'
			title='Error'
			subTitle={
				error?.message ? error?.message : error ? String(error) : 'Sorry, something went wrong. Please try again later.'
			}
		/>
	);
};
