import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';

interface GuestGuardProps {
	children: React.ReactNode;
}

const GuestGuard = ({ children }: GuestGuardProps) => {
	const navigate = useNavigate();

	const auth = useAuth();

	useEffect(() => {
		if (auth.user) {
			navigate('/');
		}
	}, [auth.user]);

	if (auth.user) return null;

	return <>{children}</>;
};

export default GuestGuard;
