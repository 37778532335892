type LogLevel = 'log' | 'error' | 'warn' | 'info';

interface LogEntry {
	message: string;
	level: LogLevel;
	timestamp: string;
	url: string;
}

let logBuffer: LogEntry[] = [];

const addToBuffer = (message: string, level: LogLevel = 'info') => {
	const timestamp = new Date().toISOString();
	const url = window.location.href;
	logBuffer.push({ message, level, timestamp, url });

	// Keep only the last 2 minutes of logs
	const twoMinutesAgo = new Date(Date.now() - 2 * 60 * 1000);
	logBuffer = logBuffer.filter((log) => new Date(log.timestamp) > twoMinutesAgo);
};

// Store the original console methods
const originalLog = console.log;
const originalError = console.error;
// Repeat for console.warn, console.info if needed

export const log = (...args: any[]) => {
	addToBuffer(args.join(' '), 'log');
	originalLog(...args); // Use the original log method
};

export const error = (...args: any[]) => {
	addToBuffer(args.join(' '), 'error');
	originalError(...args); // Use the original error method
};

// Overwrite console methods
console.log = log;
console.error = error;
// Repeat for console.warn, console.info if needed

export const getLogBuffer = () => logBuffer.slice(); // Return a copy of the log buffer
