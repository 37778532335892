import { ColProps, FormItemProps } from "antd";

import TextField from "../components/text";
import { TTextFieldProps } from "../types/field-props";

type TProps = {
  colProps?: ColProps;
  formItemProps?: FormItemProps & {
    additionalRules?: FormItemProps["rules"];
  };
  fieldProps?: TTextFieldProps["fieldProps"];
};

type TReturn = TTextFieldProps & {
  colProps?: ColProps;
};

export const getTitleFormField = (props?: TProps): TReturn => {
  const { additionalRules, ...restFormItemProps } = props?.formItemProps || {};

  return {
    fieldType: "text",
    colProps: { span: 24, ...props?.colProps },
    formItemProps: {
      name: "title",
      label: "Title",
      rules: [
        { required: true, message: "Please input Title!" },
        ...(additionalRules || []),
      ],
      ...restFormItemProps,
    },
    fieldProps: {
      placeholder: "Enter Title",
      ...props?.fieldProps,
    },
  };
};

const TitleFormField = (props?: TProps) => {
  return <TextField {...getTitleFormField(props)} />;
};

export default TitleFormField;
