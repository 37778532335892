import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { convertToTitleCase } from 'helpers';
import { DASHBOARD_QUERIES } from 'services/tanstack-query/dashboard-apis';

import { ESubscribersPeriodTypes } from '../../../type';

const DEFAULT_SELECTED_PERIOD = ESubscribersPeriodTypes.YEARLY;

const useSubscribersJoinedVsLeft = () => {
	const [period, setPeriod] = useState(DEFAULT_SELECTED_PERIOD);

	const querySubscribersJoinedVsLeft = useQuery({
		...DASHBOARD_QUERIES.fetchSubscribersJoinedVsLeft({ period }),
		refetchInterval: 10000
	});

	const handlePeriodChange = (value: ESubscribersPeriodTypes) => {
		setPeriod(value);
	};

	const subscribersData = useMemo(() => {
		const hash: any = {};

		(querySubscribersJoinedVsLeft?.data || []).forEach((item: any) => {
			hash[item?.district] = {
				name: convertToTitleCase(item?.district),
				joined: item?.status === 'PAID' ? item?.user_count : hash[item?.district]?.joined || 0,
				left: item?.status !== 'PAID' ? item?.user_count : hash[item?.district]?.left || 0
			};
		});

		return Object.values(hash);
	}, [querySubscribersJoinedVsLeft?.data]);

	return {
		DEFAULT_SELECTED_PERIOD,
		isLoading: querySubscribersJoinedVsLeft.isLoading,
		subscribersData,
		handlePeriodChange
	};
};

export default useSubscribersJoinedVsLeft;
