import { RefObject } from 'react';

import { Form } from 'antd';

import { TGlobalFilterConfig } from '../types';

type TProps = {
	filterButtonRef: RefObject<HTMLButtonElement>;
} & TGlobalFilterConfig;

function useFilterContainer(params: TProps) {
	const { filterButtonRef, onSubmit, onClose } = params;

	const [form] = Form.useForm();

	const handleCloseFilter = () => {
		filterButtonRef.current?.click();
		onClose?.();
	};

	const handleFiltersResult = () => {
		onSubmit?.(form.getFieldsValue());
		handleCloseFilter();
	};

	const handleResetFilter = () => {
		form.resetFields();
		onSubmit?.(form.getFieldsValue());
	};

	return {
		form,
		handleFiltersResult,
		handleCloseFilter,
		handleResetFilter,
	};
}

export default useFilterContainer;
