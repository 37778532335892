import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { TFetchImagesData } from 'types/image';

export type UseQueryType = {
	queryKey: ReadonlyArray<unknown>;
	queryFn: (arg0?: any) => Promise<any>;
};

export type FetchImagesArgs = {
	q: string;
	tags?: string[];
	page: number;
	limit: number;
	sortBy: string;
	sort: string;
};

export const IMAGE_QUERIES = {
	fetchMedia: (id: string): UseQueryType => ({
		queryKey: [API_URLS.MEDIA_IMAGE, id],
		queryFn: () => HttpService.get(`${API_URLS.MEDIA_IMAGE}/${id}`).then(({ data }) => data?.data)
	}),
	fetchImages: (args: FetchImagesArgs): UseQueryType => ({
		queryKey: [API_URLS.MEDIA_IMAGE, args],
		queryFn: () =>
			HttpService.get(API_URLS.MEDIA_IMAGE, args)?.then(({ data }) => {
				// revise image data
				const raw = data?.data as TFetchImagesData;
				raw.items.forEach((item: any) => {
					item.src = item?.urls?.url;
					item.width = 375;
					item.height = 250;
					item.tags?.map((e: string) => ({
						value: e,
						title: e
					}));
					item.caption = item.title;
				});
				return raw;
			})
	})
};

export const IMAGE_MUTATIONS = {
	uploadSingleImage: () => ({
		mutationFn: (formData: FormData) => HttpService.post(API_URLS.MEDIA_UPLOAD_IMAGE, formData)
	}),
	updateSingleImage: (id?: string) => ({
		mutationFn: (values: Record<string, any>) => HttpService.put(`${API_URLS.MEDIA}/${id}`, values)
	}),

	uploadBulkImage: () => ({
		mutationFn: (formData: FormData) => HttpService.post(API_URLS.MEDIA_UPLOAD_BULK_IMAGES, formData)
	})
};
