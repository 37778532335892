import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	FieldTimeOutlined,
	ProfileOutlined,
	FileTextOutlined,
	FileSyncOutlined,
} from '@ant-design/icons';

export enum FILTER_OPTIONS {
	CREATED_AT = 'createdAt',
	UPDATED_AT = 'updatedAt',
	TITLE = 'title',
	DESCRIPTION = 'description',
}

export type TSortOrder = 'ASC' | 'DESC';

interface FilterMenuProps {
	selectedItem: (newData: string) => void;
	sortOrder: TSortOrder;
}

const SELECTED_ICON_STYLE = { marginLeft: '12px' };
const OPTION_ICON_STYLE = { marginRight: '12px' };
function getSortIcon(sortOrder: TSortOrder) {
	return sortOrder === 'ASC' ? <ArrowDownOutlined style={SELECTED_ICON_STYLE} /> : <ArrowUpOutlined style={SELECTED_ICON_STYLE} />;
}

export default function FilterButton({ selectedItem, sortOrder }: FilterMenuProps) {
	const [sortBy, setSortBy] = useState('createdAt' as any);

	const menuItem = [
		{ name: 'Created At', value: 'createdAt' },
		{ name: 'Updated At', value: 'updatedAt' },
		{ name: 'Title', value: 'title' },
		{ name: 'Description', value: 'description' },
	];

	const handleItemClick = (item: string) => {
		setSortBy(item);
		selectedItem(item);
	};

	const menu = (
		<Menu>
			<Menu.Item key='1' onClick={() => handleItemClick(menuItem[0].value)}>
				<FieldTimeOutlined style={OPTION_ICON_STYLE} />
				<span>{menuItem[0].name}</span>

				{sortBy === 'createdAt' && getSortIcon(sortOrder)}
			</Menu.Item>
			<Menu.Item key='3' onClick={() => handleItemClick('updatedAt')}>
				<FileSyncOutlined style={OPTION_ICON_STYLE} />

				<span>Updated At</span>
				{sortBy === 'updatedAt' && getSortIcon(sortOrder)}
			</Menu.Item>
			<Menu.Item key='2' onClick={() => handleItemClick('title')}>
				<ProfileOutlined style={OPTION_ICON_STYLE} />
				<span>Title</span>
				{sortBy === 'title' && getSortIcon(sortOrder)}
			</Menu.Item>
			<Menu.Item key='3' onClick={() => handleItemClick('description')}>
				<FileTextOutlined style={{ marginRight: '8px' }} />
				<span>Description</span>
				{sortBy === 'description' && getSortIcon(sortOrder)}
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Button size='large'>
				{sortOrder === 'ASC' ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
				{menuItem.map((items: any, i: number) => items.value === sortBy && items.name)}
			</Button>
		</Dropdown>
	);
}
