import { TUserTranslation } from 'localization/types/user';

const user: TUserTranslation = {
	users: 'Users',
	addOrManageAdmin: 'Add or Manage Admin',
	addUser: 'Add User',
	cmsManagers: 'CMS Managers',
	platformUsers: 'Platform Users',
	superAdmin: 'Super Admin',
	admin: 'Admin',
	publisher: 'Publisher',
	reviewer: 'Reviewer',
	contentCreator: 'Content Creator',
	viewer: 'Viewer',
	allUsers: 'All Users',
	paidUsers: 'Paid Users',
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	searchUsers: 'Search Users',
	gender: 'Gender',
	male: 'Male',
	female: 'Female',
	copy: 'Copy',
	changeUserRole: 'Change User Role',
	changeRole: 'Change Role',
	userRoleUpdatedSuccessfully: 'User role updated successfully',
	selectRolError: 'Please select role!',
	selectRole: 'Select Role',
	updateUser: 'Update User',
	thumbnail: 'Thumbnail',
	fullName: 'Full Name',
	phoneNumber: 'Phone Number',
	dob: 'DOB',
	status: 'Status',
	actions: 'Actions',
	resetPassword: 'Reset Password',
	userUpdateSuccess: 'User status updated successfully',
	userDeleteSuccess: 'User deleted successfully',
	changePasswordTitle: 'Are you sure you want to change password for this user?',
	activate: 'activate',
	deactivate: 'deactivate',
	changeUserStatusTitle: 'Are you sure you want to {{status}} this user?',
	youCanReactivateMsg: 'You can reactivate this user later.',
	deleteUserTitle: 'Are you sure you want to delete this user?',
	passwordCopiedMsg: 'Password copied to clipboard',
	deactivateUser: 'Deactivate User',
	activateUser: 'Activate User',
	enterFirstNameEmpty: 'Please input first name!',
	enterFirstName: 'Enter First Name',
	enterLastNameEmpty: 'Please input last name!',
	enterLastName: 'Enter Last Name',
	dateOfBirth: 'Date of Birth',
	selectDateOfBirth: 'Select Date of Birth',
	inputEmailEmpty: 'Please input email!',
	enterEmail: 'Enter email',
	phone: 'Phone',
	inputPhoneEmpty: 'Please input phone number!',
	userRole: 'User Role',
	inputUserRoleEmpty: 'Please select user role!',
	selectUserRole: 'Select User Role',
	profilePhoto: 'Profile Photo'
};

export default user;
