import { ColProps, FormItemProps } from 'antd';

import { REGEX } from 'config/constants';

import TextField from '../components/text';
import { TTextFieldProps } from '../types/field-props';

type TProps = {
	colProps?: ColProps;
	formItemProps?: FormItemProps;
	fieldProps?: TTextFieldProps['fieldProps'];
};

type TReturn = TTextFieldProps & {
	colProps?: ColProps;
};

export const getSlugFormField = (props?: TProps): TReturn => {
	return {
		fieldType: 'text',
		colProps: { span: 24, ...props?.colProps },
		formItemProps: {
			name: 'slug',
			label: 'Slug',
			rules: [
				{
					required: true,
					message: 'Please input Slug!',
				},
				{
					pattern: REGEX.SLUG,
					message:
						'Invalid format. Only alphanumeric characters and non-consecutive hyphens allowed, without starting/ending with a hyphen.',
				},
			],
			tooltip: props?.formItemProps?.tooltip || 'Slug must be in english',
			extra: props?.formItemProps?.extra || 'Slug will be auto generated based on Title. You can also edit it. example: my-slug-value',
			normalize: (value: string) => value.toLowerCase(),
			...props?.formItemProps,
		},
		fieldProps: {
			placeholder: 'Enter Slug',
			...props?.fieldProps,
		},
	};
};

const SlugFormField = (props?: TProps) => {
	return <TextField {...getSlugFormField(props)} />;
};

export default SlugFormField;
