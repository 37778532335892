import { TModalTranslation } from 'localization/types/modal';

const modal: TModalTranslation = {
	yes: 'Yes',
	no: 'No',
	cancel: 'Cancel',
	actionCannotBeUndone: 'This action cannot be undone.',
	areYouSureToDelete: 'Are you sure to delete?'
};

export default modal;
