export enum ESortOrder {
	ASC = 'ASC',
	DESC = 'DESC'
}

export enum EFilterCreatedUpdatedAt {
	CREATED_AT = 'createdAt',
	UPDATED_AT = 'updatedAt'
}

export enum EGenres {
	ACTION = 'Action',
	ADVENTURE = 'Adventure',
	COMEDY = 'Comedy',
	DRAMA = 'Drama',
	FANTASY = 'Fantasy',
	HORROR = 'Horror',
	MYSTERY = 'Mystery',
	ROMANCE = 'Romance',
	SCI_FI = 'Sci-Fi',
	THRILLER = 'Thriller',
	ANIMATION = 'Animation',
	CRIME = 'Crime',
	DOCUMENTARY = 'Documentary',
	HISTORICAL = 'Historical',
	MUSICAL = 'Musical',
	TRUE_CRIME = 'True Crime',
	NEWS_AND_POLITICS = 'News & Politics',
	BUSINESS = 'Business',
	SOCIETY_AND_CULTURE = 'Society & Culture',
	TECHNOLOGY = 'Technology',
	HEALTH_AND_WELLNESS = 'Health & Wellness',
	EDUCATION = 'Education',
	ARTS = 'Arts',
	SCIENCE = 'Science',
	HISTORY = 'History',
	FICTION = 'Fiction',
	SPORTS = 'Sports',
	PERSONAL_FINANCE = 'Personal Finance',
	PARENTING = 'Parenting',
	REALITY = 'Reality',
	OTHER = 'Other'
}

export enum EStatus {
	INITIAL_DRAFT = 'INITIAL_DRAFT',
	REQUEST_REVIEW = 'REQUEST_REVIEW',
	READY_FOR_PUBLICATION = 'READY_FOR_PUBLICATION',
	PUBLISHED = 'PUBLISHED',
	ARCHIVED = 'ARCHIVED',
	REJECTED = 'REJECTED'
}

export enum EStatusColors {
	INITIAL_DRAFT = '#6699CC',
	REQUEST_REVIEW = '#FFA500',
	READY_FOR_PUBLICATION = '#008080',
	ARCHIVED = '#808080',
	PUBLISHED = '#008000',
	REJECTED = '#c01d1c'
}

// Button is next to the filter button, used to toggle between archived and all statuses,
export enum EStatusButtonType {
	Archived = 'archived',
	All = 'all'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum EPODCAST_ROLE {
	Host = 'Host',
	Producer = 'Producer',
	Editor = 'Editor',
	'Sound Designer/Sound Mixer/Audio Engineer' = 'Sound Designer/Sound Mixer/Audio Engineer',
	Researcher = 'Researcher',
	Writer = 'Writer',
	'Guest Coordinator' = 'Guest Coordinator',
	'Marketing Coordinator' = 'Marketing Coordinator',
	'Graphic Designer' = 'Graphic Designer',
	Transcriber = 'Transcriber',
	'Theme Music Composer' = 'Theme Music Composer',
	Publicist = 'Publicist',
	'Legal Advisor' = 'Legal Advisor',
	'Executive Producer' = 'Executive Producer',
	'Network/Platform Representative' = 'Network/Platform Representative',
	'Social Media Manager' = 'Social Media Manager',
	'Community Manager' = 'Community Manager',
	'Show Notes Writer' = 'Show Notes Writer',
	'Sponsorship Coordinator' = 'Sponsorship Coordinator',
	Assistant = 'Assistant'
}

export enum EContentTypes {
	// todo return
	EDUCATION = 'EDUCATION',
	MOVIE = 'MOVIE',
	ARTICLE = 'ARTICLE',
	PODCAST = 'PODCAST',
	PODCAST_EPISODE = 'PODCAST_EPISODE',
	SERIES = 'SERIES',
	SERIES_EPISODE = 'SERIES_EPISODE',
	STREAM = 'STREAM',
	CATEGORY = 'CATEGORY',
	COMMUNITY = 'COMMUNITY',
	LEADERS = 'LEADERS',
	PARTY = 'PARTY',
	ALLIANCE = 'ALLIANCE',
	DISTRICT = 'DISTRICT'
}

export enum EContentTypesApiKeys {
	MOVIE = 'movies',
	ARTICLE = 'articles',
	PODCAST = 'podcasts',
	PODCAST_EPISODE = 'podcastEpisodes',
	SERIES = 'series',
	SERIES_EPISODE = 'seriesEpisodes',
	STREAM = 'streams',
	CATEGORY = 'categories',
	COMMUNITY = 'communities',
	LEADERS = 'leaders',
	PARTY = 'parties',
	DISTRICT = 'districts',
	ALLIANCE = 'alliances',
	EDUCATION = 'education'
}

export enum EDynamicPageSectionDataForm {
	STATIC = 'STATIC',
	LATEST = 'LATEST',
	OLDEST = 'OLDEST',
	MOST_VIEWED = 'MOST VIEWED',
	RECENTLY_VIEWED = 'RECENTLY_VIEWED',
	RECOMMENDATIONS = 'RECOMMENDATIONS'
}
export enum EDynamicPageSectionDataFormStreams {
	STATIC = 'STATIC',
	LATEST = 'LATEST',
	OLDEST = 'OLDEST',
	MOST_VIEWED = 'MOST VIEWED'
}

// !IMPORTANT: Enum keys are used in the backend and must not be changed. Values, used as labels on the frontend, can be modified.
export enum EVideoResolutions {
	SD = '720p',
	HD = '1080p'
}

// !IMPORTANT: Enum keys are used in the backend and must not be changed. Values, used as labels on the frontend, can be modified.
export enum ESubscriptionPackageType {
	MONTHLY = 'Monthly',
	QUARTERLY = 'Quarterly',
	YEARLY = 'Yearly'
}

// !IMPORTANT: Enum keys are used in the backend and must not be changed. Values, used as labels on the frontend, can be modified.
export enum ESubscriptionPackageCurrencies {
	INR = 'INR',
	AED = 'AED',
	USD = 'USD'
	// EUR = "EUR",
	// JPY = "JPY",
	// GBP = "GBP",
	// AUD = "AUD",
	// CAD = "CAD",
	// CHF = "CHF",
	// CNY = "CNY",
	// HKD = "HKD",
	// NZD = "NZD",
	// SEK = "SEK",
	// KRW = "KRW",
	// SGD = "SGD",
	// NOK = "NOK",
	// MXN = "MXN",
	// RUB = "RUB",
	// ZAR = "ZAR",
	// TRY = "TRY",
	// BRL = "BRL",
	// SAR = "SAR",
	// QAR = "QAR",
	// KWD = "KWD",
	// ILS = "ILS",
}

// !IMPORTANT: Enum values are used to send in the backend. keys, used as labels on the frontend.
export enum EAge {
	'A' = '100',
	'UA' = '7'
}

export enum EStorageKeys {
	TRANSLATION_LANGUAGE = 'translation-language'
}

// internalEnum field is used for this in the backend
export enum ECategoryType {
	EDUCATION = 'Education',
	ENTERTAINMENT = 'Entertainment',
	SPORTS = 'Sports',
	POLITICS = 'Politics',
	OTHER = 'Other'
}
