import { ESaveAudioType } from 'pages/media/audio/types';

export const DROPDOWN_MENU_ITEMS = {
	save: {
		[ESaveAudioType.single]: {
			key: ESaveAudioType.single,
			label: 'Single Audio File',
		},
		[ESaveAudioType.bulk]: {
			key: ESaveAudioType.bulk,
			label: 'Bulk Audio File',
		},
	},
} as const;
