import { Flex, Layout } from 'antd';

import GlobalSearch from './glabal-search';
import NavbarUserDetails from './user-detials';

const { Header } = Layout;

const HeaderNavbar = () => {
	return (
		<Header className='h-20 w-full flex items-center justify-end sticky top-0 z-10 cursor-pointer'>
			<Flex flex={1} align='center' gap={48}>
				<Flex flex={1} className='px-8'>
					<GlobalSearch />
				</Flex>

				<Flex justify='flex-end' className='w-56 '>
					<NavbarUserDetails />
				</Flex>
			</Flex>
		</Header>
	);
};

export default HeaderNavbar;
