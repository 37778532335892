import { TDashboardTranslation } from 'localization/types/dashboard';

const dashboard: TDashboardTranslation = {
	dashboard: 'Dashboard',
	views: 'Views',
	'watchTime(mins)': 'Watch Time (mins)',
	likes: 'Likes',
	down: 'down',
	up: 'up',
	thanLastWeek: 'than last week',
	totalArticles: 'Total Articles',
	totalMovies: 'Total Movies',
	totalPodcasts: 'Total Podcasts',
	totalSeries: 'Total Series',
	totalPropagandas: 'Total Propagandas',
	top10ViewedCategories: 'Top 10 Viewed Categories',
	propagandas: 'Propagandas',
	articles: 'Articles',
	movies: 'Movies',
	podcasts: 'Podcasts',
	series: 'Series',
	contentDistribution: 'Content Distribution',
	stateWiseSubscribers: 'State Wise Subscribers',
	subscribersJoinedVsLeft: 'Subscribers Joined Vs Left',
	joined: 'Joined',
	left: 'Left'
};

export default dashboard;
