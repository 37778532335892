import { TApiDrivenSelectFieldProps } from 'components/input-fields/types/field-props';

type TResponseDataStructure = TApiDrivenSelectFieldProps['api']['responseDataStructure'];

type TDataHandlers = {
	[key in TResponseDataStructure]: () => any[];
};

const getData = (handlerType: TResponseDataStructure, data: any) => {
	const dataHandlers: TDataHandlers = {
		none: () => data || [],
		items: () => data?.items || [],
		tags: () => data?.tags || [],
		data: () => (Array.isArray(data) ? data : [data])
	};

	return dataHandlers[handlerType]();
};

type TGetOptions = {
	data: any;
	api: TApiDrivenSelectFieldProps['api'];
};

const getOptions = ({ data, api }: TGetOptions) => {
	const options = data.map((item: any) => {
		return {
			value: api.responseDataStructure === 'tags' ? item : item[api.valueKey],
			label: (() => {
				let label = api.responseDataStructure === 'tags' ? item : item[api.labelKey];
				if (api.getOptionLabel) {
					label = api.getOptionLabel(item);
				}

				return label;
			})(),
			...item
		};
	});

	const excludeValues = typeof api.excludeValues === 'function' ? api.excludeValues(data) : api.excludeValues;

	const filteredOptions = options.filter((option: any) => {
		return !excludeValues?.includes(option.value);
	});

	return filteredOptions;
};

const filterOptions = (options: any[], value: string) => {
	return options?.filter((option) => option?.label?.toLowerCase().includes(value?.toLowerCase()));
};

export const ApiDrivenSelectUtils = {
	getData,
	getOptions,
	filterOptions
};
