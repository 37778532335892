import { Form, InputNumber } from 'antd';

import { TNumberFieldProps } from '../types/field-props';

const NumberField: React.FC<TNumberFieldProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item extra={formItemProps?.extra ? formItemProps?.extra : 'Accepts number only'} {...formItemProps}>
			<InputNumber size='large' {...fieldProps} style={{ ...(fieldProps?.style || {}), width: '100%' }} />
		</Form.Item>
	);
};

export default NumberField;
