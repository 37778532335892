import { useEffect, useRef, useState } from 'react';
import { HttpService } from '../../../services/http.service';
import { TSortOrder } from './FilterButton';
import { API_URLS } from '../../../services/apiUrls';
import { ApiHandlersService } from '../../../services/api-handlers.service';
import { useUtils } from '../../../contexts/UtilsProvider';
import { ESaveAudioType } from 'components/drawers/SaveAudioDrawer/types';
import { TSaveAudioType } from 'pages/media/audio/types';

export const AUDIO_PER_PAGE = 30;

export type TAudio = {
	id: string;
	url: string;
	title: string;
};

type TProps = {
	selectMultiple?: boolean;
	onAudioSelected?: (audio: TAudio[]) => void;
	onSelect?: (selectedMedias: TAudio[]) => void;
};

const DEFAULT_SORT_BY = 'createdAt';
const DEFAULT_SORT_ORDER: TSortOrder = 'DESC';
const DEFAULT_FILTER_TAGS = '';

export const useAudioGallery = ({ selectMultiple, onAudioSelected, onSelect }: TProps) => {
	const utils = useUtils();

	const [isLoading, setIsLoading] = useState(true);
	const [dataList, setDataList] = useState([] as any);
	const [pagination, setPagination] = useState(1);
	const [searchString, setSearchString] = useState('');

	const sortByRef = useRef<string>(DEFAULT_SORT_BY);
	const sortOrderRef = useRef<TSortOrder>(DEFAULT_SORT_ORDER);
	const totalAudiosRef = useRef(0);

	const saveAudioTypeRef = useRef<TSaveAudioType>(ESaveAudioType.single);
	const [openSaveAudio, setOpenSaveAudio] = useState(false);

	const totalItemsRef = useRef<number>(0);

	const onAudioModalClose = (refresh?: boolean) => {
		setOpenSaveAudio(false);

		if (refresh) {
			fetchAudios(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, DEFAULT_SORT_ORDER);
		}
	};

	const handleSaveAudioDrawerClose = (refresh?: boolean) => {
		setOpenSaveAudio(false);

		if (!refresh) return;

		fetchAudios(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, DEFAULT_SORT_ORDER);
	};

	const handleSaveAudio = (key: ESaveAudioType) => {
		saveAudioTypeRef.current = key;
		setOpenSaveAudio(true);
	};

	const handlePageSelection = (page: number) => {
		setPagination(page);
	};

	const fetchAudios = async (pagination: number, searchString: string, filterTags: string, sortByOrder: string, sortOrder: string) => {
		try {
			setIsLoading(true);

			const { data } = await HttpService.get(
				`${API_URLS.MEDIA_AUDIO}?q=${searchString}&tags=${filterTags}&page=${pagination}&limit=${AUDIO_PER_PAGE}&sortBy=${sortByOrder}&sort=${sortOrder}`,
			);

			totalItemsRef.current = data?.data?.totalItems || 0;
			setDataList(data?.data?.items);
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAudios(pagination, searchString, DEFAULT_FILTER_TAGS, DEFAULT_SORT_BY, DEFAULT_SORT_ORDER);
	}, [pagination, searchString]);

	const onSearch = (searchString: string) => {
		setSearchString(searchString);
	};

	const onFilterOptionSelected = (sortBy: string) => {
		sortByRef.current = sortBy;
		if (sortBy === sortByRef.current) {
			sortOrderRef.current = sortOrderRef.current === 'ASC' ? 'DESC' : 'ASC';
		} else {
			sortOrderRef.current = 'DESC';
		}

		fetchAudios(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, sortOrderRef.current);
	};

	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const selectedItemsId = selectedItems.map((item) => item.id);

	const handleItemSelect = (id: string) => {
		const item = dataList.find((item: any) => item.id === id);

		const newSelectedItems = selectedItemsId.includes(id)
			? selectedItems.filter((selectedItem) => selectedItem.id !== id)
			: selectMultiple
				? [...selectedItems, item]
				: [item];

		setSelectedItems?.(newSelectedItems);

		onAudioSelected?.(newSelectedItems);
		onSelect?.(newSelectedItems);
	};

	return {
		isLoading,
		dataList,
		pagination,
		sortOrderRef,
		onAudioModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		totalAudiosRef,

		selectedItemsId,
		handleItemSelect,
		totalItemsRef,
		saveAudioTypeRef,
		openSaveAudio,

		handleSaveAudioDrawerClose,
		handleSaveAudio,
	};
};
