import { EPageMetaSchemaType } from './type';

export const metaSchemaTypeOptions = {
	[EPageMetaSchemaType.ARTICLES]: [
		{
			value: 'AdvertiserContentArticle',
			label: 'Advertiser Content Article'
		},
		{ value: 'NewsArticle', label: 'News Article' },
		{ value: 'Report', label: 'Report' },
		{ value: 'SatiricalArticle', label: 'Satirical Article' },
		{ value: 'ScholarlyArticle', label: 'Scholarly Article' },
		{ value: 'SocialMediaPosting', label: 'Social Media Posting' },
		{ value: 'TechArticle', label: 'Tech Article' }
	],
	[EPageMetaSchemaType.MOVIES]: [
		{ value: 'Movie', label: 'Movie' },
		{ value: 'EducationalOrganization', label: 'Educational Organization' }
	],
	[EPageMetaSchemaType.TV_SERIES]: [
		{ value: 'TVSeries', label: 'TV Series' },
		{ value: 'EducationalOrganization', label: 'Educational Organization' }
	]
};

export const groupedFields: { [groupKey: string]: string[] } = {
	group1: ['metaSchemaType'],
	group2: ['metaTitle', 'metaDescription', 'metaKeyWords', 'metaImage'],
	group3: ['metaSocialTitle', 'metaSocialDescription', 'metaSocialKeyWords', 'metaSocialImage']
};
