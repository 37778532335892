/* eslint-disable react/jsx-no-useless-fragment */
import { Empty, Flex, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { COLORS_PALETTE } from 'config/colors';
import { convertToTitleCase } from 'helpers';
import { formatToUnits } from 'services/helpers.service';

import useDistrictWiseSubscribers from './useDistrictWiseSubscribers';
import HeaderContentBlock from '../../header-content-block';

const MAX_VISIBLE_DISTRICTS = 20;

const DistrictWiseSubscribers = () => {
	const { t } = useTranslation();

	const { DEFAULT_SELECTED_STATE_VALUE, queryStates, isLoading, districtsSubscribers, handleStateChange } = useDistrictWiseSubscribers();

	const chartOptions = {
		grid: {
			bottom: districtsSubscribers?.length > MAX_VISIBLE_DISTRICTS ? '25%' : '15%'
		},
		xAxis: {
			type: 'category',
			data: districtsSubscribers?.map((district: any) => convertToTitleCase(district?.district)),
			axisLabel: {
				rotate: 45,
				interval: 0 // Show all labels without skipping
			}
		},
		yAxis: {
			type: 'value'
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		series: [
			{
				data: districtsSubscribers?.map((district: any) => formatToUnits(district?.user_count)),
				type: 'bar',
				barMaxWidth: 50,
				color: COLORS_PALETTE.color400
			}
		],
		...(districtsSubscribers?.length > MAX_VISIBLE_DISTRICTS
			? {
					dataZoom: [
						{
							type: 'slider',
							start: 0,
							end: (MAX_VISIBLE_DISTRICTS / (districtsSubscribers?.length || 1)) * 100,
							zoomLock: false // Optional: if you want to disable zooming and only allow scrolling
						}
					]
				}
			: {})
	};

	return (
		<Flex flex={1} vertical className='h-full'>
			<HeaderContentBlock
				title={t('dashboard.stateWiseSubscribers')}
				selectProps={{
					loading: queryStates.isLoading,
					defaultValue: DEFAULT_SELECTED_STATE_VALUE,
					onChange: handleStateChange,
					options: (queryStates?.data || [])?.map((state: any) => ({
						label: state?.name,
						value: String(state?.id)
					}))
				}}
			/>

			<Flex flex={1} justify='center' align='center'>
				{isLoading && <Spin />}

				{!isLoading && (
					<>
						{districtsSubscribers?.length > 0 ? (
							<ReactECharts option={chartOptions} style={{ height: '100%', width: '100%' }} />
						) : (
							<Empty />
						)}
					</>
				)}
			</Flex>
		</Flex>
	);
};

export default DistrictWiseSubscribers;
