import { Col, Flex, Row, Typography } from 'antd';

import InputFields from 'components/input-fields';
import { EInputFieldTypes, EMediaTypes } from 'components/input-fields/types/enums';
import { TFormFields } from 'components/input-fields/types/types';
import { getGroupedFormFields } from 'components/input-fields/utils';
import { META_FIELD_RULE } from 'config/constants';

import { groupedFields, metaSchemaTypeOptions } from './config';
import { EPageMetaSchemaType, TGetMetaFormFields, TMetaInputFieldsProps } from './type';

const MetaInputFields = ({ form, pageSchemaType, componentProps }: TMetaInputFieldsProps) => {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const formFields = getMetaFormFields({ form, pageSchemaType });

	const groupedFormFields = getGroupedFormFields({
		formFields,
		groupedFields
	});

	return (
		<Flex vertical>
			<Typography.Title level={4}>Meta Content Settings</Typography.Title>

			<Typography.Text className='mb-4' type='danger'>
				*Meta details will be auto generated and can be modified manually.
			</Typography.Text>

			<InputFields formFields={groupedFormFields?.group1} />

			<Row gutter={[16, 16]}>
				<Col span={12} {...componentProps?.meta?.colProps}>
					{[undefined, true].includes(componentProps?.meta?.showTitle) && (
						<Typography.Title level={5}>Meta Details</Typography.Title>
					)}

					<div
						className={`p-4 border border-solid rounded-md border-zinc-300 ${componentProps?.meta?.fieldsContainer?.className}`}
						style={componentProps?.meta?.fieldsContainer?.style}
					>
						<InputFields formFields={groupedFormFields?.group2} />
					</div>
				</Col>

				<Col span={12} {...componentProps?.socialMeta?.colProps}>
					{[undefined, true].includes(componentProps?.socialMeta?.showTitle) && (
						<Typography.Title level={5}>Meta Social Details</Typography.Title>
					)}

					<div
						className={`p-4 border border-solid rounded-md border-zinc-300 ${componentProps?.socialMeta?.fieldsContainer?.className}`}
						style={componentProps?.socialMeta?.fieldsContainer?.style}
					>
						<InputFields formFields={groupedFormFields?.group3} />
					</div>
				</Col>
			</Row>
		</Flex>
	);
};

export default MetaInputFields;

export const getMetaFormFields = ({ form, pageSchemaType }: TGetMetaFormFields): TFormFields => {
	return {
		metaSchemaType: (() => {
			const fieldType = pageSchemaType ? EInputFieldTypes.SELECT : EInputFieldTypes.TEXT;

			return {
				fieldType,
				colProps: { span: 24 },
				formItemProps: {
					label: 'Meta Schema Type',
					name: 'metaSchemaType'
				},
				fieldProps: {
					placeholder: 'Enter Meta Schema Type',
					...(fieldType === EInputFieldTypes.SELECT && {
						options: pageSchemaType ? metaSchemaTypeOptions[pageSchemaType as EPageMetaSchemaType] : [],
						placeholder: 'Select Meta Schema Type'
					})
				}
			};
		})(),

		metaTitle: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Title',
				name: 'metaTitle'
			},
			fieldProps: {
				count: {
					show: true,
					max: META_FIELD_RULE.TITLE_MAX_LENGTH
				},
				maxLength: META_FIELD_RULE.TITLE_MAX_LENGTH,
				placeholder: 'Enter Meta Title'
			}
		},
		metaDescription: {
			fieldType: EInputFieldTypes.TEXTAREA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Description',
				name: 'metaDescription'
			},
			fieldProps: {
				autoSize: { minRows: 3, maxRows: 3 },
				count: {
					show: true,
					max: META_FIELD_RULE.DESCRIPTION_MAX_LENGTH
				},
				maxLength: META_FIELD_RULE.DESCRIPTION_MAX_LENGTH,
				placeholder: 'Enter Meta Description'
			}
		},
		metaKeyWords: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Keywords',
				name: 'metaKeyWords'
			},
			fieldProps: {
				mode: 'tags',
				placeholder: 'Select Meta Keywords'
			}
		},
		metaImage: {
			fieldType: EInputFieldTypes.SELECT_MEDIA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Image',
				name: 'metaImage'
			},
			fieldProps: {
				formInstance: form,
				button: {
					text: 'Select Meta Image'
				},
				drawer: {
					title: 'Select Image'
				},
				mediaGallery: {
					mediaType: EMediaTypes.IMAGE,
					selectMultiple: false
				}
			}
		},

		metaSocialTitle: {
			fieldType: EInputFieldTypes.TEXT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Social Title',
				name: 'metaSocialTitle'
			},
			fieldProps: {
				count: {
					show: true,
					max: 80
				},
				maxLength: META_FIELD_RULE.TITLE_MAX_LENGTH,
				placeholder: 'Enter Meta Social Title'
			}
		},
		metaSocialDescription: {
			fieldType: EInputFieldTypes.TEXTAREA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Social Description',
				name: 'metaSocialDescription'
			},
			fieldProps: {
				autoSize: { minRows: 3, maxRows: 3 },
				count: {
					show: true,
					max: META_FIELD_RULE.DESCRIPTION_MAX_LENGTH
				},
				maxLength: META_FIELD_RULE.DESCRIPTION_MAX_LENGTH,
				placeholder: 'Enter Meta Social Description'
			}
		},
		metaSocialKeyWords: {
			fieldType: EInputFieldTypes.SELECT,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Social Keywords',
				name: 'metaSocialKeyWords'
			},
			fieldProps: {
				mode: 'tags',
				placeholder: 'Select Meta Social Keywords'
			}
		},
		metaSocialImage: {
			fieldType: EInputFieldTypes.SELECT_MEDIA,
			colProps: { span: 24 },
			formItemProps: {
				label: 'Meta Social Image',
				name: 'metaSocialImage'
			},
			fieldProps: {
				formInstance: form,
				button: {
					text: 'Select Meta Social Image'
				},
				drawer: {
					title: 'Select Image'
				},
				mediaGallery: {
					mediaType: EMediaTypes.IMAGE,
					selectMultiple: false
				}
			}
		}
	};
};
