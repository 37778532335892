import { ColProps, FormItemProps } from 'antd';

import TextareaField from 'components/input-fields/components/textarea';
import { TTextAreaFieldProps } from 'components/input-fields/types/field-props';
import { DEFAULT_DESCRIPTION_MAX_LENGTH, DEFAULT_DESCRIPTION_ROWS } from 'config/constants';


type TProps = {
	colProps?: ColProps;
	formItemProps?: FormItemProps & {
		additionalRules?: FormItemProps['rules'];
	};
	fieldProps?: TTextAreaFieldProps['fieldProps'];
	applyMaxLength?: boolean;
};

type TReturn = TTextAreaFieldProps & {
	colProps?: ColProps;
};

export const getDescriptionFormField = (props?: TProps): TReturn => {
	const maxLength = props?.fieldProps?.maxLength ?? DEFAULT_DESCRIPTION_MAX_LENGTH;
	const label = props?.formItemProps?.label || 'Description';

	const { additionalRules, ...restFormItemProps } = props?.formItemProps || {};

	const applyMaxLength = props?.applyMaxLength ?? true;

	const rows = props?.fieldProps?.rows || DEFAULT_DESCRIPTION_ROWS;

	return {
		fieldType: 'textarea',
		colProps: { span: 24, ...props?.colProps },
		formItemProps: {
			name: 'description',
			label,
			rules: [
				{ required: true, message: `Please enter ${label}!` },
				...(applyMaxLength ? [{ max: maxLength, message: `Max ${maxLength} chars` }] : []),
				...(additionalRules || []),
			],
			...restFormItemProps,
		},
		fieldProps: {
			rows,
			autoSize: { minRows: rows, maxRows: rows },
			...(applyMaxLength && {
				maxLength,
				count: { show: true, max: maxLength },
			}),
			placeholder: `Enter ${label}`,
			...props?.fieldProps,
		},
	};
};

const DescriptionFormField = (props?: TProps) => {
	return <TextareaField {...getDescriptionFormField(props)} />;
};

export default DescriptionFormField;
