import React, { useRef, useState } from 'react';
import { Flex, Tag, Form, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { TPillsFieldProps } from '../types/field-props';

const PillsField: React.FC<TPillsFieldProps> = ({ formItemProps, fieldProps }) => {
	const { formInstance } = fieldProps;

	const value = Form.useWatch(formItemProps.name, formInstance);

	const onSelect = (selected: string | undefined) => {
		formInstance?.setFieldValue(formItemProps?.name, selected);
		formInstance?.validateFields([formItemProps?.name]);

		fieldProps?.pills?.onChange?.(selected);
	};

	return (
		<Form.Item {...formItemProps}>
			<ChipComp
				key={value}
				onSelect={onSelect}
				pillFieldProps={{
					...fieldProps.pills,
					initialChecked: value ? [value] : fieldProps.pills?.initialChecked,
				}}
			/>
		</Form.Item>
	);
};

export default PillsField;

type TChipsProps = {
	pillFieldProps: TPillsFieldProps['fieldProps']['pills'];
	onSelect: (selected: string | undefined) => void;
};

const ChipComp: React.FC<TChipsProps> = ({ pillFieldProps, onSelect }) => {
	const { options, initialChecked, styles, ...restPillFieldProps } = pillFieldProps;
	const defaultSelected = initialChecked?.[0];

	const [selected, setSelected] = useState<string | null>(defaultSelected || null);

	const containerRef = useRef<HTMLDivElement>(null);
	const contentsRef = useRef<{ [key: string]: HTMLDivElement }>({});

	const handleChange = (option: any, checked: boolean) => {
		setSelected(option?.value);
		onSelect(option?.value);

		if (checked && containerRef.current && contentsRef.current[option?.value]) {
			const containerWidth = containerRef.current.offsetWidth;
			const itemOffsetLeft = contentsRef.current[option?.value].offsetLeft;
			const itemWidth = contentsRef.current[option?.value].offsetWidth;

			containerRef.current.scrollTo({
				left: itemOffsetLeft - containerWidth / 2 + itemWidth / 2,
				behavior: 'smooth',
			});
		}
	};

	return (
		<Flex gap={12} wrap='nowrap' className='overflow-x-auto' ref={containerRef}>
			{(options || []).map((option: any) => (
				<Tag.CheckableTag
					ref={(r) =>
						(contentsRef.current = {
							...contentsRef.current,
							[option?.value]: r,
						})
					}
					key={option?.value}
					checked={selected === option?.value}
					className={`items-center rounded-lg cursor-pointer m-0  bg-gray-100 
            ${selected === option?.value ? 'border border-[var(--primary)] text-[var(--primary)]' : 'border border-transparent'}
            hover:bg-gray-200 transition duration-150 ease-in-out`}
					{...restPillFieldProps}
					onChange={(checked) => handleChange(option, checked)}
				>
					<Flex vertical gap={4} className='py-1' style={styles?.contentContainer}>
						<Flex gap={6} justify='space-between' align='self-start'>
							<Typography.Paragraph
								className='text-wrap text-[12px] text-semibold '
								style={{ margin: 0, fontWeight: 600 }}
								ellipsis={{ rows: 1, tooltip: true }}
							>
								{option?.label}
							</Typography.Paragraph>

							{selected === option?.value && <CheckOutlined className='text-[var(--primary) mt-1' style={styles?.checkedIcon} />}
						</Flex>

						{option?.description && (
							<Typography.Paragraph
								className='text-wrap text-gray-500 leading-4 text-[11px]'
								style={{ margin: 0 }}
								ellipsis={{ rows: 2, tooltip: true }}
							>
								{option?.description}
							</Typography.Paragraph>
						)}
					</Flex>
				</Tag.CheckableTag>
			))}
		</Flex>
	);
};
