/* eslint-disable react/no-unstable-nested-components */
import { useImperativeHandle } from 'react';

import { Flex, Spin } from 'antd';

import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TApiDrivenSelectFieldProps } from 'components/input-fields/types/field-props';

import useApiDrivenSelect from './useApiDrivenSelect';
import SelectField from '../select';

const ApiDrivenSelect = (props: TApiDrivenSelectFieldProps) => {
	const { api, formItemProps, fieldProps, imperativeRef } = props;

	const {
		queryFetchOption,
		optionsRef,
		isFetchingNextPageDataRef,
		filteredOptions,
		data,
		handleOnSearch,
		handleClearSearch,
		handleFetchNextPage
	} = useApiDrivenSelect({ api });

	useImperativeHandle(imperativeRef, () => ({
		options: optionsRef.current,
		fetchOptions: queryFetchOption.refetch
	}));

	return (
		<SelectField
			fieldType={EInputFieldTypes.SELECT}
			formItemProps={formItemProps}
			fieldProps={{
				...fieldProps,
				loading: queryFetchOption.isFetching,
				onSearch: handleOnSearch,
				onClear: handleClearSearch,
				showSearch: true,
				options: filteredOptions,
				onBlur: handleClearSearch, // clear search query, to set all options again
				onChange: async (value) => {
					handleClearSearch(); // clear search query, to set all options again

					if (fieldProps?.onChange) {
						const selectedOptionFromData = data.find((item) => {
							return api.responseDataStructure === 'tags' ? value === item : item[api.valueKey] === value;
						});

						fieldProps?.onChange?.(value, selectedOptionFromData);
					}
				},

				onPopupScroll: async (e) => {
					const target = e.target as HTMLDivElement;

					if (target.scrollHeight - target.scrollTop === target.clientHeight) handleFetchNextPage();
				},
				dropdownRender: (menu) => {
					return (
						<>
							{menu}
							{queryFetchOption?.isFetching && isFetchingNextPageDataRef.current && (
								<Flex justify='center' className='py-2'>
									<Spin size='small' />
								</Flex>
							)}
						</>
					);
				},

				maxTagCount: 'responsive',
				filterOption: false // !important: Don't remove this, else search query options will be not visible
			}}
		/>
	);
};

export default ApiDrivenSelect;
