import { Suspense, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { SpinLoading } from 'components/loading/SpinLoading';

import { useAuth } from '../contexts/AuthProvider';
import { StorageService } from '../services/storage.service';

interface AuthGuardProps {
	children: React.ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	const auth = useAuth();

	useEffect(() => {
		const redirectToLogin = () => {
			navigate('/auth/login');
		};

		if (auth.user) {
			setIsLoading(false);
			return;
		}

		const accessToken = StorageService.get('access-token');
		if (!accessToken) {
			redirectToLogin();
			return;
		}

		auth
			.refresh()
			.then(() => {
				setIsLoading(false);
			})
			.catch(() => {
				StorageService.clear();
				redirectToLogin();
			});
	}, [auth.user]);

	return <Suspense fallback={<SpinLoading isLoading={isLoading} />}>{children}</Suspense>;
};

export default AuthGuard;
