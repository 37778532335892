import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo, useCallback } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { DEFAULT_FILTER_SORT_BY, DEFAULT_FILTER_SORT_ORDER } from 'components/filter/global-filter';
import { DEFAULT_ITEMS_LIMIT_PER_PAGE } from 'config/constants';
import { EDUCATION_CHAPTER_QUERIES } from 'services/tanstack-query/education-chapter-apis';
import { EDUCATION_GRADE_QUERIES } from 'services/tanstack-query/education-grade-apis';
import { EDUCATION_SUBJECT_QUERIES } from 'services/tanstack-query/education-subject-apis';
import { IEducationChaptersApiParams, IEducationChaptersPaginatedRes } from 'types/education-chapter';
import { IEducationGradePaginatedRes } from 'types/education-grade';
import { IEducationSubjectPaginatedRes } from 'types/education-subject';

const INITIAL_API_PARAMS: IEducationChaptersApiParams = {
	sort: DEFAULT_FILTER_SORT_ORDER,
	sortBy: DEFAULT_FILTER_SORT_BY,
	page: 1,
	limit: DEFAULT_ITEMS_LIMIT_PER_PAGE,
	q: ''
};

type TGradeDetails = {
	hasGrades?: boolean | undefined;
	selectedGradeId?: string | undefined;
	gradesList?: any[] | undefined;
};

type TSubjectDetails = {
	hasSubjects?: boolean | undefined;
	selectedSubjectId?: string | undefined;
	subjectsList?: any[] | undefined;
};

interface EducationProviderProps {
	openMultiLang: boolean;
	chapterId: string | undefined;
	onAddTranslation: () => void;
	gradeDetails: TGradeDetails | undefined;
	subjectDetails: TSubjectDetails | undefined;
	queryChapters: UseQueryResult<IEducationChaptersPaginatedRes>;
	apiParams: IEducationChaptersApiParams;
	openSaveChapter: boolean;
	isLoading: boolean;
	handleSaveChapter: () => void;
	handleCloseSaveChapterDrawer: () => void;
	handleSaveChapterComplete: (refresh?: boolean, newId?: string) => void;
	handleDataChange: () => void;
	setApiParams: React.Dispatch<React.SetStateAction<IEducationChaptersApiParams>>;
	setGradeDetails: React.Dispatch<React.SetStateAction<TGradeDetails | undefined>>;
	setSubjectDetails: React.Dispatch<React.SetStateAction<TSubjectDetails | undefined>>;
	setCourseId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EducationProviderContext = createContext<EducationProviderProps | undefined>(undefined);

// NOTO: This is for the education chapter page only, to add other modules, you need to modify this context. you may create custom hooks and put in the provider
const EducationProvider = ({ children }: { children: ReactNode }) => {
	const [courseId, setCourseId] = useState<string | undefined>();
	const [openSaveChapter, setOpenSaveChapter] = useState(false);
	const [openMultiLang, setOpenMultiLang] = useState(false);
	const [chapterId, setChapterId] = useState<string>();
	const [apiParams, setApiParams] = useState<IEducationChaptersApiParams>(INITIAL_API_PARAMS);

	const onAddTranslation = useCallback(() => {
		setOpenSaveChapter(false);
		setOpenMultiLang(true);
	}, []);

	const [gradeDetails, setGradeDetails] = useState<TGradeDetails | undefined>({
		hasGrades: true,
		selectedGradeId: undefined,
		gradesList: []
	});
	const [subjectDetails, setSubjectDetails] = useState<TSubjectDetails | undefined>({
		hasSubjects: false,
		selectedSubjectId: undefined,
		subjectsList: []
	});

	const queryChapters: UseQueryResult<IEducationChaptersPaginatedRes> = useQuery({
		...EDUCATION_CHAPTER_QUERIES.fetchChapters({
			apiParams: {
				...apiParams,
				course: courseId,
				grade: gradeDetails?.selectedGradeId,
				subject: subjectDetails?.selectedSubjectId
			}
		}),
		enabled:
			!!courseId &&
			(gradeDetails?.hasGrades ? !!gradeDetails.selectedGradeId : true) &&
			(subjectDetails?.hasSubjects ? !!subjectDetails.selectedSubjectId : true)
	});

	const queryGrades: UseQueryResult<IEducationGradePaginatedRes> = useQuery({
		...EDUCATION_GRADE_QUERIES.fetchGrades({ apiParams: { page: 1, limit: 50, course: courseId } }),
		enabled: !!courseId
	});

	const querySubjects: UseQueryResult<IEducationSubjectPaginatedRes> = useQuery({
		...EDUCATION_SUBJECT_QUERIES.fetchSubjects({
			apiParams: { page: 1, limit: 50, course: courseId, grade: gradeDetails?.selectedGradeId }
		}),
		enabled: gradeDetails?.hasGrades ? !!gradeDetails.selectedGradeId : true
	});

	const handleSaveChapter = useCallback(() => {
		setOpenSaveChapter(true);
	}, []);

	const handleCloseSaveChapterDrawer = useCallback(() => {
		setOpenSaveChapter(false);
		setOpenMultiLang(false);
		setChapterId(undefined);
	}, []);

	const handleSaveChapterComplete = useCallback(
		(refresh?: boolean, newId?: string) => {
			handleCloseSaveChapterDrawer();
			if (refresh) {
				queryGrades.refetch();
				querySubjects.refetch();
				queryChapters.refetch();
			}

			if (newId) {
				const ti = setTimeout(() => {
					setChapterId(newId);
					handleSaveChapter();
				}, 300);
				return () => {
					clearTimeout(ti);
				};
			}
			return undefined;
		},
		[handleCloseSaveChapterDrawer, queryChapters, queryGrades, querySubjects, handleSaveChapter]
	);

	// For grades
	useEffect(() => {
		if (queryGrades.data) {
			const gradesList = queryGrades.data.items;
			const defaultSelected = gradesList?.length ? gradesList[0].id : undefined;

			if (gradesList?.length > 0) {
				setGradeDetails({
					...gradeDetails,
					hasGrades: gradesList?.length > 0,
					selectedGradeId: defaultSelected,
					gradesList
				});
			} else {
				setGradeDetails(undefined);
			}
		}
	}, [queryGrades.data]);

	// For subjects
	useEffect(() => {
		if (querySubjects.data?.items) {
			const subjectsList = querySubjects.data.items;
			const defaultSelected = subjectsList?.length ? subjectsList[0].id : undefined;

			if (subjectsList?.length > 0) {
				setSubjectDetails({
					...subjectDetails,
					hasSubjects: subjectsList?.length > 0,
					selectedSubjectId: defaultSelected,
					subjectsList
				});
			} else {
				setSubjectDetails(undefined);
			}
		}
	}, [querySubjects.data]);

	const contextValue = useMemo(
		() => ({
			openMultiLang,
			chapterId,
			onAddTranslation,
			gradeDetails,
			subjectDetails,
			queryChapters,
			apiParams,
			openSaveChapter,
			isLoading: queryChapters.isFetching || queryGrades.isFetching || querySubjects.isFetching,
			handleSaveChapter,
			handleCloseSaveChapterDrawer,
			handleSaveChapterComplete,
			handleDataChange: () => {
				handleSaveChapterComplete(true);
			},
			setApiParams,
			setGradeDetails,
			setSubjectDetails,
			setCourseId
		}),
		[
			openMultiLang,
			chapterId,
			onAddTranslation,
			gradeDetails,
			subjectDetails,
			queryChapters,
			apiParams,
			openSaveChapter,
			queryGrades.isFetching,
			querySubjects.isFetching,
			handleSaveChapter,
			handleCloseSaveChapterDrawer,
			handleSaveChapterComplete,
			setApiParams,
			setGradeDetails,
			setSubjectDetails,
			setCourseId
		]
	);

	return <EducationProviderContext.Provider value={contextValue}>{children}</EducationProviderContext.Provider>;
};

export default EducationProvider;

export const useEducationContext = () => {
	const context = useContext(EducationProviderContext);
	if (!context) {
		throw new Error('useEducationProvider must be used within a EducationProvider');
	}
	return context;
};
