import { FormInstance } from 'antd';

import { META_FIELD_RULE } from 'config/constants';

export const reflectTitleToMeta = async (form: FormInstance, e: React.ChangeEvent<HTMLInputElement>) => {
	await form.validateFields(['metaTitle']).then(() => {
		// metaTitle has length limit
		if (e.target.value) {
			const value = e.target.value.slice(0, META_FIELD_RULE.TITLE_MAX_LENGTH);
			form.setFieldValue('metaTitle', value);
			form.setFieldValue('metaSocialTitle', value);
		}
	});
};

export const reflectDescriptionToMeta = async (form: FormInstance, e: React.ChangeEvent<HTMLTextAreaElement>) => {
	await form.validateFields(['metaDescription']).then(() => {
		// metaDescription has length limit
		if (e.target.value) {
			const val = e.target.value?.slice(0, META_FIELD_RULE.DESCRIPTION_MAX_LENGTH);
			form.setFieldValue('metaDescription', val);

			form.setFieldValue('metaSocialDescription', val);
		}
	});
};

export const reflectCategoryToMeta = async (form: FormInstance, c: any) => {
	if (typeof c === 'object') {
		await form.validateFields(['metaKeyWords']).then(({ metaKeyWords }) => {
			const temp = c as any;
			if (temp?.title) {
				const val = Array.from(new Set([...(metaKeyWords ?? []), temp?.title].map((e) => e?.toLowerCase())));
				// toLowerCase() for SEO wise.
				form.setFieldValue('metaKeyWords', val);
				form.setFieldValue('metaSocialKeyWords', val);
			}
		});
	}
};

export const reflectTagToMeta = async (form: FormInstance, v: any) => {
	if (Array.isArray(v) && v.every((e) => typeof e === 'string')) {
		await form.validateFields(['metaKeyWords']).then(({ metaKeyWords }) => {
			// toLowerCase() for SEO wise.\
			const val = Array.from(new Set([...(metaKeyWords ?? []), ...v].map((e) => e.toLowerCase())));
			form.setFieldValue('metaKeyWords', val);
			form.setFieldValue('metaSocialKeyWords', val);
		});
	}
};

export const reflectPreviewToMeta = (form: FormInstance, imageFile: any) => {
	form.setFieldValue('metaImage', imageFile);
	form.setFieldValue('metaSocialImage', imageFile);
};
