import { Form, TimePicker } from 'antd';

import { TTimePickerProps } from 'components/input-fields/types/field-props';

const TimePickerField: React.FC<TTimePickerProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<TimePicker size='large' {...fieldProps} style={{ ...(fieldProps?.style || {}), width: '100%' }} />
		</Form.Item>
	);
};

export default TimePickerField;
