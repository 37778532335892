export enum ESubscribersPeriodTypes {
	WEEKLY = 'week',
	MONTHLY = 'month',
	YEARLY = 'year',
}

export enum EUserEngagementPeriods {
	WEEKLY = 'week',
	MONTHLY = 'month',
	YEARLY = 'year',
}
