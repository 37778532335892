import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { EAge } from 'types/enums';
import { TObject } from 'types/types';

dayjs.extend(duration);

export const convertToTitleCase = (str: string) => {
	return str
		?.replace(/_/g, ' ')
		.toLowerCase()
		.replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * This function compares two objects and returns a new object that contains the differences.
 *
 * @param {TObject} object - The first object to compare.
 * @param {TObject} base - The second object to compare.
 * @returns {TObject} - An object that contains only the properties that are different between the two input objects.
 *
 * example:
// const object = {
//   a: 1, // Primitive Value
//   b: [2, 3, 4], // Array
//   c: { d: 5, e: 6 }, // Nested Object
//   f: 7, // Property in object but not in base
//   h: [
//     { id: 1, value: "foo" },
//     { id: 2, value: "bar" },
//   ], // Array of objects
// };

// const base = {
//   a: 1, // Same Primitive Value
//   b: [2, 3, 5], // Different Array
//   c: { d: 5, f: 7 }, // Different Nested object
//   g: 8, // Property in base but not in object,
//   h: [
//     { id: 1, value: "foo" },
//     { id: 2, value: "baz" },
//   ], // Different Array of objects
// };

// const results = difference(object, base);
// results = {
//   b: [2, 3, 4],
//   c: { e: 6 },
//   f: 7,
//   h: [
//     { id: 1, value: "foo" },
//     { id: 2, value: "bar" },
//   ],
// };
 */
export const difference = (object: TObject, base: TObject): TObject => {
	// eslint-disable-next-line prefer-const
	let result: TObject = {};

	// Get all unique keys from both object and base
	const keys = new Set([...Object.keys(object), ...Object.keys(base)]);

	// eslint-disable-next-line no-restricted-syntax
	for (const key of Array.from(keys)) {
		// If both properties are arrays, check if any item is different
		if (Array.isArray(object[key]) && Array.isArray(base[key])) {
			const isDifferent =
				object[key].length !== base[key].length ||
				object[key].some((item: any, index: number) => {
					if (typeof item === 'object' && item !== null && base[key][index]) {
						// eslint-disable-next-line prefer-const
						let nestedDifference = difference(item, base[key][index]);
						return Object.keys(nestedDifference).length > 0;
					}

					return item !== base[key][index];
				});

			if (isDifferent) {
				result[key] = object[key];
			}

			// eslint-disable-next-line no-continue
			continue;
		}

		// If both properties are objects, compare them recursively
		if (typeof object[key] === 'object' && object[key] !== null && base[key]) {
			// If the property is a dayjs object, compare it with the corresponding property in base
			if (dayjs.isDayjs(object[key]) && dayjs.isDayjs(base[key])) {
				if (!object[key].isSame(base[key])) {
					result[key] = object[key];
				}

				// eslint-disable-next-line no-continue
				continue;
			}

			// eslint-disable-next-line prefer-const
			let nestedDifference = difference(object[key], base[key]);
			if (Object.keys(nestedDifference).length > 0) {
				result[key] = nestedDifference;
			}

			// eslint-disable-next-line no-continue
			continue;
		}

		// If the properties are primitive types, compare them directly
		if (object[key] !== base[key]) {
			result[key] = object[key];
		}
	}

	return result;
};

export const generateUniqueKey = () => {
	return `key-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const findDiffIndices = (str1: any, str2: any) => {
	let start = 0;
	while (start < str1?.length && start < str2?.length && str1[start] === str2[start]) {
		// eslint-disable-next-line no-plusplus
		start++;
	}
	if (start === str1?.length && start === str2?.length) {
		return { start: -1, end: -1 };
	}
	let end = 0;
	// eslint-disable-next-line no-unsafe-optional-chaining
	while (end < str1?.length - start && end < str2?.length - start && str1[str1?.length - 1 - end] === str2[str2?.length - 1 - end]) {
		// eslint-disable-next-line no-plusplus
		end++;
	}
	return { start, end };
};

export const escapeHtml = (unsafe: any) => {
	return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
};

export const getFormAgeFromAgeValue = (age: string | number) => {
	// eslint-disable-next-line eqeqeq
	if (!age && age != 0) return undefined;

	const ageValue = Number(age) > 7 ? +EAge.A : +EAge.UA;
	return ageValue;
};

export const formatSecondsToHHMMSS = (totalSeconds: number) => {
	const dur = dayjs.duration(totalSeconds, 'seconds');
	const hours = Math.floor(dur.asHours()).toString().padStart(2, '0');
	const minutes = dur.minutes().toString().padStart(2, '0');
	const seconds = dur.seconds().toString().padStart(2, '0');

	return `${hours}:${minutes}:${seconds}`;
};

export const authorHelper = (firstName: string, lastName: string, prefix?: string) => {
	if (!firstName && !lastName) return 'N/A';

	const fullName = [firstName, lastName].filter(Boolean).join(' ');

	return fullName ? `${prefix ?? 'by '}${fullName}` : 'N/A';
};
