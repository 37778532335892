import { RefObject } from 'react';

export enum ESaveAudioType {
	single = 'single',
	bulk = 'bulk'
}

export type TSaveAudioType = ESaveAudioType.single | ESaveAudioType.bulk;

export interface ISaveAudioDrawer {
	saveAudioType: TSaveAudioType;
	audioDetails?: {
		createdAt: string;
		updatedAt: string;
		id: string;
		title: string;
		description: string;
		processStatus: string;
		tags: string[];
	};
	onClose: (refresh?: boolean) => void;
}

export interface IAudioForm {
	saveAudioType: TSaveAudioType;
	audioDetails: any;
	onCloseSaveAudioDrawer: (refresh?: boolean) => void;
}

export type TUseAudioForm = {
	saveAudioType: TSaveAudioType;
	audioDetails: any;
	onCloseSaveAudioDrawer: (refresh?: boolean) => void;
};

export type TUseSaveAudio = {
	saveAudioType: TSaveAudioType;
	audioDetails: any;
	audioFilesRef: RefObject<any>;
	onSuccess: () => void;
};

export type TGenerateAddAudioPayload = {
	saveAudioType: TSaveAudioType;
	values: any;
	audioFilesRef: RefObject<any>;
};

export type TGenerateEditAudioPayload = {
	values: any;
};
