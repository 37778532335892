import { DatePicker, Form } from 'antd';

import { TDateRangePickerProps } from 'components/input-fields/types/field-props';

const DateRangePickerField: React.FC<TDateRangePickerProps> = ({ formItemProps, fieldProps }) => {
	return (
		<Form.Item {...formItemProps}>
			<DatePicker.RangePicker size='large' {...fieldProps} style={{ ...(fieldProps?.style || {}), width: '100%' }} />
		</Form.Item>
	);
};

export default DateRangePickerField;
