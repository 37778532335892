import React, { useState } from 'react';
import { Modal, Input, Button, Form } from 'antd';
import { captureScreenshot } from 'components/util/CaptureScreenshot';
import { getLogBuffer } from 'config/logger';
import { useUtils } from 'contexts/UtilsProvider';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';

interface BugReportModalProps {
	visible: boolean;
	onClose: () => void;
}

const BugReportModal: React.FC<BugReportModalProps> = ({ visible, onClose }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const url = window.location.href;
	const utils = useUtils();

	const handleSendBugReport = async (values: any) => {
		setLoading(true);
		const base64Screenshot = await captureScreenshot();
		const logs = getLogBuffer();

		const payload = {
			...values,
			logs,
			url,
			base64Screenshot
		};

		try {
			const { data } = await HttpService.post(API_URLS.BUG_REPORT_SUBMIT, payload);
			data.success && utils.showSuccess('Bug report sent successfully!');
		} catch (error) {
			utils.showError('Error sending bug report');
		} finally {
			setLoading(false);
			onClose();
			form.resetFields();
		}
	};

	return (
		<Modal open={visible} title='Report Bug' onCancel={onClose} footer={null}>
			<div className='flex flex-col space-y-4'>
				<Form form={form} layout='vertical' onFinish={handleSendBugReport}>
					<Form.Item label='Subject' name='subject' rules={[{ required: true, message: 'Please enter a subject' }]}>
						<Input placeholder='Subject' className='w-full' size='large' />
					</Form.Item>
					<Form.Item label='Error Message' name='message' rules={[{ required: true, message: 'Please enter an error message' }]}>
						<Input.TextArea placeholder='Error Message' rows={4} className='w-full' size='large' />
					</Form.Item>
					<Button className='bg-[#c01d1c]' htmlType='submit' type='primary' loading={loading} size='large' block>
						Send Report
					</Button>
				</Form>
			</div>
		</Modal>
	);
};

export default BugReportModal;
