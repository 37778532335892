import { TModalTranslation } from 'localization/types/modal';

const modal: TModalTranslation = {
	yes: 'അതെ',
	no: 'ഇല്ല',
	cancel: 'റദ്ദാക്കുക',
	actionCannotBeUndone: 'ഈ പ്രവർത്തനം പഴയപടിയാക്കാനാകില്ല.',
	areYouSureToDelete: 'ഇല്ലാതാക്കുമെന്ന് ഉറപ്പാണോ?'
};

export default modal;
