export const DEFAULT_FILTER_SORT_BY = 'updatedAt';
export const DEFAULT_FILTER_SORT_ORDER = 'DESC';

export const GLOBAL_FILTERS_COMMON_SORT_BY = [
	{ label: 'Updated At', value: 'updatedAt' },
	{ label: 'Created At', value: 'createdAt' }
];

export const DEFAULT_GLOBAL_FILTER_SORT_ORDER_LIST = [
	{ label: 'Asc', value: 'ASC' },
	{ label: 'Desc', value: 'DESC' }
];
export const DRM_FILTER_OPTIONS = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false }
];
