import { useLocation, useNavigate } from 'react-router-dom';

export const useCustomNavigation = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const clearStateFromNavigation = (shouldClear: boolean) => {
		if (shouldClear) navigate(location.pathname, { replace: true }); // This will clear the state from the navigation
	};

	return {
		clearStateFromNavigation
	};
};
