import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Input, InputRef } from 'antd';
import { SearchProps } from 'antd/lib/input';

interface TDebounce {
	func: (params: any) => void;
	delay: number;
}
export const debounce = ({ func, delay }: TDebounce) => {
	let timeoutId: NodeJS.Timeout;

	return (params: any) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			func(params);
		}, delay);
	};
};

interface ISearchProps {
	autoFocus?: boolean;
	searchProps?: Omit<SearchProps, 'onSearch'>;
	onValueChange: (value: string) => void;
}

const SearchComp = ({ searchProps, autoFocus = true, onValueChange }: ISearchProps) => {
	const inputRef = useRef<InputRef>(null);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (autoFocus) inputRef.current?.focus();
	}, []);

	useEffect(() => {
		onValueChange(searchValue);
	}, [searchValue]);

	const debouncedSetSearchValue = debounce({ func: setSearchValue, delay: 300 });

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		debouncedSetSearchValue(e.target.value);
	};

	return <Input.Search ref={inputRef} onChange={handleSearchValueChange} size='large' allowClear {...searchProps} />;
};

export default SearchComp;
