import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';
import { UseQueryType } from 'services/tanstack-query/type';
import { IEducationChaptersApiParams } from 'types/education-chapter';

export const EDUCATION_CHAPTER_QUERIES = {
	fetchChapters: ({ apiParams }: { apiParams: IEducationChaptersApiParams }): UseQueryType => ({
		queryKey: [
			EDUCATION_CHAPTER_QUERIES.fetchChapters.name,
			API_URLS.EDUCATION_CHAPTER,
			apiParams,
			'educationFetchChaptersKey'
		], // DO NOT CHANGE THE educationFetchChaptersKey, used in the resetQueries
		queryFn: async () => {
			const { data } = await HttpService.get(API_URLS.EDUCATION_CHAPTER, apiParams);
			return data?.data;
		}
	}),
	fetchChapterById: ({ id, language }: { id: string; language?: string }): UseQueryType => ({
		queryKey: [EDUCATION_CHAPTER_QUERIES.fetchChapterById.name, API_URLS.EDUCATION_CHAPTER, id, language],
		queryFn: async () => {
			const { data } = await HttpService.get(
				`${API_URLS.EDUCATION_CHAPTER}/${id}`,
				{},
				{ headers: { 'accept-language': language } }
			);
			return data?.data;
		}
	})
};

export const EDUCATION_CHAPTER_MUTATIONS = {
	addChapter: async ({ apiParams }: { apiParams: any }) => {
		const { data } = await HttpService.post(API_URLS.EDUCATION_CHAPTER, apiParams);
		return data;
	},

	updateChapter: async ({ id, apiParams }: { id: string; apiParams: any }) => {
		const { data } = await HttpService.patch(`${API_URLS.EDUCATION_CHAPTER}/${id}`, apiParams);
		return data;
	},
	updateChapterLang: async ({ id, apiParams, language }: { id: string; apiParams: any; language?: string }) => {
		const { data } = await HttpService.patch(
			`${API_URLS.EDUCATION_CHAPTER}/${id}/language`,
			apiParams,
			{},
			{ headers: { 'accept-language': language } }
		);
		return data;
	},
	deleteChapter: async ({ id }: { id: string }) => {
		const { data } = await HttpService.delete(`${API_URLS.EDUCATION_CHAPTER}/${id}`);
		return data;
	}
};
