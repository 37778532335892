import { useMutation } from '@tanstack/react-query';
import { Button, Form, Select } from 'antd';

import ApiDrivenSelect from 'components/input-fields/components/api-driven-select';
import UploadDeviceImageField from 'components/input-fields/components/custom/upload-device-image-field';
import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { FORM_SCROLL_OPTIONS, IMAGE_ASPECT_RATIOS, REGEX } from 'config/constants';
import { useUtils } from 'contexts/UtilsProvider';
import 'cropperjs/dist/cropper.css';
import { API_URLS } from 'services/apiUrls';
import { IMAGE_MUTATIONS } from 'services/tanstack-query/image';
import { EImageType } from 'types/image';

interface BulkImageUploadDrawerProps {
	onClose: (refresh?: boolean) => void;
	onImagesUploaded?: () => void;
	defaultImageType?: `${EImageType}`;
}

const BulkImageUploadDrawer = ({ defaultImageType, onClose, onImagesUploaded }: BulkImageUploadDrawerProps) => {
	const { showError, showSuccess } = useUtils();

	const [form] = Form.useForm();

	if (defaultImageType) form.setFieldValue('imageType', defaultImageType);

	const bulkImageUploadMutation = useMutation({
		...IMAGE_MUTATIONS.uploadBulkImage(),
		onSuccess: () => {
			showSuccess('Images uploaded successfully');
			onImagesUploaded?.();
			onClose(true);
		},
		onError: (error) => {
			showError(error);
		}
	});

	const handleAdd = async (values: any) => {
		const fileList = values?.mediaUpload;

		if (!fileList || fileList?.length < 1) {
			showError('Please upload image');
			return;
		}

		const formData = new FormData();

		for (let i = 0; i < fileList.length; i += 1) {
			if (REGEX.IMAGE.test(fileList[i].name)) {
				formData.append('images', fileList[i]);
			}
		}

		if (values.tags) formData.append('tags', values.tags);
		formData.append('imageType', values.imageType);

		bulkImageUploadMutation.mutateAsync(formData).catch(showError);
	};

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleAdd}
			disabled={bulkImageUploadMutation.isPending}
			scrollToFirstError={FORM_SCROLL_OPTIONS}
		>
			<Form.Item
				label='Select Media'
				name='mediaUpload'
				rules={[
					{
						required: true,
						message: 'Please select image!'
					},

					{
						validator: () => {
							if ((form.getFieldValue('mediaUpload')?.length ?? 0) > 12) {
								return Promise.reject(new Error('Please select a maximum of 12 images'));
							}
							return Promise.resolve();
						}
					}
				]}
				extra='File names should not be more than 100 characters long'
			>
				<UploadDeviceImageField
					multiple
					buttonProps={{ title: 'Upload Images' }}
					onMultipleImageFilesChange={(files: File[] | undefined) => {
						if (files && Array.isArray(files)) {
							form.setFieldValue('mediaUpload', files);
						} else {
							form.setFieldValue('mediaUpload', undefined);
						}

						form.validateFields(['mediaUpload']);
					}}
				/>
			</Form.Item>

			<Form.Item
				label='Image Type'
				name='imageType'
				rules={[
					{
						required: true,
						message: 'Please select image type!'
					}
				]}
			>
				<Select
					defaultValue={defaultImageType}
					placeholder='Select Image Type'
					size='large'
					options={IMAGE_ASPECT_RATIOS}
				/>
			</Form.Item>

			<ApiDrivenSelect
				fieldType={EInputFieldTypes.API_DRIVEN_SELECT}
				formItemProps={{
					label: 'Image Tags',
					name: 'tags'
				}}
				api={{
					url: `/${API_URLS.MEDIA_IMAGE_TAGS}`,
					responseDataStructure: 'tags'
				}}
				fieldProps={{
					mode: 'tags',
					placeholder: 'Select Image Tags'
				}}
			/>

			<Form.Item className='mt-8'>
				<Button loading={bulkImageUploadMutation.isPending} type='primary' htmlType='submit' size='large' block>
					Upload Image
				</Button>
			</Form.Item>
		</Form>
	);
};

export default BulkImageUploadDrawer;
