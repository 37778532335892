import { TFormFields } from 'components/input-fields/types/types';

import { TGlobalFilterConfig } from '../types';

type TUseFormProps = {
	predefinedFormFields: TFormFields;
	filterConfig: TGlobalFilterConfig;
};

export const useFilterForm = (props: TUseFormProps) => {
	const { filterConfig, predefinedFormFields } = props;

	// Return custom form fields which are not mentioned in "filterConfig.fieldNames"
	const getRemainingCustomFields = () => {
		const remainingCustomFormFields: any = {};

		// fieldNames is the list of predefined form fields name
		Object.keys(filterConfig?.formFields?.custom || {}).forEach((customField: any) => {
			if (
				!filterConfig.fieldNames.includes(customField) &&
				!(filterConfig.formFields?.custom?.[customField] as any)?.formItemProps?.hidden
			) {
				remainingCustomFormFields[customField] = filterConfig.formFields?.custom?.[customField];
			}
		});

		return remainingCustomFormFields;
	};

	const getFinalFormFields = (): any => {
		const customFormFields = filterConfig.formFields?.custom;

		const formFields: any = {};

		// Combining predefined and custom form fields mentioned in "filterConfig.fieldNames"
		filterConfig?.fieldNames?.forEach((fieldName) => {
			if (predefinedFormFields[fieldName] && !predefinedFormFields[fieldName]?.formItemProps?.hidden) {
				formFields[fieldName] = predefinedFormFields?.[fieldName];
			}

			// If custom form field is available then override predefined form field
			if (customFormFields?.[fieldName] && !(customFormFields[fieldName] as any)?.formItemProps?.hidden) {
				formFields[fieldName] = { ...customFormFields?.[fieldName] };
			}
		});

		// Custom form fields which are not mentioned in "filterConfig.fieldNames"
		const remainingCustomFields: any = getRemainingCustomFields();

		return { ...formFields, ...remainingCustomFields };
	};

	const finalFormFields: TFormFields = getFinalFormFields();

	const getInitialValues = (): TFormFields => {
		let initialValues = {};

		Object.keys(finalFormFields).forEach((field: any) => {
			const fieldProps = finalFormFields[field]?.fieldProps as any;

			if (fieldProps?.defaultValue) {
				initialValues = {
					...initialValues,
					[field]: fieldProps?.defaultValue
				};
			}
		});

		return initialValues;
	};

	return { finalFormFields, initialValues: getInitialValues() };
};
