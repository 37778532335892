/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AiOutlineLike } from 'react-icons/ai';
import { IoIosPeople } from 'react-icons/io';
import { MdOutlineWatchLater } from 'react-icons/md';

import { EUserEngagementPeriods } from 'pages/dashboard/type';
import { formatToUnits } from 'services/helpers.service';
import { DASHBOARD_QUERIES } from 'services/tanstack-query/dashboard-apis';

const useUserEngagementStats = () => {
	const { t } = useTranslation();
	const [period] = useState<EUserEngagementPeriods>(EUserEngagementPeriods.WEEKLY);

	const queryUserEngagementStats = useQuery({
		...DASHBOARD_QUERIES.fetchUserEngagementStats({ apiParams: { period } }),
		refetchInterval: 10000
	});

	const statsData: any = useMemo(() => {
		return Object.values(getHashKeyEngagementData(t)).map((fn: any) => fn?.(queryUserEngagementStats?.data));
	}, [queryUserEngagementStats.data, t]);

	return {
		isLoading: queryUserEngagementStats.isLoading,
		data: statsData
	};
};

export default useUserEngagementStats;

const getHashKeyEngagementData = (t: TFunction) => {
	return {
		watchTime: (data: any) => ({
			title: t('dashboard.watchTime(mins)'),
			icon: MdOutlineWatchLater,
			color: 'text-[#042254]',
			background: 'bg-blue-100',
			borderColor: 'border-[#042254]',
			count: formatToUnits(data?.data?.watchTime.watchTime),
			upDownDetails: getProgressUpDownDetails(data?.data?.watchTime.watchChange, t)
		}),
		view: (data: any) => ({
			title: t('dashboard.views'),
			icon: IoIosPeople,
			color: 'text-[#4c9943]',
			background: 'bg-lime-50',
			borderColor: 'border-[#4c9943]',
			count: formatToUnits(data?.data?.view.viewCount),
			upDownDetails: getProgressUpDownDetails(data?.data?.view.viewChange, t)
		}),
		like: (data: any) => ({
			title: t('dashboard.likes'),
			icon: AiOutlineLike,
			color: 'text-[#bf1d1d]',
			background: 'bg-red-50',
			borderColor: 'border-[#bf1d1d]',
			count: formatToUnits(data?.data?.like.likeCount),
			upDownDetails: getProgressUpDownDetails(data?.data?.like.likeChange, t)
		})
	};

};

const getProgressUpDownDetails = (change: string, t: TFunction) => {
	if (!change || change === 'N/A') return null;

	return {
		isIncreased: !change.includes('-'),
		message: `${change?.replace('-', '')} ${change.includes('-') ? t('dashboard.down') : t('dashboard.up')} ${t('dashboard.thanLastWeek')}`
	};
};
