import { useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';

import { TSelectedMedia } from 'components/gallery/types';
import { FetchLiveSteamsData } from 'types/live-stream';

import StreamCard from './StreamCard';
import { EStreamCardMenuOptions } from './StreamMenuOptions';

interface Props {
	dataList: FetchLiveSteamsData[];
	onMenuOptionSelected?: (option: EStreamCardMenuOptions, liveStreamData: FetchLiveSteamsData) => void;
	onLiveStreamClicked?: (streamId: string) => void;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
}

const StreamGallery = ({ dataList, onMenuOptionSelected, onLiveStreamClicked, onSelect }: Props) => {
	const [selectedId, setSelectedId] = useState('');

	const handleSelect = (cardData: FetchLiveSteamsData) => {
		setSelectedId(cardData?.id);
		onSelect?.([{ title: cardData?.title || '', id: cardData?.id || '', url: cardData.image?.urls?.preview || '' }]);
	};

	return (
		<List
			grid={{
				gutter: 12
			}}
			dataSource={dataList}
			renderItem={(item) => (
				<List.Item>
					<Button
						className={`rounded-[50px] absolute z-10 top-[8px] right-[40px] ${selectedId !== item?.id && 'hidden'} `}
						icon={<CheckOutlined />}
						size='small'
						type={selectedId === item?.id ? 'primary' : 'default'}
					/>
					<StreamCard
						cardData={item}
						onLiveStreamClicked={onLiveStreamClicked}
						onMenuOptionSelected={onMenuOptionSelected}
						onSelect={handleSelect}
					/>
				</List.Item>
			)}
		/>
	);
};

export default StreamGallery;
