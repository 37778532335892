/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useUtils } from './UtilsProvider';
import { GlobalWrapper } from '../interfaces/global';
import { ApiHandlersService } from '../services/api-handlers.service';
import { API_URLS } from '../services/apiUrls';
import { HttpService } from '../services/http.service';
import { StorageService } from '../services/storage.service';

const AuthContext = createContext<
	| {
			user: UserType | null;
			login: (username: string, password: string) => Promise<void>;
			forgetPassword: (email: string) => Promise<object>;
			verifyOTP: (email: string, otp: number) => Promise<object>;
			refresh: () => Promise<void>;
			logout: () => Promise<void>;
	  }
	| undefined
>(undefined);

interface AuthProviderProps {
	children: ReactNode;
}

type LoginResponse = {
	token: string;
	roles: string;
};

export type UserType = {
	id: string;
	email: string;
	name: string;
	profileImage: string;
};

export type AssociatedOrganization = {
	organizationName: string;
	organizationId: string;
	parentOrgId: string | null;
	associatedOrganizationUserId: number;
	roleName: string;
	accessRights: string[];
};

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState<UserType | null>(null);
	const utils = useUtils();

	const refreshProfile = useCallback(async () => {
		try {
			const { data } = await HttpService.get(API_URLS.LOGGED_IN_USER_PROFILE);
			const { id, firstName, lastName, user, imageUrl } = data?.data || {};
			const fullName = `${firstName || ''} ${lastName || ''}`?.trim();

			const userData: UserType = {
				id,
				email: user?.email,
				name: fullName,
				profileImage: imageUrl && `${imageUrl}?version=${Date.now()}` // Using date to force refresh image
			};

			datadogRum?.setUser({ id: id || '', email: user?.email || '', name: fullName || '' });

			setUser(userData);
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	}, []);

	const login = async (email: string, password: string) => {
		// try {
		const { data }: { data: GlobalWrapper<LoginResponse> } = await HttpService.post(API_URLS.AUTH_LOGIN, {
			email,
			password
		});
		StorageService.set('access-token', data.data.token);
		StorageService.set('user-role', data.data.roles);

		await refreshProfile();

		// } catch (error) {
		//   const message = ApiHandlersService.generateErrorMessage(error);
		//   utils.showError(message);
		// }
	};
	const forgetPassword = async (email: string) => {
		try {
			const { data }: { data: GlobalWrapper<LoginResponse> } = await HttpService.post(API_URLS.AUTH_FORGOT_PASSWORD, {
				email
			});
			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);

			return {};
		}
	};
	const verifyOTP = async (email: string, otp: number) => {
		try {
			const { data }: { data: GlobalWrapper<LoginResponse> } = await HttpService.post(API_URLS.AUTH_RESET_PASSWORD, {
				email,
				otp
			});
			return data;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
			return {};
		}
	};

	const logout = async () => {
		try {
			await HttpService.post(API_URLS.AUTH_LOGOUT);
			StorageService.clear();
			setUser(null);

			datadogRum?.clearUser();
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		}
	};

	const values = {
		user,
		login,
		forgetPassword,
		verifyOTP,
		refresh: refreshProfile,
		logout
	};

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return context;
};

export { AuthContext, AuthProvider, useAuth };
