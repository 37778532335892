import { ButtonView, Plugin, toWidget } from 'ckeditor5';

export default class CustomVideoPlugin extends Plugin {
	init() {
		const { editor } = this;

		// Define the `video` element in the model schema
		editor.model.schema.register('video', {
			// Allow in $block elements (such as paragraphs, block quotes, etc.)
			allowWhere: '$block',

			// Allow attributes for the `video` element
			allowAttributes: [
				'src',
				'controls',
				'class',
				'id',
				'type',
				'fluid',
				'poster',
				'data-setup',
				'data-vjs-player',
				'preload',
				'width',
				'height',
				'autoplay',
				'muted'
			],

			// Disallow children elements inside the `video` element
			isObject: true,
			isBlock: true
		});
		editor.conversion.for('editingDowncast').elementToElement({
			model: 'video',
			view: (modelElement, { writer: viewWriter }) => {
				// Insert the video element inside the widget
				const videoElement = viewWriter.createEmptyElement('video', {
					src: modelElement.getAttribute('src'),
					controls: '',
					class: 'i-need-to-be-fixed',
					preload: 'auto',
					autoplay: false,
					width: '100%',
					height: 'auto',
					muted: true
				});

				// widget
				const widgetElement = viewWriter.createContainerElement('div', {});
				viewWriter.insert(viewWriter.createPositionAt(widgetElement, 0), videoElement);
				return toWidget(widgetElement, viewWriter);
			}
		});

		editor.conversion.for('dataDowncast').elementToElement({
			model: 'video',
			view: (modelElement, { writer: viewWriter }) => {
				const videoElement = viewWriter.createEmptyElement('video', {
					src: modelElement.getAttribute('src'),
					controls: 'controls',
					'data-setup': '{}',
					preload: 'auto',
					autoplay: false,
					width: '100%',
					height: 'auto',
					muted: true
				});
				return videoElement;
			}
		});

		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'video',
				attributes: {
					src: true,
					controls: true
				}
			},
			model: (viewElement, { writer: modelWriter }) => {
				return modelWriter.createElement('video', {
					src: viewElement.getAttribute('src')
				});
			}
		});

		editor.ui.componentFactory.add('CustomVideoPlugin', (locale) => {
			const view = new ButtonView(locale);
			// Assuming you want to allow images in all block elements
			view.set({
				label: 'Insert Video',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
									<path d="M11 8H9v3H6v2h3v3h2v-3h3v-2h-3z" />
									<path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7zm-1.999 10H4V7h12v5l.001 5z" />
                </svg>`,
				tooltip: true
			});

			// The command to be executed when the button is clicked
			view.on('execute', () => {
				// Trigger your child component here
				const opener = editor.config.get('onVideoDrawerOpen') as any;
				if (typeof opener === 'function') opener();
				// editor.execute('openVideoDrawer');
			});

			return view;
		});
	}
}
