import { EImageType } from 'types/image';

export enum EMediaType {
	video = 'video',
	image = 'image',
	audio = 'audio',
	stream = 'stream'
}

export type TSelectedMedia = {
	id: string;
	title: string;
	url: string;
};

interface IMediaGalleryBase {
	mediaType: `${EMediaType}`;
}

interface IImageGallery extends IMediaGalleryBase {
	mediaType: `${EMediaType.image}`;
	selectMultiple: boolean;
	imageType?: `${EImageType}`;
	onImageSelected?: (images: TSelectedMedia[]) => void; // TODO: remove this, when onSelect is used at all places
	onSelect?: (selectedMedias: TSelectedMedia[]) => void; // TODO: make it required, after onImageSelected is removed
	forEditor?: boolean;
}

interface IVideoGallery extends IMediaGalleryBase {
	mediaType: `${EMediaType.video}`;
	selectMultiple: boolean;
	onVideoSelected?: (videos: TSelectedMedia[]) => void; // TODO: remove this, when onSelect is used at all places
	onSelect?: (selectedMedias: TSelectedMedia[]) => void; // TODO: make it required, after onVideoSelected is removed
}

interface IAudioGallery extends IMediaGalleryBase {
	mediaType: `${EMediaType.audio}`;
	selectMultiple: boolean;
	onAudioSelected?: (audios: TSelectedMedia[]) => void; // TODO: remove this, when onSelect is used at all places
	onSelect?: (selectedMedias: TSelectedMedia[]) => void; // TODO: make it required, after onAudioSelected is removed
}

export type TMediaGallery = IImageGallery | IVideoGallery | IAudioGallery;
