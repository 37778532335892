import { useEffect, useState } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import BugReportModal from 'components/modals/BugReportModal';
import EducationProvider from 'contexts/EducationProvider';
import { MasterProvider } from 'contexts/MasterProvider';
import queryClient from 'services/tanstack-query/query-client';
import { WebWorkerProvider } from 'web-workers/web-worker-context';

import { AppRoutes } from './config/routing';
import { AuthProvider } from './contexts/AuthProvider';
import { CategoryProvider } from './contexts/CategoryProvider';
import { UtilsProvider } from './contexts/UtilsProvider';
import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';

const App = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	return (
		<div
			className={
				isLoaded
					? 'opacity-100 translate-y-0 scale-100 duration-300 transition-all ease-in-out'
					: 'opacity-0 translate-y-2 scale-95'
			}
		>
			<QueryClientProvider client={queryClient}>
				<UtilsProvider>
					<AuthProvider>
						<WebWorkerProvider>
							<MasterProvider>
								<EducationProvider>
									<CategoryProvider>
										<ConfigProvider
											theme={{
												components: {
													Menu: {
														itemBg: '#ffffff',
														itemHoverBg: '#fff2f0',
														itemHoverColor: '#c01d1c',
														subMenuItemBg: 'rgba(0, 0, 0, 0.02)'
													},
													Segmented: {
														itemSelectedBg: '#fff',
														trackBg: 'rgba(0, 0, 0, 0.02)',
														trackPadding: 4
													},
													Table: {
														headerSortActiveBg: '#fafafa',
														headerSortHoverBg: '#fafafa',
														bodySortBg: 'transparent'
													}
												},
												token: {
													colorPrimary: '#C01D1C',
													colorFillContentHover: '#0E2F69',
													colorFillContent: '#fff',
													colorLink: '#0E2F69',
													borderRadius: 5,
													colorBorderSecondary: '#e4e4e4'
												}
											}}
										>
											<BrowserRouter>
												<Routes>
													{AppRoutes.map((route, index) => {
														if (route.authGuard) {
															return (
																<Route
																	path={route.path}
																	element={<AuthGuard>{route.component}</AuthGuard>}
																	key={`route-${index}`}
																/>
															);
														}
														return (
															<Route
																path={route.path}
																element={<GuestGuard>{route.component}</GuestGuard>}
																key={`route-${index}`}
															/>
														);
													})}
												</Routes>
											</BrowserRouter>
										</ConfigProvider>
									</CategoryProvider>
								</EducationProvider>
							</MasterProvider>
						</WebWorkerProvider>
					</AuthProvider>

					{/* <Button
						onClick={openModal}
						type='primary'
						className='fixed bottom-8 right-4 bg-[#c01d1c] text-white p-2 border-none rounded-full shadow-md hover:shadow-2xl cursor-pointer hover:animate-pulse z-[9999]'
					>
						Report Bug
					</Button> */}
					<BugReportModal visible={isModalVisible} onClose={closeModal} />
				</UtilsProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</div>
	);
};

export default App;
