import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';

type TMasterContext = {
	countries: {
		isLoading: boolean;
		data: any[];
	};
	setData: (data: any) => void;
};

const MasterContext = createContext<TMasterContext>({
	countries: {
		isLoading: false,
		data: [],
	},
	setData: (data: any) => {},
});

type TMasterProvider = {
	children: ReactNode;
};

export const MasterProvider = ({ children }: TMasterProvider) => {
	const { countries, setData } = useMasterProvider();

	const value = { countries, setData };

	return <MasterContext.Provider value={value}>{children}</MasterContext.Provider>;
};

const useMasterProvider = () => {
	const [data, setData] = useState({
		countries: { isLoading: false, data: [] },
	});

	return {
		countries: {
			isLoading: data.countries.isLoading,
			data: data.countries.data,
		},
		setData,
	};
};

type TUseMasterContext = {
	countries?: {
		load: boolean;
		onSuccess?: (data: any) => void;
	};
};

export const useMasterContext = ({ countries: countriesProp }: TUseMasterContext) => {
	const context = useContext(MasterContext);

	if (context === undefined) {
		throw new Error('useMasterContext must be used within a MasterProvider');
	}

	const { setData, ...restContext } = context;

	const handleSetData = (key: string, data: any) => {
		setData((pv: any) => ({
			...pv,
			[key]: { ...pv[key], ...data },
		}));
	};

	useEffect(() => {
		if (countriesProp?.load) {
			const getCountries = async () => {
				if (context.countries.data.length > 0) {
					countriesProp?.onSuccess?.(context.countries.data);
					return;
				}

				handleSetData('countries', { isLoading: true });

				try {
					const response = await HttpService.get(API_URLS.MASTER_COUNTRIES);
					const data = response.data?.data;

					handleSetData('countries', { isLoading: false, data });

					countriesProp?.onSuccess?.(data);
				} catch (error) {
					handleSetData('countries', { isLoading: false, data: [] });
				}
			};

			getCountries();
		}
	}, [countriesProp?.load]);

	return restContext;
};
