import { useEffect, useState } from 'react';

import { Flex, Input, Popover } from 'antd';
import { VscSettings } from 'react-icons/vsc';

import { GlobalFilter, TGlobalFilterResult } from 'components/filter/global-filter';
import { EInputFieldTypes } from 'components/input-fields/types/enums';
import { TABLE_ROWS_LIMIT } from 'config/constants';
import useDebounceValue from 'hooks/useDebounceValue';

import { useSearchContext } from './provider/search-hook';
import SearchContentTabs from './search-tabs/search-content-tabs';

const GlobalSearch = () => {
	const [searchValue, setSearchValue] = useState<string>('');

	const debounceValue = useDebounceValue(searchValue, 300);
	const { openSearchData, setOpenSearchData, setSearchApiParams } = useSearchContext();

	const handleSearchChange = (value: string) => {
		if (value?.length > 0) {
			setOpenSearchData(true);
		} else {
			setOpenSearchData(false);
			setSearchApiParams((pv: any) => ({ ...pv, page: 1 }));
		}

		setSearchValue(value);
	};

	const handleFilterChange = (filters: TGlobalFilterResult) => {
		setSearchApiParams((pv: any) => ({
			...pv,
			page: 1,
			isActive: (() => {
				const hash = { all: undefined, active: true, inactive: false };
				return filters.isActive in hash ? hash[filters.isActive as keyof typeof hash] : filters.isActive;
			})(),
			limit: filters.recordsLimit
		}));
	};

	useEffect(() => {
		setSearchApiParams((pv: any) => ({ ...pv, q: debounceValue }));
	}, [debounceValue]);

	return (
		<Flex vertical align='center' justify='center' className='w-full'>
			<Flex gap={8} className='w-full'>
				<Input.Search
					onFocus={() => searchValue && setOpenSearchData(true)}
					placeholder='Search Content'
					size='large'
					allowClear
					onChange={(e) => {
						handleSearchChange(e?.target?.value);
					}}
					onSearch={(value) => {
						handleSearchChange(value);
					}}
				/>

				{debounceValue && (
					<GlobalFilter
						fieldNames={['recordsLimit', 'isActiveTest']}
						onSubmit={handleFilterChange}
						filterButtonProps={{ icon: <VscSettings />, title: '' }}
						formFields={{
							predefined: {
								recordsLimit: {
									fieldProps: {
										defaultValue: TABLE_ROWS_LIMIT
									}
								}
							},
							custom: {
								isActive: {
									fieldType: EInputFieldTypes.PILLS,
									formItemProps: {
										name: 'isActive',
										label: 'Active'
									},
									fieldProps: {
										pills: {
											initialChecked: ['all'],
											options: [
												{ label: 'All', value: 'all' },
												{ label: 'Active', value: 'active' },
												{ label: 'Inactive', value: 'inactive' }
											]
										}
									}
								}
							}
						}}
					/>
				)}
			</Flex>

			<Popover className='z-10' content={<SearchContentTabs />} open={openSearchData} placement='bottom' />
		</Flex>
	);
};

export default GlobalSearch;
