// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { enTranslations } from 'localization/en';
import { mlTranslations } from 'localization/ml';
import { StorageService } from 'services/storage.service';
import { EStorageKeys } from 'types/enums';

import { TAppTranslation } from './types';

const translations: Record<string, { translation: TAppTranslation }> = {
	en: { translation: enTranslations },
	ml: { translation: mlTranslations }
};

i18n.use(initReactI18next).init({
	resources: translations,
	lng: 'en',
	fallbackLng: 'en',

	interpolation: {
		escapeValue: false // React already escapes values to prevent XSS
	}
});

i18n.changeLanguage(StorageService.get(EStorageKeys.TRANSLATION_LANGUAGE) || 'en');

export default i18n;
