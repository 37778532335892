import { useEffect, useRef, useState } from 'react';

import { useUtils } from 'contexts/UtilsProvider';
import { ApiHandlersService } from 'services/api-handlers.service';
import { API_URLS } from 'services/apiUrls';
import { HttpService } from 'services/http.service';

import { TSortOrder } from './FilterButton';
import { TSelectedMedia } from '../types';

export const VIDEOS_PER_PAGE = 30;

export type TVideo = {
	id: string;
	url: string;
	title: string;
};

type TProps = {
	selectMultiple?: boolean;
	onVideoSelected?: (videos: TVideo[]) => void;
	onSelect?: (selectedMedias: TSelectedMedia[]) => void;
	forEditor?: boolean;
};

const DEFAULT_SORT_BY = 'createdAt';
const DEFAULT_SORT_ORDER: TSortOrder = 'DESC';
const DEFAULT_FILTER_TAGS = '';

export const useVideoGallery = ({ selectMultiple, onVideoSelected, onSelect, forEditor = false }: TProps) => {
	const utils = useUtils();

	const [isLoading, setIsLoading] = useState(true);
	const [dataList, setDataList] = useState([] as any);
	const [pagination, setPagination] = useState(1);
	const [showSingVideoUpload, setShowSingleVideoUpload] = useState(false);
	const [searchString, setSearchString] = useState('');

	const sortByRef = useRef<string>(DEFAULT_SORT_BY);
	const sortOrderRef = useRef<TSortOrder>(DEFAULT_SORT_ORDER);
	const totalVideosRef = useRef(0);

	const onSingleVideoModalClose = () => {
		setShowSingleVideoUpload(false);
	};

	const handlePageSelection = (page: number) => {
		setPagination(page);
	};

	const fetchVideos = async (
		pagination: number,
		searchString: string,
		filterTags: string,
		sortByOrder: string,
		sortOrder: string
	) => {
		setIsLoading(true);

		try {
			const apiParams = {
				q: searchString,
				tags: filterTags,
				page: pagination,
				limit: VIDEOS_PER_PAGE,
				sortBy: sortByOrder,
				sort: sortOrder
			} as any;
			if (forEditor) {
				apiParams.processStatus = 'completed';
				apiParams.drmProtected = false;
			}

			const { data } = await HttpService.get(API_URLS.MEDIA_VIDEO, apiParams);

			if (data && data.data && data.data.items) {
				data.data?.items.forEach((item: any) => {
					item.src = item?.urls?.thumbnail;
					item.video = item?.urls?.preview;
					item.tags = [];
					item.caption = item.title;
				});
			}

			setDataList(data?.data?.items);
			totalVideosRef.current = data?.data?.totalItems;
		} catch (error) {
			const message = ApiHandlersService.generateErrorMessage(error);
			utils.showError(message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchVideos(pagination, searchString, DEFAULT_FILTER_TAGS, DEFAULT_SORT_BY, DEFAULT_SORT_ORDER);
	}, [pagination, searchString]);

	const onSearch = (searchString: string) => {
		setSearchString(searchString);
	};

	const onVideoUploaded = () => {
		fetchVideos(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, DEFAULT_SORT_ORDER);
	};

	const onFilterOptionSelected = (sortBy: string) => {
		sortByRef.current = sortBy;
		if (sortBy === sortByRef.current) {
			sortOrderRef.current = sortOrderRef.current === 'ASC' ? 'DESC' : 'ASC';
		} else {
			sortOrderRef.current = 'DESC';
		}

		fetchVideos(pagination, searchString, DEFAULT_FILTER_TAGS, sortByRef.current, sortOrderRef.current);
	};

	const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const selectedItemsId = selectedItems.map((item) => item.id);

	const handleItemSelect = (id: string) => {
		const item = dataList.find((item: any) => item.id === id);

		if (item.processStatus !== 'completed') return;

		const newSelectedItems = selectedItemsId.includes(id)
			? selectedItems.filter((selectedItem) => selectedItem.id !== id)
			: selectMultiple
				? [...selectedItems, item]
				: [item];

		setSelectedItems(newSelectedItems);

		onVideoSelected?.(newSelectedItems);
		onSelect?.(newSelectedItems);
	};

	return {
		isLoading,
		dataList,
		showSingVideoUpload,
		pagination,
		sortOrderRef,
		onVideoUploaded,
		onSingleVideoModalClose,
		onFilterOptionSelected,
		onSearch,
		handlePageSelection,
		setShowSingleVideoUpload,
		totalVideosRef,

		selectedItemsId,
		handleItemSelect
	};
};
