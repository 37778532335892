import { memo, ReactNode } from 'react';

import { Spin } from 'antd';

// todo need a props for future

type Props = { children?: ReactNode; isLoading?: boolean };
// eslint-disable-next-line no-empty-pattern
export const SpinLoading = memo(({ children, isLoading = true }: Props) => {
	return (
		// <Flex className='w-full h-full' justify='center' align='center'>
		<Spin spinning={isLoading}>{children}</Spin>
		// </Flex>
	);
});
