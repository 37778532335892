/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';

import { EGlobalSearchIndexes } from 'types/global-search';

import { ETabsKey } from './types';
import { useSearchContext } from '../provider/search-hook';

const useSearchContentTabs = () => {
	const [activeTabKey, setActiveTabKey] = useState(ETabsKey.ALL);

	const { setSearchApiParams } = useSearchContext();

	const handleTabChange = (tabKey: string) => {
		setActiveTabKey(tabKey as ETabsKey);

		// Set the search index based on the tab key, Setting params, will refresh the data as per the tab
		const searchIndex = HashTabKeyToSearchIndex[tabKey as ETabsKey];
		setSearchApiParams((prev) => ({ ...prev, page: 1, indexes: searchIndex ? [searchIndex] : undefined }));
	};

	return {
		activeTabKey,
		handleTabChange
	};
};

export default useSearchContentTabs;

const HashTabKeyToSearchIndex: { [key in ETabsKey]: EGlobalSearchIndexes | undefined } = {
	[ETabsKey.ALL]: undefined,
	[ETabsKey.ARTICLE]: EGlobalSearchIndexes.ARTICLE,
	[ETabsKey.CATEGORY]: EGlobalSearchIndexes.CATEGORY,
	[ETabsKey.DISTRICT]: EGlobalSearchIndexes.DISTRICT,
	[ETabsKey.MOVIE]: EGlobalSearchIndexes.MOVIE,
	[ETabsKey.SERIES]: EGlobalSearchIndexes.SERIES,
	[ETabsKey.PODCAST]: EGlobalSearchIndexes.PODCAST,
	[ETabsKey.STREAM]: EGlobalSearchIndexes.STREAM,
	[ETabsKey.POLL]: EGlobalSearchIndexes.POLL,
	[ETabsKey.RAFFLE]: EGlobalSearchIndexes.RAFFLE,
	[ETabsKey.PARTY]: EGlobalSearchIndexes.PARTY,
	[ETabsKey.LEADER]: EGlobalSearchIndexes.LEADER,
	[ETabsKey.DYNAMIC_PAGE]: EGlobalSearchIndexes.DYNAMIC_PAGE,
	[ETabsKey.EDUCATION_COURSE]: EGlobalSearchIndexes.EDUCATION_COURSE
};
